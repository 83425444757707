<template>
    <div>
        <h2>Logging In</h2>
    </div>
</template>
<script>
export default {
    name : 'login-via-url',
    props: {'token':String},
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.$socket.emit('login-via-token', {urlToken: vm.$props.token})
            vm.$store.commit('set-url-access-token', vm.$props.token)
            next();
        }) 
    }

}
</script>