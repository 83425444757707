<template>
  <div>
    <div class="add-button-opener" :class="{'hide':!editMode, 'open':add_menu_open}" v-on:click="add_menu_open = !add_menu_open" >
      <svg viewBox="0 0 50 50">
        <line x1="5" y1="25" x2="45" y2="25" style="stroke-width: 5px; stroke: white"/>
        <line x1="25" y1="5" x2="25" y2="45" style="stroke-width: 5px; stroke: white"/>
      </svg> 
    </div>
    <div class="background" :class="{'not_show':!add_menu_open}">
      <div v-for="(item, i) in additems" :key="i + '_add_item'" class="add_icon icon" :style="`right:${(!add_menu_open)? '50' : circle.rad * Math.cos(item.deg * Math.PI / 180) + circle.offset_x}px; bottom:${(!add_menu_open)? '50' : circle.rad * Math.sin(item.deg * Math.PI / 180) + circle.offset_y}px; background-image: url('${item.image}')` " v-on:click="item_clicked(item)">
        <div class="name">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'add-items-template',
  props: {circle:Object, 'edit-mode':Boolean, additems:Array},
  methods: {
    item_clicked(item){
      if(item.additem){
        item.additem()
      }
      
      this.add_menu_open = false;
    }
  },data(){
    return{
      add_menu_open: false
    }
  }
}
</script>
<style>
.add-button-opener{
  position: fixed;
  bottom: 50px;
  right: 50px;

  width: 65px;
  height: 65px;

  padding: 5px;
  background-color: var(--color2);
  border-radius: 50%;
  
  transition: transform 0.4s;
  z-index: 9;
}
.add-button-opener.open{
  transform: rotate(45deg);
  z-index: 51;

}
.background{
  background-color: var(--color7);

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  z-index: 50;
}
.background .add_icon{
  position: fixed;

  background-color: var(--color4);

  width: 65px;
  height: 65px;
  border-radius: 50%;
  
  background-size: 40px;
  opacity: 1;

  transition: 0.4s ease;
  cursor: pointer;

}
.background .add_icon.not_show{
  right: 50px;
  bottom: 50px;
  opacity: 0;
}
.background .add_icon .name{
  margin-left: -200px;
  color: white;
  font-size: var(--font2);
}
</style>