<template>
    <div>
        <charts-details :show="show"  :chart="chart"  :maschine="maschine" v-on:close-details="$router.go(-1)"></charts-details>
    </div>
</template>
<script>
import chartsDetails from '../../components/charts-details.vue'
export default {
    name: 'chart-details-dialog',  
    components: { chartsDetails },
    props: {id: [String, Number], 'chartId': [String, Number], maschineId: [String, Number],  'type': String, html_id: String },
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.maschine = {id: vm.maschineId }
            vm.chart = { 
                ID: vm.chartId, 
                type: vm.type, 
                html_id: vm.html_id,  
                from: vm.$moment().add(-1, 'days').toISOString(),
                till: vm.$moment().toISOString(),
                time: {
                    from: vm.$moment().add(-1, 'days').toISOString(), 
                    till: vm.$moment().toISOString(), 
                    span: 'dayly' },
                scale: {}
            }

            vm.show = true
        })
    },
    /*
      token:this.token, 
        type: this.chart.type, ID:this.chart.ID, 
        graph: this.chart,
        html_id: this.chart.html_id,
        show_details: true,
        activeSegment:this.activeSegment,
        tolerance: this.filter / 100,
        presentation: this.presentation,
        time:{
          von:this.chart.from, 
          bis:this.chart.till, 
          span: (this.chart.type=='bar')? this.selector: null}, 
        mobil:this.$isMobile(), 
        scale: this.scale,
        maschine:{id: parseInt(this.maschine.id)}
    */
    data(){

        return{
            show: false,
            chart: { },
            maschine: { }
        }
    }

}
</script>