<template>
    <div class="vertical-wrapper">
        <md-table v-model="globalView.offlineMaschines" :md-sort.sync="tableSortBy" :md-sort-order.sync="tableSortOrder" md-card md-fixed-header :md-height="'auto'" :md-sort-fn="customSort">
                <md-table-toolbar>
                    <h1 class="md-title">Offline Maschines</h1>
                </md-table-toolbar>

                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>

                    <md-table-cell md-label="Offline Datum" md-sort-by="offlineDate">{{ item.offlineDate }}</md-table-cell>

                </md-table-row>
            </md-table>
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: 'global-offline-view',

    computed: {
   
        token(){
            return this.$store.getters.token
        },
        ...mapState(["globalView"]),

    },
    methods:{
        customSort(value) {
            const sortBy = this.tableSortBy
            return value.sort((a, b) => {
                if (typeof a[sortBy] == 'string' && this.tableSortOrder === 'desc') {
                    return a[sortBy].localeCompare(b[sortBy])
                } else if (typeof a[sortBy] == 'string'){
                    return b[sortBy].localeCompare(a[sortBy])
                } else if (Array.isArray(a[sortBy]) && this.tableSortOrder === 'asc'){
                    return a[sortBy] - b[sortBy]
                } else if (Array.isArray(a[sortBy]) && this.tableSortOrder === 'desc') {
                    return  b[sortBy] - a[sortBy]
                }


            })
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.commit('display/set-show-kategories', false);
            vm.$store.commit('display/set-burger-active', false);

            
           fetch(`${vm.$hostname}/maschines/offline`, {method: 'POST', headers: { authorization: vm.token }}).then(async (e) => {
            if(e.status == 200){
                const data = await e.json()

                // sort all maschines after offline date
                data.sort((a, b) => b.offlineDate - a.offlineDate)
                // convert dates into format
                data.forEach(e => e.offlineDate = vm.$moment.unix(e.offlineDate).format('YYYY-MM-DD HH:mm:ss') )
                //vm.table = data
            }
           })
        })
    },
    data(){
        return{
            tableSortBy: 'offlineData',
            tableSortOrder: 'asc',
            table: []
        }
    }

}
</script>