<template>
  <div class="export-template" :class="{'edit':edit, 'edit_active':etemp.edit}" v-on:click="() =>{  this.$emit('item-clicked', etemp)}">
    <div class="name">
      <h2>{{etemp.name}}</h2>
      <h3>{{etemp.export_type}}</h3>
    </div>    
    <div class="placeholder"></div>
    <div class="eye" v-on:click="open_preview"></div>
    <div class="export icon" :class="{'hide':edit}" v-on:click="open_export" ></div>
     <md-dialog :md-active.sync="export_dialog" class="export-dialog">
      <div class="md-dialog-body">

        <md-dialog-title>Export</md-dialog-title>

        <div class="centered-middle">
          <md-field class="date-wrapper">
            <label>filename</label>
            <md-input v-model="filename"></md-input>
          </md-field>
          <div class="date-wrapper">
            <label for="">from:</label>
            <datetime type="datetime" title="from" :value="from" v-model="from"/>
          </div>
          <br><br>
          <div class="date-wrapper">
            <label for="">till:</label>
            <datetime type="datetime" title="till" :value="till" v-model="till" color="red"/>
          </div>
          <br>
          <div class="valuenames-export">
            <label for="">Export data:</label>
             <md-switch v-for="(item, index) in valuenames" :key="'valuenames' + index" v-model="item.active" class="md-primary" v-on:change="(e) => valuename_switch_toggle(e, item)">{{`${item.name} [${item.unit}]`}}</md-switch>
          </div> 
        </div>
          
      </div>

      <md-dialog-actions class="">
        <md-button class="md-primary" v-on:click="export_dialog = false">Close</md-button>
        <md-button class="md-primary" v-on:click="()=>{export_data(); export_dialog = false;}">Export</md-button>
      </md-dialog-actions>
    </md-dialog>
        
    <vue-json-to-csv
      class="hide"
      ref="downloadcsv"
      :show-labels="true"
      :separator="';'"
      :labels="csv_header"
      :json-data="mydata"


      @error="(e) => alert(e)"
      :csv-title="filename">
    </vue-json-to-csv>
  </div>
</template>
<script>
export default {
  name: 'export-template',
  props: {etemp: Object, maschine: Object, edit:Boolean},
  methods:{
    open_export(){
      this.$socket.emit('load-export-template', {token:this.token, maschine:this.maschine, export_template:(this.etemp.id > 0)? this.etemp: null})
      console.log('open export')
    },
    open_preview(){},
    export_data(){
      const etemp = JSON.parse(JSON.stringify(this.etemp))
      etemp.valuenames = this.valuenames
      etemp.from = this.from;
      etemp.till = this.till
      //this.etemp.valuenames = this.valuenames
      //this.etemp.from = this.from;
      //this.etemp.till = this.till;
      this.$socket.emit('export-data', {token: this.token, maschine:this.maschine, export_template: etemp})
    },
    valuename_switch_toggle(e, item){
      item.active = e;
    }
  },sockets:{
    'show-export-template':function({valuenames, template}){
      console.log(template.id, this.etemp.id, template.id != this.etemp.id)
      if(template.id != this.etemp.id)
        return;

      this.valuenames = valuenames.map(e => {e.active = e.active? true:false; return e});

      this.from = new Date(template.von).toISOString()
      this.till = new Date(template.bis).toISOString()
      this.export_dialog = true;
    },
    'download-export-data': function({data}){
      this.csv_header = {date: {title:'date'}}
      if(!data.length){
        console.log('no data');
        return;
      }
      this.valuenames.filter(e => e.active).forEach(e => this.csv_header[e.name] = {title:e.name});
      this.mydata = data
      this.export = true;
    }
  },
  computed:{
    token(){
      return this.$store.getters.token
    }
  },
  data(){
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate( yesterday.getDate() - 1)

    return{
      filename: 'export_' + today.toLocaleDateString('de-DE', { year: 'numeric', month: 'numeric', day: 'numeric' }),
      csv_header: {},
      mydata: [],
      edit_active: false,
      from: yesterday.toISOString(),
      till: today.toISOString(),
      export_dialog: false,
      valuenames: [],

      export: false,
    }
  },updated(){
    this.$nextTick(() => {
      if(this.export){
        this.$refs.downloadcsv.$el.click();
      }
      this.export = false      
    })
  }
}
</script>
<style>
.export-template{
  display: inline-flex;
  flex-direction: row;

  margin: 5px;
  background-color: var(--color1 );

  width: calc(50% - 2pt - 10px);
  border: 1pt solid var(--color5);
  background-color: var(--color1);

  cursor: pointer;
}
.export-template.edit{
  border: 1pt solid var(--color5);

  border-color: var(--color5)
}
.export-template:nth-child(2n + 1){
  margin-right: 2.5px;
}
.export-template:nth-child(2n){
  margin-left: 2.5px;
}


.export-template.edit.edit_active{
  border-style: dashed;

  border-color: var(--color2);

}
.export-template .name{
  margin: 0 5pt;
}
.export-template h2{
  margin:0;
  margin-bottom: 0;
  padding: 2.5px;
}
.export-template h3{
  margin-top: 0;
  text-align: left;
}
.export-template .placeholder{
  flex: 1 0 0;
}
.export-template .export{
  flex: 0 0 50pt;
  margin: 5pt;
  cursor: pointer;
  background-image: url('../images/file-export-solid.svg');
}
.export-dialog .md-dialog-container{
  min-height: 500px;

  display: flex;
  flex-direction: column;
}
.export-dialog .md-dialog-body{
  flex: 1 0 0;
}
.export-dialog .centered-middle{
  margin-top: 20px;
}
.export-dialog .date-wrapper{
  width: 200px;
  margin: 0 auto;
}
.export-dialog .valuenames-export{
  max-height: 300px;
  flex: 0 0 300px;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
</style>