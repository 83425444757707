<template>
    <!--Kategories-->
    <md-dialog :md-active="show" class="" v-on:md-closed="$emit('close')" v-on:md-clicked-outside="$emit('close')">
        <md-dialog-title>Kategories</md-dialog-title>
        <div  style="min-width: 410px; overflow-y:scroll;">
            <draggable v-model="kategories" handle=".moving-bars"> 
                
                <div v-for="(item) in kategories" :key="item.uuid" class="kategorie-row horizontal-wrapper">
                    <md-icon class="fa fa-bars moving-bars" style="margin: auto 10pt"></md-icon>
                    <md-switch v-model="item.active" class="md-primary" ></md-switch>
                    <label-with-edit :item="item" v-on:deselect-all="kategories.forEach(e => e.edit = false)" :edit.sync="item.edit" :name.sync="item.name" style="flex: 1 0 0;"/>
                    <div class="trashcan icon" v-on:click="uploadImage(item)"
                        :style="`margin: 10pt 20pt; flex: 0 0 20pt; background-image:url(${require('../images/upload-solid.svg')}`">
                    </div>
                    <div 
                        class="trashcan icon" 
                        v-on:click="deleteKategorie(item)"
                        :style="`margin: 10pt 20pt; flex: 0 0 20pt; background-image:url(${require('../images/trash-alt-solid.svg')}`">
                    </div>
                </div>
            </draggable>
            <div class="kategorie-row add vertical-wrapper" v-on:click="add_kategorie">
                <span style="flex: 1 0 0">+</span>
            </div>
        </div>
        
        <md-dialog-actions>
            <md-button class="md-primary" @click="$emit('close')">Close</md-button>
            <md-button class="md-primary" @click="save_kategories()">Save</md-button>
        </md-dialog-actions>
    </md-dialog>
</template>
<script>
import { v4 as uuid } from 'uuid';

import labelwithedit from '@/components/VueComponents/label-with-edit.vue'
import draggable from 'vuedraggable'

export default {
    name: 'kategories-dialog',
    props: {show:Boolean, maschine:Object, editObject:Object},
    components: {
        draggable,
        'label-with-edit':labelwithedit
    },
    watch:{
        show(){
            if(this.show)
                this.$socket.emit('load-kategories-list', {token: this.token, maschine: this.maschine, item: this.editObject})

        }
    },
    computed:{
        token(){
            return this.$store.getters.token
        }
    },
    methods:{
        add_kategorie(){
            const kategorie = {id: -1, name: 'new kategorie', edit:false,active:false, ktg_id:null, uuid: uuid(), order:1}
            this.kategories.push(kategorie)
            this.$socket.emit('add-kategories-to-list', {token: this.token, maschine:this.maschine, kategorie:kategorie})
        },
        save_kategories(){
            let orderid = 1
            this.kategories.forEach(e => e.order = orderid ++)
            this.$socket.emit('edit-kategories-list', {token:this.token, maschine:this.maschine, item: this.editObject, kategories:this.kategories})

            this.$emit('close')
        },
        uploadImage(){

        },
        deleteKategorie(kategorie){
            this.$swal.fire({     
                icon: 'question',
                title: 'Delete?',
                text: 'Sure you want to delete kategorie?',

                showConfirmButton: true,
                confirmButtonText: 'Yes',

                showDenyButton: true,
                denyButtonText: 'no',
            }).then(e => {
                if(e.isConfirmed){
                    this.$socket.emit('delete-kategorie', {token:this.token, maschine:this.maschine, item: this.editObject,  kategorie: kategorie})
                }
            })

        }
    },
    sockets:{
        'show-kategories-list': function(kategories){

            kategories.forEach(e =>{ e.edit = false;e.active = e.active? true:false; e.uuid = uuid() });
            this.kategories = kategories
        },
        'kategorie-list-added': function({id, uuid}){
            const my_kategorie = this.kategories.find(e => e.uuid == uuid)
            if(my_kategorie)
                my_kategorie.id = id;

        }
    },
    data(){
        return{
            kategories: []
        }
    }
    
}
</script>
<style>

</style>