<template>
    <div class="horizontal-wrapper" style="overflow-x: scroll;">
        <div class="side-navigator" style="" :class="{'show': display.showKategories }">
            <maschine-opener :id="0" :name="'Alle'"></maschine-opener>
            <div class="horizontal-wrapper" style="flex: 0 0 auto; margin: 5pt;  margin-top: 5pt" v-on:click="$router.push({ name: 'main-global-notification' })">
                <div class="mask-icon" style="flex: 0 0 30pt; background-color: white;" :style="`mask-image: url(${require('../../assets/icons/triangle-exclamation-solid.svg')})`"></div>
                <p style=" font-size: 18pt; display: block;padding-right: 30pt;">Benachrichtigungen</p>
            </div>
            <ul>
                <li v-for="n in notificationsKategories" :key="n">
                    <router-link class="item" :to="{ name: 'main-global-notification', params: { header: n } }" >{{ n }}</router-link>
                </li>
            </ul>
            <div class="horizontal-wrapper" style="flex: 0 0 auto; margin: 5pt;  margin-top: 20pt">
                <div class="mask-icon" style="flex: 0 0 30pt; background-color: white;" :style="`mask-image: url(${require('../../assets/icons/globe-solid.svg')})`"></div>
                <p style=" font-size: 18pt">Global Dashboards</p>
            </div>
            <ul>
                <li v-for="gD in globalDashbaords" :key="gD.key" >
                    <router-link class="item" :to="{ name: 'main-global-dashboard', params: { id: gD.id } }" >
                            {{ gD.ownerName.trim() }}{{ gD.ownerName == '' ? '' : ': ' }} {{ gD.name }}
                    </router-link>
                </li>
            </ul>
            <router-link v-if="admin > 0" class="horizontal-wrapper offline-btn" style="flex: 0 0 auto; padding: 5pt;  margin-top: 20pt; text-decoration: none;" :to="{ name: 'main-global-offline' }" >
                <div class="mask-icon" style="flex: 0 0 30pt; background-color: white;" :style="`mask-image: url(${require('../../assets/icons/wifi-out-solid.svg')})`"></div>
                <p style=" font-size: 18pt; color: white; text-decoration: none;">Offline ({{ globalView.offlineMaschines.length }})</p>
            </router-link >
        </div>
          
        <div style="flex: 6 0 0" class="vertical-wrapper">
            <router-view/>
        </div>

        <md-speed-dial class="md-primary md-bottom-right" :class="{'hide': !display.editorMode }">
            <md-speed-dial-target class="md-primary">
            <md-icon :md-src="require('../../assets/icons/pen-solid.svg')" style="fill: white">add</md-icon>
            </md-speed-dial-target>

            <md-speed-dial-content >
            <md-button class="md-icon-button" v-on:click="$router.push({ name: 'main-global-dashboard-charts' })">
                <md-icon :md-src="require('../../images/add-items-icons/chart-line-solid.svg')">Chart</md-icon>
            </md-button>

            <md-button class="md-icon-button" v-on:click="$router.push({ name: 'main-global-dashboard-settings' })">
                <md-icon :md-src="require('../../assets/icons/globe-solid.svg')">event</md-icon>
            </md-button>
        
            </md-speed-dial-content>
        </md-speed-dial>

    </div>
</template>
<script>
import { mapState } from "vuex";
import maschineOpener from '../../components/maschine-opener.vue';

export default {
    name: 'global-view',
    components: { maschineOpener },
    computed: {
        token() {
            return this.$store.getters.token
        },
        globalDashbaords() {
            return this.$store.getters.getGlobalDashboards
        },
       
        notificationsKategories(){
            const messages = this.$store.getters.getMessageFrames

            return messages.reduce((arr, m) => {
                if(!arr.find(e => e == m.header))
                    arr.push(m.header)
                return arr
            },[])
        },
        showSideBar(){
            return  !this.$isMobil() 
        },
        admin(){
            return this.$store.getters.getAdmin
        },
        ...mapState(["display"]),
        ...mapState(["globalView"]),


    },
    methods: {
        clickOnOffline(){
            this.$router.push({name: 'main-global-offline'})
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$socket.emit('get-global-dashboards', { token: vm.token });

            vm.$socket.emit('overview-ew-frames', { token: vm.token });

            vm.$store.dispatch('globalView/fetch-offline-maschines')
         
        })
    },
    sockets:{
        'set-global-dashboards': function({ globalDashboards }){
            globalDashboards.forEach(e => e.key = this.$uuid.v4())
            this.$store.commit('set-global-dashboards', globalDashboards)
        },
        'show-all-ew-frames': function (ewframes) {
            this.$store.commit('set-message-frames', ewframes)
        }
    },
    data(){
        return{
           
        }
    }
}
</script>
<style>
div.side-navigator{
    flex: 1 0 0;
    background-color: var(--color3);
    color: var(--color1);

}
.side-navigator ul{
    padding: 0;
    margin: 2pt 0 ;
}
.side-navigator ul li{
    list-style-type: none;
    padding: 5pt;
    text-align: left;
    font-size: 14pt;

}
.side-navigator ul > li > a.item{
    text-decoration: none;
    color: var(--color1);
    display: block;
    padding-left: 20pt;

}
.side-navigator ul > li > a.item:hover{
    text-decoration: none;
    color: var(--color1);
}
.side-navigator ul li:has( a.item.router-link-active ){
    background-color: var(--color2);
}


.offline-btn.router-link-active{
    background-color: var(--color2);

}

.offline-btn p{
    color: white;
    text-decoration: none;
}
.offline-btn:hover{
    text-decoration: none;
}


.offline-btn:hover p{
    text-decoration: none;
}


@media screen and (max-device-width: 800px) {/*für mobil*/
    .side-navigator{
        display: none;
        transition: display 0.4s; 
        padding-left: 0pt;

    }
    .side-navigator.show{
        display: flex;
        flex-direction: column;

        position: fixed;
        width: 100vw;
        height: calc(100vh - 42pt);
        z-index: 11;
        padding-left: 2pt;
    }
}
</style>