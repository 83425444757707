<template>
    <div>
        <md-dialog :md-active.sync="show" style="overflow-y: scroll; "  v-on:md-clicked-outside="$router.go(-1)">
            <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                <md-dialog-title style="display: inline">Benachrichtigungen</md-dialog-title>
             
            </div>

            <div class="vertical-wrapper" style="margin: 0 24px">
               
                <div v-if="showPushOptions" class="vertical-wrapper">
                    <div style="flex: 0 0 auto">
                        Bei welchen Events soll eine Push Nachricht generiert werden
                    </div>
                    <md-switch style="flex: 0 0 auto" class="md-primary" v-model="error">Fehler Meldungen</md-switch>
                    <md-switch style="flex: 0 0 auto" class="md-primary" v-model="message_frame">Message Frame</md-switch>
                    <md-switch style="flex: 0 0 auto" class="md-primary" v-model="status">Verbindungsstatus</md-switch>

                </div>
                <div v-else class="vertical-wrapper">
                    <div style="flex: 0 0 auto">
                        Registrieren für Push Nachrichten über App
                    </div>
                    <md-button style="margin: 6px 0px" class="md-primary md-raised" v-on:click="subscribeToPushManager">Registrieren</md-button>
                </div>
            </div>
            <md-dialog-actions style="flex: 0 0 auto; padding: 24px" class="horizontal-wrapper">
                    <md-button class="md-primary" style="flex: 1 0 0;" v-on:click="$router.go(-1)" >Schließen</md-button>
                    <md-button class="md-primary md-raised" style="flex: 1 0 0;" v-on:click="editPushManagerDetails" >Speichern</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>
<script>

export default {
    name: 'push-manager-dialog',
    
    methods:{
        subscribeToPushManager(){
            this.$subscribeToPushManager().then(() => {
                this.getPushManagerDetails()
            }).catch(e => {
                alert(e)
            })
        },
        editPushManagerDetails(){
            
            const body = JSON.stringify({ 
                pushManagerRegistration: this.getPushManagerRegistration, 
                pushOptions: { 
                    error: this.error? 1: 0 ,
                    status: this.status? 1: 0,
                    message_frame: this.message_frame? 1: 0 
                }  
            })

            fetch(`${this.$hostname}/user/push-manager/edit`, {
                    method: 'POST', 
                    headers: { authorization: this.token, "Content-Type": "application/json", },
                    body
            }).then(async (e) => {
                if(e.status == 200)
                    this.$router.go(-1)
            }).catch()
        },
        getPushManagerDetails(){

            const body = JSON.stringify({ pushManagerRegistration: this.getPushManagerRegistration })

            fetch(`${this.$hostname}/user/push-manager/details`, {
                    method: 'POST', 
                    headers: { authorization: this.token, "Content-Type": "application/json", },
                    body
            }).then(async (e) => {
                    if(e.status == 200){
                        const body = await e.json()
                        
                        // if body pushOptions is a object and true push registration is complete
                        if(body.pushOptions){
                            this.showPushOptions = true
                            const pO = body.pushOptions;
                            this.error = pO.error? true: false
                            this.message_frame = pO.message_frame? true: false
                            this.status = pO.status? true: false
                            

                        }else{
                            this.showPushOptions = false
                        }
                }
            })

        },

    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.show = true;
            vm.getPushManagerDetails()

        })

        
    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        getPushManagerRegistration(){
            return this.$store.getters.getPushManagerRegistration;
        },
        getPushManagerStatus(){
            return this.$store.getters.getPushManagerStatus;
        }

    },
    data(){
        return{
            show: false,

            showPushOptions: false,

            error: false,
            message_frame: false,
            status: false
        }
    }
    
}
</script>