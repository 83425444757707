<template>
  <div class="schema" :class="{'edit':schema.edit}" @click.self="$emit('item-clicked', schema)" >
    <!--

      <pipe v-for="(p,i) in pipes" :key="i + '_pipe'" :item="p" :edit="edit" v-on:item-clicked="$emit('item-clicked', p)" v-on:item-changed="item_changed" v-on:item-moved="item_moved" />
      
      <valve v-for="valve in valves" :key="valve.key" :valve="valve" :edit="edit" v-on:item-clicked="$emit('item-clicked', valve)" v-on:item-changed="item_changed" v-on:item-moved="item_moved"/>
      <pump v-for="pump in pumps" :key="pump.key" :pump="pump" :edit="edit" v-on:item-clicked="$emit('item-clicked', pump)" v-on:item-changed="item_changed" v-on:item-moved="item_moved"/>
      <tank v-for="tank in tanks" :key="tank.key" :tank="tank" :edit="edit" v-on:item-clicked="$emit('item-clicked', tank)" v-on:item-changed="item_changed" v-on:item-moved="item_moved"/>
      <data-label v-for="data_label in labels" :key="data_label.key" :datalabel="data_label" :edit="edit" v-on:item-clicked="$emit('item-clicked', data_label)" v-on:item-changed="item_changed"/>
      <my-image v-for="image in images" :key="image.key" :my-image="image" :edit="edit" v-on:item-clicked="$emit('item-clicked', image)" v-on:item-changed="item_changed"/>
    -->
  </div>
</template>
<script>
import vue from 'vue'
/*import pipe from '@/components/schema_components/pipe.vue'
import valve from '@/components/schema_components/valve.vue'
import pump from '@/components/schema_components/pump.vue'
import tank from '@/components/schema_components/tank.vue'
import data_label from '@/components/schema_components/data-label.vue'
import myimage from '@/components/schema_components/image.vue'
*/

export default {
  name: 'schema',
  props: { schema:Object, maschine:Object, edit:Boolean },
  components:{
   // pipe, valve, pump, tank, 'data-label':data_label, 'my-image':myimage
  },
 
  methods:{
    item_changed(){
      console.log('item changed', this.objects.filter(e => e.edit));
      this.objects.filter(e => e.edit).forEach(item => {



        this.$socket.emit('edit-schema-item', {token: vue.$token, maschine: this.maschine, schema:this.$props.schema, item: item})
      })
    },
    item_moved({oldPos, newPos}){
      const moveDiffX = oldPos.x - newPos.x;
      const moveDiffY = oldPos.y - newPos.y;

      this.objects.filter(e => e.edit).forEach(e => {
         e.x = e.x - moveDiffX;
         e.y = e.y - moveDiffY;
       })

    }
  },
  sockets:{
    build_schema({objects, schema}){
      if(this.$props.schema.id != schema.id)
        return  
             
      objects.forEach(e =>{ e.edit = false; e.key = this.$uuid.v4()});
      //this.schema.objects = objects;
      this.objects = objects;

      this.pipes = objects.filter(o => o.type == 'pipe' && o.IDh_graph == this.$props.schema.id)
      this.valves = objects.filter(o => o.type == 'valve' && o.IDh_graph == this.$props.schema.id)
      this.pumps = objects.filter(o => o.type == 'pump' && o.IDh_graph == this.$props.schema.id)
      this.tanks = objects.filter(o => o.type == 'tank' && o.IDh_graph == this.$props.schema.id)
      this.images = objects.filter(o => o.type == 'image' && o.IDh_graph == this.$props.schema.id).map(o => {o.svg_text = ''; return o})
      this.labels = objects.filter(o => o.type == 'label' && o.IDh_graph == this.$props.schema.id)
    }
  },
  data(){

    return{
      pipes: [],
      valves: [],
      pumps: [],
      tanks: [],
      labels: [],
      levers: [],
      images: [],

      objects: []
      }
    }, 
  mounted(){
    //this.schema.objects = []
    this.$socket.emit('load_schema', {token:vue.$token, schema:this.schema, maschine:this.maschine})

  }
}
</script>
<style>
.schema{
  position: relative;

  background-color: var(--color1);
  margin: 5px;
  border: 1pt solid var(--color5);
  width: calc(100% - 10px - 2px);
  height: 400px;
}
.schema.edit{
  border-style: dashed;
  border-color: var(--color2);
}
.schema > div{
  position: absolute;

}
.testpipe{
  top: 10px;
  left: 10px;
}
@media screen and (max-device-width: 800px) {/*für mobil*/
  .schema{
   overflow: scroll;
  }
}
</style>