<template>
  <div id="app" class="vertical-wrapper">
    <router-view/>
  </div>
</template>

<script>
import vue from 'vue'
//import firebaseInitialize from './firebase'
export default {
  components:{
   
  },
  computed:{
    token(){
      return this.$store.getters.token;
    }
  },
  sockets:{
    connect() {
      if(vue.$token && !this.$router.history.current.path.includes('/login-via-url')){
        this.$socket.emit('checkToken', {token: vue.$token, isMobil: false, device_token: this.$cookies.get('device-token')})
      }
    },
    login_sucess({ authendifiactionString, admin }){
      const token = authendifiactionString;

     // const deviceToken = this.$cookies.get('device-token')
      if(!this.firebaseConnected){
        //firebaseInitialize(this.$socket, token, deviceToken)
        this.firebaseConnected = true
      }
      this.loggedin = true
      vue.$token = token;

      // set store variables
      this.$cookies.set('user-token', token);
      this.$store.commit('set-token', token);
      this.$store.commit('set-admin', admin);

      
      this.$nextTick(() => {
        if (this.$router.history.current.path == '/' || this.$router.history.current.path.includes('/login-via-url'))
          this.$router.push({ name: 'main-global-view' })

      })
      
    }, 
    'set-user-creditals':function({id, name}){
      this.$user.id = id
      this.$user.name = name
    },
    'show-login':function(){
     
      if (this.$router.history.current.path != '/')
        this.$router.replace('/')
    },
    'set-device-token':function({device_token}){
      this.$cookies.set('device-token', device_token)
      

    }, 
    'route-to': function({route}){
      if(route.includes('{'))
        route = JSON.parse(route)
      this.$router.push(route)
    },
    'license-not-active':function(){
      this.$swal.fire({
        title:'Error',
        text: 'Lizenz ist abgelaufen',
        icon: 'error'
      })
    },

    'show_maschines':  function({ maschines }){
      // setup maschine in the global store
      maschines.forEach(item => { item.active = false; item.connect = false });
      this.$store.commit('set-maschines', maschines)

      // remove maschine active interval if there is any
      if (this.maschineActiveInterval)
        clearInterval(this.maschineActiveInterval)

      // add new maschine active request interval 
      // ask every 15 seconds if maschine is online
      this.maschineActiveInterval = setInterval(() => {
        this.$socket.emit('load_maschines_active', { token: this.token })
      }, 15000);

    },
    'set-maschine-search-attributes': function({ searchAttributes }){
      const attributes = [ {
          key: this.$uuid.v4(),
          text: 'Name',
          name: 'text',
          value: '',
       } , 
        ...searchAttributes.map(e => {
          return {
            key: this.$uuid.v4(),
            text: e.text,
            name: e.name,

            value: '',
          }
      })];
      this.$store.commit('set-maschine-search-attributes', attributes)
    },
    'show_maschines_active': function ({ maschines }) {


      this.$store.commit('set-maschines-status', maschines)
    },
    
    'set-mandates': function (mandates) {
      this.$store.commit('set-mandates', mandates)
    },
  },
  data(){
    return{
      firebaseConnected: false,
    }
  },
  mounted(){
  },
  created(){
    vue.loggedin = false
  }

}
</script>

<style>
@import '~../public/styles/steuerelement.css';

body, html{
  margin: 0;
  -moz-transform: scale(1, 1); /* Moz-browsers */
  zoom: 1; /* Other non-webkit browsers */
  zoom: 100%; /* Webkit browsers */
}
#app {
  font-family: Roboto,Noto Sans,-apple-system,BlinkMacSystemFont,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}
.icon{
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
a{
  text-decoration: none;
}
a:hover{
  text-decoration: none;
}
p{
  margin: 0;
}
p.inline{
  text-align: left;
  display: inline;
}
/* width */
::-webkit-scrollbar {
  width: 2.5px;
  height: 2.5px;

}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
