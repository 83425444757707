<template>
    <!--Data Sources -->
    <md-dialog :md-active="open" class="" v-on:md-closed="$emit('close')" v-on:md-clicked-outside="$emit('close')">
        <md-dialog-title>Data Source</md-dialog-title>

        <md-tabs md-dynamic-height v-on:md-changed="data_source_tab_changed">
            <md-tab md-label="Valuename" id="0">
                <div class="vertical_wrapper">

                    <md-field>
                        <label>Searchtext</label>
                        <md-input v-model="valuenamesSearchText"></md-input>
                    </md-field>

                    <div class="valuenames" style="max-heigth: 460px">
                        <div class="horizontal-wrapper" v-for="item in valuenamesSelection" 
                            :key="item.key" :style="item.openText? 'padding: 0':'padding: 7.5pt 0' ">

                            <md-switch v-model="item.active" class="md-primary" style="margin: auto"
                                v-on:change="(e) => valuename_switch_toggle(e, item, true)">{{`${item.name}
                                [${item.unit}]: ${item.value}`}}
                            </md-switch>
                            <div class="placeholder"></div>
                           
                            <div style="flex: 0 0 40pt;" :class="{'hide': !item.active}" class="vertical-wrapper">
                                <div></div>
                                <input type="color" v-model="item.color" />
                                <div></div>
                            </div>
                            <!--
                            <div class="icon" style="flex: 0 0 30pt"></div>
                            <md-field v-if="item.active && item.openText">
                                <label for="">Name</label>
                                <md-input v-model="item.aliasName"></md-input>
                            </md-field>
                            <div class="icon" :class="{'hide': !item.active}" v-on:click="() => {item.openText = !item.openText;}"
                                :style="`flex: 0 0 17.5pt; cursor: pointer; background-image: url(${item.openText? require('../images/xmark-solid.svg'): require('../images/pen-solid.svg')});`">
                            </div>
                           
                            <div class="icon" style="flex: 0 0 30pt"></div>
                        -->

                            <div class="icon" style="flex: 0 0 30pt"></div>
                            <md-field v-if="item.active && item.openUnit">
                                <label for="">Einheit</label>
                                <md-input v-model="item.unitOverride"></md-input>
                            </md-field>
                            <div class="icon" :class="{ 'hide': !item.active }" v-on:click="() => { item.openUnit = !item.openUnit; }"
                                :style="`flex: 0 0 17.5pt; cursor: pointer; background-image: url(${item.openUnit ? require('../images/xmark-solid.svg') : require('../images/pen-solid.svg')});`">
                            </div>
                        
                            <div class="icon" style="flex: 0 0 30pt"></div>
                            

                        </div>
                    </div>
                </div>
            </md-tab>

            <md-tab md-label="Calculation" id="1">
                <div class="vertical_wrapper">
                    <label for="">available Equations</label>
                    <div class="horizontal_wrapper">
                        <div class="horizontal_wrapper" style="flex: 1 0 0">
                            <md-chip v-for="item in availableEquations" :key="item.id + $uuid.v4()"
                                v-on:click="selectAvailableEquation(item)" class="" md-clickable>{{item.name}}</md-chip>
                        </div>
                        <div>
                            <md-switch class="md-primary" v-model="editCalculation">edit calculation</md-switch>
                        </div>
                    </div>
                    <div class="horizontal_wrapper">
                        <md-field style="flex: 3 0 0; margin:2.5pt;">
                            <label>Name</label>
                            <md-input v-model="vn_name"></md-input>
                        </md-field>
                        <md-field style="flex: 1 0 0; margin:2.5pt;">
                            <label>Unit</label>
                            <md-input v-model="vn_unit"></md-input>
                        </md-field>
                    </div>
                    <md-field>
                        <label>Equation</label>
                        <md-textarea v-model="equation" ref="equation_input" @input="equation_changed"></md-textarea>
                    </md-field>
                    <md-field>
                        <label>Searchtext</label>
                        <md-input v-model="valuenamesSearchText"></md-input>
                    </md-field>
                    <div class="" style="overflow-y: scroll; flex: 1 0 100px">
                        <md-chip v-for="(item, index) in valuenamesSelection" :key="index + 'calc_valuenames'" class=""
                            md-clickable v-on:click="add_valuename_to_equation(item)">{{`${item.name}
                            [${item.unit}]` }}
                        </md-chip>
                    </div>

                </div>





                <div class="info">
                    <h3>Current Output:</h3>
                    <div>
                        <div v-if="equation_error" class="result-item">
                            <div class="images error">
                                <div class="icon"></div>
                            </div>
                            <p class="p-in-middle">Error in equation</p>
                        </div>
                        <div v-if="!equation_error" class="result-item">
                            <div class="images sucess">
                                <div class="icon"></div>
                            </div>
                            <p class="p-in-middle">{{equation_result}}</p>
                        </div>
                    </div>
                </div>
            </md-tab>

            <md-tab md-label="SQL" id="2">
            </md-tab>
            <md-tab md-label="Model" id="3">
                <model-selector ref="model-selector" :maschine="maschine" :edit-object="editObject"
                    :open="this.tab == '3'"></model-selector>
            </md-tab>
        </md-tabs>

        <md-dialog-actions>
            <md-button class="md-primary" @click="$emit('close')">Close</md-button>
            <md-button class="md-primary" @click="save_data_source()">Save</md-button>
        </md-dialog-actions>
    </md-dialog>
</template>
<script>
import modelSelectorVue from '@/components/model-selector.vue'

export default {
    name: 'data-source-dialog',
    components: {
        'model-selector':modelSelectorVue,
    },
    props: {
        'open':Boolean,
        'maschine':Object,
        'edit-object':Object,
        'edit-type':String,
        'data-binding-type':String
    },
    watch:{
        open(){
            if(this.open){
                this.$socket.emit('get-chart-source', {token: this.token, maschine: this.maschine, chart:this.editObject})
            }
        }
    },
    methods:{
        data_source_tab_changed(tab){
            this.tab = tab
            if(tab != 2){
                /*Bei tab 0 or 1*/
                this.$socket.emit('load-available-valuenames', {token: this.token, maschine:this.maschine, type:(this.editType == 'graph')? 'graph': 'schema', source_type:this.databinding_type, schema:this.$props.schema, item: this.$props.editObject})
            }
            if(tab == 1){
                this.$socket.emit('load-available-equations', { token: this.token, maschine: this.maschine, editObject: this.editObject})

            }
        },
        valuename_switch_toggle(value, item, chart){
            if(!chart)
                this.valuenames.forEach(e => e.active = value && e.id == item.id);
      
        },
        save_data_source(){
            let source_type = ['valuename', 'calculation', 'sql', 'model'][this.tab];
            if (source_type == 'valuename'){
                if(this.editType == 'schema-item'){
                    this.$socket.emit('save-schema-element-data-source', {
                    token: this.token, 
                    maschine: this.maschine, 
                    schema:this.$props.schema, 
                    item: this.$props.editObject, 
                    source_type: ['valuename', 'calculation', 'sql'][this.tab],
                    data_binding_type: this.dataBindingType,
                    valuename: this.valuenames.find(e => e.active)
                    })
                }else if(this.editType == 'graph'){
                    this.$socket.emit('save-graphs-valuenames', {
                        token: this.token,
                        maschine: this.maschine,
                        source_type: ['valuename', 'calculation', 'sql'][this.tab],
                        graph: this.editObject,
                        valuenames: this.valuenames,
                    })
                }else{
                    console.log( ['valuename', 'calculation', 'sql', 'model'][this.tab])
                }
            }
            else if (source_type == 'calculation'){
                if(this.vn_name != ''){
                    this.save_calulation = true;
                    this.$socket.emit('data-source-equation-test', {token:this.token, maschine:this.maschine, equation:this.equation})
                }else{
                    this.$swal('Warning', 'I need a name', 'warning')
                    return
                }
            }
            else if (source_type == 'model'){
                if (0 < this.$refs['model-selector'].save())
                return 
            }
            this.$emit('close')

            
        },

        //Equations
        add_valuename_to_equation(item){
            let cursor_positon = this.$refs['equation_input'].$el.selectionStart

            if(!cursor_positon)
                cursor_positon = this.equation.length


            this.equation = this.equation.substr(0, cursor_positon) + `{${item.name}\\${item.unit}}` + this.equation.substr(cursor_positon)
            this.$socket.emit('data-source-test-equation', )

        },
        equation_changed(){
            this.$socket.emit('data-source-equation-test', {token:this.token, maschine:this.maschine, equation:this.equation})
        },
        selectAvailableEquation(item){
            this.vn_name = item.name
            this.vn_unit = item.unit
            this.equation = '=' + item.operation
        },
       
    },
    sockets:{
        'show-available-valuenames':function ({valuenames}){
            valuenames.forEach(e => {
                e.active = e.active? true : false; 
                e.openText = false;
                //e.openUnit = false;

                
                e.key = this.$uuid.v4()
            })
            this.valuenames = valuenames
        },
        'data-source-equation-result': function({result}) {
            this.equation_error = false;
            this.equation_result = '= ' + result
            if(this.save_calulation){
                this.save_calulation = false;
                this.$socket.emit('data-source-save-equation', {
                    token:this.token, 
                    maschine:this.maschine, 
                    graph: this.editObject, 
                    valuename:{name:this.vn_name, unit:this.vn_unit}, 
                    equation:this.equation,
                    editCalculation: this.editCalculation
                })
            }
        },
        'data-source-equation-error': function () {
            this.equation_error = true;
            if(this.save_calulation){
                this.save_calulation = false;
                this.$swal('Equation where not saved', '', 'Error')
            }
        },
        'show-available-equations':function({equations}){
            console.log(equations);
            this.availableEquations = equations;

            const activeEquation = equations.find(e => e.active);
            if(activeEquation){
                this.selectAvailableEquation(activeEquation)
            }else
                this.selectAvailableEquation({name: '', unit: '', operation: ''})

        },
        'set-chart-source':function({source, }){
            this.tab = source == 'data' ? 0 : this.tab
            this.tab = source == 'calc' ? 1 : this.tab
            this.tab = source == 'sql' ? 2 : this.tab
            this.tab = source == 'model' ? 3 : this.tab

        }



    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        valuenamesSelection(){
            return this.valuenames.filter(e => e.name.toLowerCase().includes(this.valuenamesSearchText.toLowerCase()))
        }
    },
    data(){
        return{
            tab:0,
            /*Data sources*/
            data_split: 'no split',
            databinding_type: '',
            valuenames: [],

            /*Equations*/    
            equation: '=',
            equation_error: true,
            equation_result: '',
            vn_name: '',
            vn_unit: '',
            save_calulation: false,
            editCalculation:false,

            valuenamesSearchText: '',
            availableEquations: []
        }
    }
}
</script>