<template>
  <div class="all-maschines" :class="{'hide':!open}">
    <div class="maschine_selector">
      <h2>My Maschines</h2>
      <md-button v-on:click="$emit('open-maschines')" class="md-primary">{{$translate('maschines')}}</md-button>

      <div class="seperator"></div>
    </div>
    <maschine-ew-frame v-for="maschine in maschines.filter(e => e.ewframes.filter(a => filterName=='' || a.header == filterName).length)" :key="uuid.v4() + maschine.id" :maschine="maschine" :ewframes="maschine.ewframes" :filter="filterName"/>
  </div>
</template>
<script>
import maschine_ew_frame from '@/components/maschine-ew-frame.vue'
import { uuid } from 'vue-uuid'

export default {
  name: 'all-maschines',
  props: {open:Boolean, 'filter-name':String},
  components:{
    'maschine-ew-frame':maschine_ew_frame
  },
  methods:{
    get_active_ew_frames(){
    }
  },
  sockets:{
    'show-all-ew-frames':function(ewframes){
      this.maschines = []
      this.kategories = []

      ewframes.forEach(ew => {
        let maschine = this.maschines.find(e => e.id==ew.IDh_maschines);
        if(!maschine){
          maschine = {id: ew.IDh_maschines, name:ew.maschine_name, ewframes:[]}
          this.maschines.push(maschine)
        }
        maschine.ewframes.push(ew)

        let kategorie = this.kategories.find(e => e.text==ew.header)
        console.log(ew.header);
        if(!kategorie){
          kategorie = {id:uuid.v4(), text:ew.header, ewframes:[], active: false}
          this.kategories.push(kategorie)
        }
        kategorie.ewframes.push(ew)
      });
      this.$emit('refresh-kategories', this.kategories)

      this.$store.commit('set-message-frames', ewframes)
    }
  },
  watch:{
    open(){
      if(this.open){
        this.$socket.emit('overview-ew-frames', {token:this.token})
        this.$emit('clear-charts')
      }
    }

  },
  computed:{
    token(){
      return this.$store.getters.token
    }
  },
  data(){
    return{
      uuid: uuid,
      maschines:[
        {id:0, name:'maschine 1', ewframes:[]},
        {id:1, name:'maschine 2', ewframes:[]}

      ],
      kategories:[]
    }
  }
}
</script>
<style>
.all-maschines .maschine_selector{
  display: flex;
  border-bottom: 2pt solid var(--color5);
  margin: 5pt 20pt;

  flex-direction: column;
}
.all-maschines .maschine_selector{
  text-align: left;
}
</style>