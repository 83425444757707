<template>
    <div>
        <md-dialog :md-active.sync="show" style="overflow-y: scroll; ">
            <md-dialog-title>Maschine kopieren</md-dialog-title>
            <md-steppers>
                <md-step id="first" md-label="Quelle auswählen" class="vertical-wrapper">
                    <md-field md-clearable style="flex: 0 0 auto; width: 100%; margin-bottom: 2px;" >
                        <label for="">Suche</label>
                        <md-input style="width: 100%;" v-model="searchText"></md-input>
                    </md-field>
                    <div style="overflow-y: scroll; max-height: 400pt">
                        <md-switch v-for="m in filteredMaschines" :key="m.key">{{m.text}}</md-switch>
                    </div>
                </md-step>

                <md-step id="second" md-label="Kopier Attribute auswählen">

                </md-step>

                <md-step id="third" md-label="Bestätigen">

                </md-step>
            </md-steppers>
        </md-dialog>
    </div>
</template>
<script>
export default {
    name: 'copy-maschine-wizard',
    props: { 'target-maschine-id': [Number, String] },
    methods: {
        save(){
          
        }
    },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.show = true

            next();
        }) 
    },
    sockets:{
        

    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        urlAccessToken(){
            return this.$store.getters.urlAccessToken
        },
        maschines(){
            return this.$store.getters.getMaschines
        },
        filteredMaschines(){
            return this.maschines.filter(e => e.text.toLowerCase().includes(this.searchText.toLowerCase()))
        }
    },
    data(){
        return{
            show: false,
            searchText: ''
        }
    }
}
</script>