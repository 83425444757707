<template>
  <vue-draggable-resizable style="z-index: 10; overflow-x: hidden; top: 30px; left: 10px"  class="graphic_editor" :x="frame.x" :y="frame.y" :w="276" :h="586"  :class="{'hide':!open}" @dragging="onDrag" :draggable="!lock" :resizable="!lock">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <div class="header horizontal_wrapper" >
      <p>Editor</p>
      <div class="placeholder"></div>
      <md-switch  class="md-primary" v-model="lock"></md-switch>
    </div>
    <div v-if="edit_type == 'graph' || edit_type == 'export-template'">
      <md-field>
        <label>name</label>
        <md-input v-model="editObject.name" v-on:change="edit_object_changed"></md-input>
      </md-field>

       <md-field v-if="edit_type == 'graph'">
        <label>type</label>
        <md-select v-model="editObject.type" v-on:md-selected="edit_object_changed">
          <md-option v-for="(item, i) in graph_types" :key="i + 'types'" :value="item">{{item}}</md-option>
        </md-select>
      </md-field>
      
      <md-field v-if="edit_type == 'export-template'">
        <label>type</label>
        <md-select v-model="editObject.export_type" v-on:md-selected="edit_object_changed">
          <md-option v-for="(item, i) in export_types" :key="i + 'types'" :value="item">{{item}}</md-option>
        </md-select>
      </md-field>


      <p>Data:</p>
      <div v-if="edit_type != 'export-template'" v-on:click="open_data_source_dialog = true" class="dialog-opener">
        <label>Source</label>
        <md-icon class="fa fa-bars"></md-icon>
      </div>

      <div v-if="edit_type == 'export-template'" v-on:click="open_data_source_dialog = true" class="dialog-opener">
        <label>columns</label>
        <md-icon class="fa fa-bars"></md-icon>
      </div>
      

      <p>Kategories:</p>
      <div v-on:click="open_kategorie_dialog = true" class="dialog-opener">
        <label>Kategories</label>
        <md-icon class="fa fa-bars"></md-icon>
      </div>

      <p v-if="edit_type == 'graph'">Error/Warning:</p>
      <div v-if="edit_type == 'graph'" v-on:click="click_ew_frame_dialog()" class="dialog-opener">
        <label>Frames</label>
        <md-icon class="fa fa-bars"></md-icon>
      </div>

      <p v-if="edit_type == 'graph'">Graph Segments:</p>
      <div v-if="edit_type == 'graph'" v-on:click="open_chart_segement_dialog = true" class="dialog-opener">
        <label>Segments</label>
        <md-icon class="fa fa-bars"></md-icon>
      </div>

      <p v-if="edit_type == 'graph'">Graph Optionen:</p>
      <div v-if="edit_type == 'graph'" v-on:click="$refs['graphOptionsDialog'].show()" class="dialog-opener">
        <label>Optionen</label>
        <md-icon class="fa fa-bars"></md-icon>
      </div>


      <div class="action_button delete" style="margin-top: 20pt" v-on:click="delete_chart">
        <div class="icon" ></div>
        <label for="">delete</label>
      </div>



    </div>
    <div v-if="edit_type == 'schema'">
      <div class="schema_add">
        <p>add</p>
        <div v-on:click="add_item('pipe')">pipe</div>
        <div v-on:click="add_item('valve')">valve</div>
        <div v-on:click="add_item('pump')">pump / fan</div>
        <div v-on:click="add_item('tank')">tank</div>
        <div v-on:click="add_item('label')">label</div>
        <div v-on:click="add_item('image')">image</div>
      </div>
      <p>Kategories:</p>
      <div v-on:click="click_kategories_dialog()" class="dialog-opener">
        <label>Kategories</label>
        <md-icon class="fa fa-bars"></md-icon>
      </div>

       <div class="action_button delete" style="margin-top: 20pt" v-on:click="delete_schema">
        <div class="icon" ></div>
        <label for="">delete</label>
      </div>
    </div>
    <div v-if="edit_type == 'schema-item' || edit_type == 'image'">
      <p>General:</p>
       <md-field>
        <label>name</label>
        <md-input v-model="editObject.text"></md-input>
      </md-field>
      
      <md-field>
        <label>color</label>
        <md-input type="color" :value="editObject.color" v-on:change="(e) => $emit('property-changed', {property:'color', value:e.target.value})"></md-input>
      </md-field>

       <md-field >
        <label>item-type</label>
        <md-select v-model="editObject.display_type">
          <md-option v-for="(item, i) in schema_item_display[editObject.type]" :key="i + '-display-types'" :value="item">{{item}}</md-option>
        </md-select>
      </md-field>

      <div class="action_button delete" v-on:click="delete_item">
        <div class="icon" ></div>
        <label for="">delete</label>
      </div>

      <div class="layout-wrapper" :class="{ 'hide': multipleObjects}">
        <p>Layout:</p>
        <div class="layout horizontal_wrapper">
          <md-field>
            <label>left</label>
            <md-input v-model="editObject.x"></md-input>
          </md-field>
          <md-field>
            <label>top</label>
            <md-input v-model="editObject.y"></md-input>
          </md-field>
        </div>
        <div class="layout horizontal_wrapper">
          <md-field>
            <label>width</label>
            <md-input v-model="editObject.width"></md-input>
          </md-field>
          <md-field>
            <label>height</label>
            <md-input v-model="editObject.height"></md-input>
          </md-field>
        </div>
      </div>
      <!--
        Exclude Rotation from Layout Wrapper hiding when multiple Elements are selected, 
        because it can affect multiple Elements 
      -->
      <md-field>
        <label>rotate</label>
        <md-input :value="editObject.rotate? editObject.rotate: 0" v-on:change="(e) => $emit('property-changed', {property:'rotate', value:e.target.value})"></md-input>
      </md-field>
      
      <div :class="{ 'hide': multipleObjects}">
        <p>Data:</p>
        <div 
          v-for="(item, index) in editObject.valuenames" :key="index + 'valuenames_editor'"
          v-on:click=" databinding_type = item.type; open_data_source_dialog = true;" class="dialog-opener">
          <label>{{item.type + ': '}}</label>
          <md-icon class="fa fa-bars"></md-icon>
        </div>
        <div v-if="edit_type == 'image'">
          <md-field>
            <label>Only images</label>
            <md-file v-model="my_image" accept=".svg" v-on:change="image_change" />
          </md-field>
        </div>
      </div>
      
    </div>


    <data-source-dialog :open="open_data_source_dialog" :edit-object="editObject" :edit-type="edit_type" :maschine="maschine" v-on:close="open_data_source_dialog = false" :data-binding-type="databinding_type"></data-source-dialog>
    <!--Kategories-->
    <kategorie-dialog :show="open_kategorie_dialog" :maschine="maschine"  :edit-object="editObject" v-on:close="open_kategorie_dialog = false"/>

    <error-warning-frame-dialog :open="open_ewframes_dialog" v-on:close="open_ewframes_dialog = false" :maschine="maschine" :editObject="editObject"></error-warning-frame-dialog>

    <!--Chart Segments-->
    <graphs-segments-dialog :show="open_chart_segement_dialog" :maschine="maschine" :chart="editObject" v-on:close="open_chart_segement_dialog = false"></graphs-segments-dialog>

    <graph-options-dialog ref="graphOptionsDialog" :maschine="maschine" :chart="editObject" ></graph-options-dialog>

  </vue-draggable-resizable >
</template>
<script>
//import checkbox from '@/components/VueComponents/checkbox.vue'

import dataSourceDialogVue from '@/components/data-source-dialog.vue'
import graphsSegmentsDialogVue from './graphs-segments-dialog.vue'
import kategorieDialogVue from './kategories-dialog.vue'
import errorWarningFrameDialogVue from './error-warning-frame-dialog.vue'
import graphOptionsDialogVue from './graph-options-dialog.vue'

export default {

  name: 'grapic-editor', 
  props: { 'edit-object':Object, open:Boolean, edit_type:String, maschine:Object, 'multiple-objects':Boolean},
  components: { 
    'data-source-dialog': dataSourceDialogVue, 
    'graphs-segments-dialog': graphsSegmentsDialogVue, 
    'kategorie-dialog': kategorieDialogVue,
    'error-warning-frame-dialog': errorWarningFrameDialogVue,
    'graph-options-dialog':graphOptionsDialogVue
  },

  methods:{
    onDrag: function (x, y) {
      this.frame.x = x
      this.frame.y = y
    },
    data_change(){

    },
    add_item(item){
      this.$socket.emit('add-item-to-schema', {token: this.token, maschine:this.maschine, schema: this.$props.editObject, item:item})
      
      this.$socket.emit('load_schema', {token: this.token, maschine:this.maschine, schema: this.$props.editObject})

    },

    image_change(e){

      if(!e.target.files.length)
        return;
      const file = e.target.files[0]
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        this.editObject.svg_text = event.target.result;

        this.$socket.emit('edit-schema-image', {token: this.token, maschine:this.maschine, item:this.editObject})
      });
      reader.readAsText(file);
    },
    delete_item(){
      const answer = window.confirm("delete item?");
      if (answer) {
          this.$socket.emit('delete-schema-item', {token: this.token, maschine:this.maschine, item:this.editObject})
          this.$socket.emit('load_schema', {token: this.token, maschine:this.maschine, schema: this.$props.editObject.my_schema})

      }
     
    },
    edit_object_changed(){
      if(this.editObject.save)
        this.editObject.save()
    },


    /*kategories*/
    click_kategories_dialog(){
      
      this.open_kategorie_dialog = true; 
    },
   
    click_ew_frame_dialog(){
      console.log('click ew frame');
      this.open_ewframes_dialog = true;
    },
   
    
    
    delete_chart(){
      this.$swal.fire({
        icon: 'question',
        title: 'Delete?',
        text: 'Sure you want to delete Chart?',

        showConfirmButton: true,
        confirmButtonText: 'Yes',

        showDenyButton: true,
        denyButtonText: 'no',
      }).then(e => {
        if(e.isConfirmed){
          this.$socket.emit('delete-graph', {token: this.token, maschine:this.maschine, chart:this.editObject})
        }
      })
    },
    delete_schema(){
       this.$swal.fire({
        icon: 'question',
        title: 'Delete?',
        text: 'Sure you want to delete Schema and Items?',

        showConfirmButton: true,
        confirmButtonText: 'Yes',

        showDenyButton: true,
        denyButtonText: 'no',
      }).then(e => {
        if(e.isConfirmed){
          this.$socket.emit('delete-schema', {token: this.token, maschine:this.maschine, schema:this.editObject})
        }
      })
    },
    save_one_kategorie(){
    },

  
  },
  
  watch:{
    
  },
  computed:{
    token(){
      return this.$store.getters.token
    }
  },
  data(){
    
    return{
      lock: false,

      frame: {x:3, y:60},
      borders: [],
      graph_types: ['line', 'bar', 'status', 'control_button', 'control_switch', 'control_number'],
      edit_object: {name: '', type:'', active: true},
      export_types: ['.csv', '.pdf'],
      open_data_source_dialog: false,
      open_kategorie_dialog: false,
      tab:0,
      /*Data sources*/
      data_split: 'no split',
      databinding_type: '',
      valuenames: [],
     

      /*Schema Elements*/
      my_image: '',
      schema_item_display: {'pump':['pump', 'fan', 'lamp', 'motor'], 'tank':['tank', 'expansion tank']},
      kategories: [],

      /*Error/Warning Framse*/
      open_ewframes_dialog: false,
      ew_frames: [],
      ew_types: ['basic', 'daily_min_max'],
      ew_icons: ['warn', 'error', 'info'],
      avaliable_users: [],
      
      user:this.$user,

      open_chart_segement_dialog:false
    }
  }

}
</script>
<style>
.graphic_editor{
  position: fixed;

  height: 1000px;
  width: 700px;
  background-color: white;
  border: solid 2pt var(--color5);
  z-index: 200;
  overflow-y: scroll;
}
.graphic_editor .header{
  background-color: var(--color3);
  color: var(--color1);

}
.graphic_editor .header p{
  margin: auto;
  padding-left: 5pt;
  font-size: 18pt;
}
.graphic_editor label{
  display: flex;
  flex-direction: row;
  text-align: left;
  padding: 2px 5px;
}

.graphic_editor label a{
  flex: 1 0 0;
  margin: auto;
}
.graphic_editor label input{
  flex: 1 0 0;
}
.graphic_editor label .v-select{
  flex: 1 0 0;
}
.graphic_editor .schema_add{
  display: flex;
  flex-direction: column;
}
.graphic_editor .schema_add > p{
  text-align: left;
  margin: 5px 2px;
}
.graphic_editor .schema_add > div{
  margin: 3px;
  border: 1pt solid var(--color5);
  cursor: pointer;
}
.horizontal_wrapper{
  display: flex;
  flex-direction: row;
}
.graphic_editor .layout.horizontal_wrapper > div{
  width: calc(50% - 10px);
  margin: 5px;
}
.dialog-opener{
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.dialog-opener label{
  flex: 1 0 0;
  cursor: pointer;
}
.valuenames{
  max-height: 470px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.md-dialog{
  z-index: 51;
}
.md-overlay{
  z-index: 50;
}
.result-item{
  display: flex;
  flex-direction: row;
}
.result-item .images{
  flex: 0 0 auto;
  height: 20pt;
  width: 20pt;
  display: flex;
  border-radius: 50%;
  margin: auto 5pt;
}
.result-item .images .icon{
  flex: 1 0 0;
  margin: 2.5pt;
}
.result-item .images.sucess{
  background-color: rgb(6, 184, 0);
}
.result-item .images.sucess .icon{
  background-image: url('../images/status-icons/check-solid.svg');
}

.result-item .error.images{
  background-color: rgb(224, 17, 17);
}
.result-item .error.images .icon{
  background-image: url('../images/status-icons/times-solid.svg');
}
.p-in-middle{
  margin: auto 5pt;
}
.action_button{
  margin: 3px;
  border: 1pt solid var(--color5);
  cursor: pointer;
}
.action_button.delete{
  display: flex;
  flex-direction: row-reverse;
}
.action_button.delete .icon{
  background-image: url('../images/trash-alt-solid.svg');
  width: 30px;
  height: 22px;
  flex:  0 0 30px;
  cursor: pointer;

}
.action_button.delete label{
  flex: 1 0 0;
  text-align: left;
  cursor: pointer;
}
.kategorie-row{
  margin: 5px 20px;
  display: flex;
  flex-direction: row;
}
.kategorie-row.add{
  font-size: var(--font2);
  text-align: center;
  cursor: pointer;
}
.ew-frames-table.row{
  margin: 10pt 0 ;
}
.ew-frames-table.row > div{
  margin: 0 10pt;
}
.md-menu-content.md-select-menu{
  z-index: 112;
}
.md-fields > div{
  width: auto;
  flex: 1 0 0;
}
</style>