<template>
    <div>
        <md-dialog :md-active="open" md-dynamic-height>
            <md-dialog-title>Global Dashboards Charts</md-dialog-title>
            <div class="vertical-wrapper" style="margin: 5pt; min-height: 400pt; min-width: 600pt">
                <div class="horizontal-wrapper" style="flex: 0 0 auto">
                    <md-field>
                        <label for="">Suchen</label>
                        <md-input v-model="searchText" @blur="filterChartsList" @enter="filterChartsList"></md-input>
                    </md-field>
                </div>

                <div style="flex: 1 0 0; overflow-y: scroll;" class="vertical-wrapper">
                    <div v-for="c in filteredChartList"  :key="c.key" class="horizontal-wrapper" style="flex: 0 0 auto; margin: 2px;">
                        <input  type="checkbox" style="display: block; -webkit-appearance: auto;" v-model="c.active" :name="c.name" v-on:input="changeChartActive(c.id)">
                        <label v-on:click="changeChartActive(c.id)">{{c.name}}</label>
                    </div>
                   <!--
                       <md-switch class="md-primary" v-for="c in filteredChartList" :key="c.key" style="flex: 0 0 auto" :value="c.active" v-on:change="changeChartActive(c.id)">{{ c.name }}</md-switch> <div></div>

                   --> 
                </div>
            </div>
            <md-dialog-actions>
                <md-button class="md-primary" @click="$router.go(-1)">Close</md-button>
                <md-button class="md-primary" v-on:click="save" >Save</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>
<script>
export default {
    name: 'global-dashboard-settings-view',
    props: {id: [String, Number]},
    computed:{
        token() {
            return this.$store.getters.token
        },
        globalDashboards(){
            return this.$store.getters.getGlobalDashboards
        }
    },
    methods:{
        filterChartsList(){
            this.filteredChartList = JSON.parse(JSON.stringify(this.chartList.filter(e => e.name.toLowerCase().includes( this.searchText.toLowerCase() )) ))
        },
        changeChartActive(IDh_chart_id){
            const m = this.chartList.find(e => e.id == IDh_chart_id)
            if(m)
                m.active = !m.active
        
            const m1 = this.filteredChartList.find(e => e.id == IDh_chart_id)
            if (m1)
                m1.active = !m1.active
        },
        save(){

            this.$socket.emit('edit-global-dashboard-settings-charts', {token: this.token, IDh_global_db: this.IDh_global_db, charts: this.chartList})
        }
    },
    beforeRouteEnter(from, to, next){
        next(vm => {
            vm.open = true
            console.log();
            vm.IDh_global_db = parseFloat(to.params | vm.id);
            vm.$socket.emit('get-global-dashboard-settings-charts', { token: vm.token, IDh_global_db: vm.IDh_global_db })
        })
    },
    sockets:{
        'set-global-dashboard-settings-charts': function({ availableCharts }){
            this.chartList = availableCharts
            this.filterChartsList()
         
        },
        'edit-global-dashboard-settings-charts-success': function(){
            this.$router.go(-1)
        }
    },
    data(){
        return{
            IDh_global_db: -1,
            open: false,
            chartList: [],
            filteredChartList : [],
            searchText: ''
        }
    }
}
</script>