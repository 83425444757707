<template>
    <md-dialog :md-active="showDialog" class="" v-on:md-closed="showDialog = false" v-on:md-clicked-outside="showDialog = false">
        <md-dialog-title>Graph Optionen</md-dialog-title>

        <div class="vertical-wrapper" style="padding: 20pt; min-height: 50vh;">
            <div v-for="o in availableOptions" :key="o.key" style="flex: 0 0 auto">
                
                <md-switch class="md-primary" v-if="o.type == 'boolean'" v-model="o.value">{{ o.name }}</md-switch>
            </div>
        </div>
       
        <md-dialog-actions>
            <md-button class="md-primary" @click="showDialog = false">Close</md-button>
            <md-button class="md-primary" @click="saveGraphOptions()">Save</md-button>
        </md-dialog-actions>
    </md-dialog>
</template>
<script>
export default {
    name: 'graph-options-dialog',
    props: { maschine:Object, chart:Object},
  
    computed:{
        token(){
            return this.$store.getters.token
        }
    },
    methods:{
        show(){
            this.showDialog = true

            this.availableOptions = [
                { name: 'realtime', type: 'boolean', value: false, key: this.$uuid.v4() }
            ]

            fetch(`${this.$hostname}/graph/options/read`, {
                    method: 'POST', 
                    headers: { authorization: this.token, "Content-Type": "application/json", },
                    body: JSON.stringify({
                        maschine: this.maschine, graph:this.chart,
                    })
                }).then(async (e) => {
                if(e.status == 200){
                    const options = await e.json()

                    // loop through available options
                    this.availableOptions.forEach(o => {
                        // find option match
                        const m = options.find(e => e.name == o.name)
                        if(m)
                            o.value = m.value;

                        // convert boolean if nssasary
                        if(o.type == 'boolean')
                            o.value = o.value? true: false

                    })
                }
            })

        },
        saveGraphOptions(){
            const body = JSON.stringify({ maschine: this.maschine, graph:this.chart, options: this.availableOptions})

            fetch(`${this.$hostname}/graph/options/edit`, {
                    method: 'POST', 
                    headers: { authorization: this.token, "Content-Type": "application/json", },
                    body
                }).then(async (e) => {
                if(e.status == 200){
                   console.log('Success');
                   this.showDialog = false;
                }
            })
        }
    },
    
    data(){
        return {
            showDialog: false,
            availableOptions: []
        }
    }
}
</script>