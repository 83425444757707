<template>
    <!--Error Warning Frames-->
    <md-dialog :md-active="open" v-on:md-closed="$emit('close')" v-on:md-clicked-outside="$emit('close')"
        class="ew-frames-dialog md-dialog-grid">
        <md-dialog-title>Error Warning Frames</md-dialog-title>
        <!--

        <div v-for="item in ew_frames" :key="item.key" class="ew-frames-table row horizontal-wrapper md-fields">
            <md-field style="width: auto; flex: 1 0 0;">
                <label>name</label>
                <md-input v-model="item.name"></md-input>
            </md-field>
            <md-field style="width: auto; flex: 1 0 0; ">
                <label>header</label>
                <md-input v-model="item.header"></md-input>
            </md-field>
            <md-field>
                <label>type</label>
                <md-select v-model="item.type">
                    <md-option v-for="(t, i) in ew_types" :key="i + '_ew_frame_types'" :value="t">{{t}}</md-option>
                </md-select>
            </md-field>
            <md-field style="width: auto; flex: 1 0 0; ">
                <label>max</label>
                <md-input v-model="item.max"></md-input>
            </md-field>
            <md-field style="width: auto; flex: 1 0 0; ">
                <label>min</label>
                <md-input v-model="item.min"></md-input>
            </md-field>

            <md-field style="width: auto; flex: 1 0 0; ">
                <label>icon</label>
                <md-select v-model="item.icon">
                    <md-option v-for="(icon, i) in ew_icons" :key="i + $uuid.v4()" :value="icon">{{icon}}
                    </md-option>
                </md-select>
            </md-field>
            <md-switch class="md-primary" style="flex: 0 0 auto; margin: auto 10pt;" v-model="item.active">Aktiv
            </md-switch>
            <md-switch class="md-primary" style="flex: 0 0 auto; margin: auto 10pt;" v-model="item.give_message">
                Benachrichtigung</md-switch>
            <md-field style="width: auto; flex: 1 0 0;">
                <label>user</label>
                <md-select v-model="item.IDh_user">
                    <md-option v-for="user in avaliable_users" :key="user.key" :value="user.id">{{user.text}}
                    </md-option>
                </md-select>
            </md-field>
        </div>
        -->
        <div class="md-dialog-body vertical-wrappper"
            style="min-height: 200pt; max-width: 100vw; max-height: 100vh; overflow: scroll;">

            <div ref="ew-frames-spreatsheet">

            </div>
            <div class="horizontal_wrapper">
                <div class="placeholder"></div>
                <md-button v-on:click="add_ew_frame" class="md-primary save-button">add</md-button>
                <div class="placeholder"></div>
            </div>
        </div>

        <md-dialog-actions>
            <md-button class="md-primary" @click="$emit('close')">Close</md-button>
            <md-button class="md-primary" @click="save_ewframes()">Save</md-button>
        </md-dialog-actions>
    </md-dialog>
</template> 
<script>
import jexcelStyle from "jexcel/dist/jexcel.css"; // eslint-disable-line no-unused-vars
import jexcel from "jexcel"; // eslint-disable-line no-unused-vars


export default {
    name: 'error-warning-frame-dialog',
    props: { 'open': Boolean, 'maschine': Object, 'edit-object': Object},
    watch:{
        open(){
            if(this.open){
                console.log(this.token, this.maschine, this.editObject);
                this.$socket.emit('load-ew-frames-settings', { token: this.token, maschine: this.maschine, chart: this.editObject })
            }

        }
    },
    methods: {
        add_ew_frame() {
            if (!this.ewframeTable.getData().length)
                this.ewframeTable.setData([[-1, this.$user.id, '', '', 'basic', 'info', '0', '0', false, false, false]])
            else
                this.ewframeTable.insertRow([-1, this.$user.id, '', '', 'basic', 'info', '0', '0', false, false, false])
        },
        save_ewframes() {
            const ewFrames = this.ewframeTable.getData().map(e => {return {id: e[0], IDh_user: e[1], name: e[2], header: e[3], type: e[4], icon: e[5], max: e[6], min: e[7], active: e[8], give_message:e[9]}})
            this.$socket.emit('save-ew-frames-settings', { token: this.token, maschine: this.maschine, chart: this.editObject, ew_frames: ewFrames })
            this.$emit('close')
        },
    },
    sockets: {
        'show-ew-frames-settings': function ({ ew_frames, users }) {
            users.forEach(e => e.key = this.$uuid.v4());
            ew_frames.forEach(e => e.key = this.$uuid.v4());

            this.avaliable_users = users;
            this.ew_frames = ew_frames;
            this.$nextTick(() => {
                const ewFramesSpreatsheet = this.$refs['ew-frames-spreatsheet']
                if (!ewFramesSpreatsheet)
                    return

                ewFramesSpreatsheet.innerHTML = ''
                this.ewframeTable = jexcel(ewFramesSpreatsheet, {
                    data: [['', '', '', '',]],
                    columns: [
                        { title: 'id', type: 'hidden' },
                        { title: 'user', width: 200, type: 'dropdown', autocomplete: true, source: users.map(e => { return {id: e.id, name : e.text }}) },

                        { title: 'name', width: 200 },
                        { title: 'header', width: 200 },
                        { title: 'type', width: 100, type: 'dropdown', source: this.ew_types },

                        { title: 'icon', width: 100, type: 'dropdown', source: this.ew_icons },
                        { title: 'max', width: 120 },
                        { title: 'min', width: 120 },

                        { title: 'active', width: 80, type: 'checkbox' },
                        { title: 'message', width: 80, type: 'checkbox' },
                        { title: 'email', width: 80, type: 'checkbox' },


                    ],
                });
                this.$nextTick(() => {
                    this.ewframeTable.setData(this.ew_frames.map(e => [e.id, e.IDh_user, e.name, e.header, e.type, e.icon, e.max, e.min, e.active, e.give_message, e.email,]))
                });
            })


        },
    },
    data(){
        return {
            ewframeTable: 0,
            avaliable_users: [],
            ew_frames: [],

            ew_types: ['basic', 'outside', 'inside'],
            ew_icons: ['warn', 'error', 'info'],
        }
    },
    mounted(){
        
    },
    computed: {
        token() {
            return this.$store.getters.token
        }
    },
}
</script>
<style>
.jexcel>tbody>tr>td>input[type=checkbox]{
    -webkit-appearance: auto;
}
</style>
