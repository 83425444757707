<template>
  <div class="main">
   
    <div class="my_maschine_wrapper">
      <!--

        <maschines :class="{'not_show':!showMaschines}" v-on:maschine-selected="burger_menu_clicked(false)" />
      -->

      <div class="navigator kategories vertical-wrapper" :class="{'not_show': $isMobile() && !display.showKategories}">
        <maschine-opener :id="id" :name="name" ></maschine-opener>
    
        <div v-if="id > 0" style="flex: 0 0 auto">

          <router-link  v-for="k in kategories" :key="k.key" class="kategorie-item" 
                      :to="{ name: 'kategorie-view', params: { id: id, name: name, kid: k.id, kategorie: k.text}}">
            <div v-if="k.icon" class="icon" 
              :style="`background-color: white; 
              mask-position: center;
              mask-repeat: no-repeat; 
              -webkit-mask-image:  url(${k.icon}); mask-image: url(${k.icon})`"> 
            </div>
            <p>
              {{k.text}}
            </p>
          </router-link>
        </div>


        <p v-for="item in ew_kategories" :key="item.id" class="kategorie-item"
          :class="{'active':item.active, 'hide':id != 0}" v-on:click="change_ew_kategorie(item)">{{item.text}}</p>

      </div>


      <div class="panel" style="overflow: scroll; max-height: 93vh;">
        <all-maschines :open="id == 0" v-on:clear-charts="clear_maschine"
          v-on:refresh-kategories="(e) => ew_kategories = e" v-on:open-maschines="open_maschines"
          :filter-name="filter_name" />
       
        <div class="overview-opener" :class="{'hide':(!$isMobile()) || id == 0 || maschine_overview}">
          <md-button v-on:click="back_to_overview" class="md-primary">{{$translate('overview')}} </md-button>
        </div>
        <router-view :id="1337"> </router-view>
        
      </div>
    </div>
    

    <vpn-status-bar />
  </div>
</template>
<script>
import { mapState } from "vuex";


//import maschines from '@/views/maschines.vue'
import vpnStatusBar from '@/components/vpn-status-bar.vue'
import allMaschines from '@/components/all-maschines.vue'
import MaschineOpener from '../components/maschine-opener.vue';

export default {
  name: 'my_maschine',
  title: 'my maschine',
  props: ['id', 'name'],
  components:{
   // 'maschines':maschines, 


    'vpn-status-bar':vpnStatusBar,
    'all-maschines':allMaschines,
    MaschineOpener,
  },
  methods:{
    burger_menu_clicked(open){
      if(this.$isMobile()){
        //console.log('burger menu clicked', open, this.burger_menu_clicked.caller);
        this.$store.commit('display/set-show-kategories', open)

        if(!open){
          this.maschine_overview = false
          this.$store.commit('display/set-show-maschines', false)

        }

        return;
      }

      this.$store.commit('display/set-show-maschines', open)

      if(!open){
        this.charts = []
        this.maschine_overview = false
      }
    },
    open_maschines(){
      this.$socket.emit('load_maschines', {token: this.token})
      this.burger_menu_clicked(true)
    },
    change_kategorie(item){
      if(this.$isMobile()){
        this.$store.commit('display/set-show-kategories', false)
      }

      this.kategories.forEach(item => item.active = false);
      this.name_active = false
      item.active = true;
      if(item.id != -2){
        this.$socket.emit('load_one_kategorie', {token: this.token, maschine:{id: parseInt(this.id)}, mobil:this.$isMobile(), kategorie:item})
      }else if(item.id != -1){
        this.export_templates = this.export_templates.filter(e => e.id != -1)
      }else//show_dashbord
      {
        console.log('show dashboard');
      }
    },
    change_ew_kategorie(item){
      this.ew_kategories.forEach(e => e.active = false)
      this.name_active = false
      this.filter_name = item.text
      item.active = true
    },
    click_on_name(){
      if(this.id == 0){
        this.ew_kategories.forEach(e => e.active = false)
        this.name_active = true
        this.filter_name = '';
        if(!this.$isMobile())
          return
      }

      this.name_active = true;
      this.kategories.forEach(item => item.active = false);
  
      this.charts = []
      this.schemas = []
      this.export_templates = []
      this.message_frames = []
      this.controls = []
      this.$socket.emit('load-ew-frames', {token: this.token, maschine:{id: parseInt(this.id)}})

      this.maschine_overview = true
      if(this.$isMobile())
        this.$store.commit('display/set-show-maschines', !this.display.getShowMaschines)


    },
    open_user_settings(){
      this.hide_user_settings = false
    },
    open_editor(){
      this.$store.commit('display/set-editor-mode', !this.display.editorMode)


      this.charts.forEach(e => e.edit = e.edit && this.edit_mode);

    },
  
    
   
    edit_export_template(obj){
      this.multiple_objects_selected = false

      this.charts.forEach(e => e.edit = false);
      this.schemas.forEach(e => e.edit = false)
      this.export_templates.forEach(e => e.edit = false)
      this.controls.forEach(e => e.edit = false)

      this.edit_type = 'export-template'
      obj.edit = true
      this.selected_object = obj;
    },
    clear_maschine(){
      this.charts = []
      this.schemas = []
      this.export_templates = []
      this.controls = []
      this.message_frames = []
    },
    back_to_overview(){
      this.$socket.emit('load_kategories',{token: this.token, maschine:{id:this.id, name:this.name}, mobil: this.$isMobile()});
    },
   
  },
  sockets:{

    show_kategories({kategories}){
      this.kategories = []
      //first load all text based icons
      kategories.filter(e => e.icon).forEach(e => {
        let blob = new Blob([e.icon], { type: 'image/svg+xml' });
        e.icon = URL.createObjectURL(blob)
      });


      if(this.id && this.id > 0){//check if maschine is selected
        kategories.push({
          id: -1, text: 'Dashboard', reihenfolge: -3,
          icon: require('../images/kategorie-icons/chart-line-solid.svg')
        });
        if(this.getAdmin){

          /*kategories.push({
            id: -2, text: 'Daten', reihenfolge: -2,
            icon: require('../images/kategorie-icons/circle-nodes-solid.svg')
          });*/
        }

      }
    
    

      //kategories.push({ id: -3, text: 'Schemas', reihenfolge: -1 });


      kategories.forEach(e => e.key = this.$uuid.v4())
 
      kategories = kategories.sort((a,b) => a.reihenfolge - b.reihenfolge)
      this.kategories = kategories
      this.$store.commit('set-kategories', this.kategories)

      if (this.id_of_last_kategorie != null){
        this.kategories.forEach(item => item.active = false);
        this.change_kategorie(this.kategories.find(e => e.id==this.id_of_last_kategorie))
        this.id_of_last_kategorie = null;
        this.maschine_overview = false
      }else{
        this.maschine_overview = true
        this.kategories.forEach(item => item.active = false);
      }
      this.$socket.emit('load-ew-frames', {token: this.token, maschine:{id: parseInt(this.id)}})

    },
    
   
   
    'kategories-edited-sucessfull':function(){
      const active_kategorie = this.kategories.find(e => e.active)
      if(active_kategorie){
        this.id_of_last_kategorie = active_kategorie.id
      }

      this.$socket.emit('load_kategories', {token: this.token, maschine:{id:this.$props.id}})

    },
    'show-ew-frames':function(message_frames){
      this.message_frames = message_frames
      this.$store.commit('set-message-frames', message_frames)
    }, 
    'remove-chart-from-dashboard':function({chart}){
      this.charts = this.charts.filter(e => e.id!=chart.id)
    },
    'remove-schema-from-dashboard': function({schema}){
      this.schemas = this.schemas.filter(e => e.id!=schema.id)
    }
  },
  beforeRouteEnter (to, from, next) { 
    next(vm => { 

      if(parseInt(vm.$props.id) != 0){
        vm.$socket.emit('load_kategories', {token: vm.token, maschine:{id:vm.$props.id}})
        //vm.$socket.emit('set-maschine-on', { token: vm.token, maschine: { id: vm.$props.id } })

        vm.maschine_overview = true
      }else if (parseInt(vm.$props.id)== 0){
        //vm.$socket.emit('load-maschine-overview', {token:this.token})
        vm.$socket.emit('overview-ew-frames', {token:vm.token})
      }

      //close burger menu while kategorie selection

      next();
    }) 
  },
  beforeRouteUpdate(to , from, next){
    this.$nextTick(() => {

    })
    next()
    
 },
  
  watch:{
    edit_mode(){
      if(!this.edit_mode){
        this.multiple_objects_selected = false;
      }
    }
  },
  computed:{
    token(){
      return this.$store.getters.token
    },
    editorMode(){
      return this.$store.getters.getEditorMode
    },
    
    ...mapState(["display"]),

    getAdmin(){
      return this.$store.getters.getAdmin
    }
  },
  data(){

    return{
      kategories:[],
      ew_kategories : [],
      filter_name: '',
      id_of_last_kategorie:null,
      name_active: true,
     
      controls: [],
      tables: [],
      charts_line: [],

      maschine_overview: false,
      hide_user_settings: true,
      

      /*Maschine Items*/
      charts: [],
      schemas:[],
      export_templates: [
        /*{id: -1, name: 'Full Export', export_type:'.csv', edit:false}*/
      ],
      message_frames:[],
      
    }
  },
  mounted(){

  },
  created(){
    this.$dictonary.add([
      ['all', 'Alle'],
      ['maschines', 'Maschinen'],
      ['my maschines', 'meine Maschinen'],

    ]);

    window.addEventListener('keydown', (e) => {
      if (e.key == 'Shift') {
        this.shiftPressed = true
      }
    });
    window.addEventListener('keyup', (e) => {
      if (e.key == 'Shift') {
        this.shiftPressed = false
      }
    });
  }
}
</script>
<style>
.maschines_router{
  top: 0;
  left: 0;
  width: auto;
  min-height: 100%;
  z-index: 15;
  overflow: auto;
  flex: 0 0 auto;
}
.maschines.not_show{
  width: 0;
  animation: width 0.4s;
}
.user-header .burger {
  height: 42pt;
  width: 42pt;
  background-color: var(--color2);
}
.user-header .burger > div{
  background-color: var(--color4);
}
.burger .bar1{
  width: 30pt;
  height: 6pt;
  margin-top: 6pt;
}
.main{
  display: flex;
  flex-direction: column;
  min-height: 100vh ;
}
.my_maschine_wrapper{
  flex: 1 0 0;
  display: flex;
  flex-direction: row;
}
.maschine_menu{
  flex: 0 0 42pt;
  background-color: var(--color4);
  border-bottom: 4pt solid var(--color3);

}
.kategories{
  flex: 0.3 0 12%;
  margin-top: 0;
  padding: 0;
  background-color: var(--color3);

}
.kategories .kategorie-item{
  margin: 0;
  padding: 5pt;
  display: flex;
  font-size: var(--font1);
  background-color: var(--color3);
  color: var(--color1) !important;
  padding-left: 30pt;
  cursor: pointer;
}
.kategories .kategorie-item:hover{
  text-decoration: none !important;
}
.kategories .kategorie-item.router-link-active{
  background-color: var(--color2)
}
.kategories .maschine_name{
  padding: 5pt;
  display: flex;

  cursor: pointer;
  padding-left: 20pt;
  font-size: 20pt;
  line-height: 21pt;
  background-color: var(--color3);
  color: var(--color1);

  border-left: solid 2pt var(--color3)   ; 

  flex-direction: row;
}
.kategories .maschine_name a{
  color: var(--color1) !important;
}
.kategories .maschine_name a:hover {
  text-decoration: none;
}
.kategories .maschine_name .maschine_opener{
  flex: 0 0 0;
}
.kategories .maschine_name:has( a.router-link-active ){
  border-left-color: var(--color2);
  background-color: var(--color3)

}
.kategories p.active{
  background-color: var(--color2)
}
.panel{
  flex: 9 0 0;
  background-color: var(--color4);
}

.kategorie-item .icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  width: 20px;
  margin-right: 10px;
  
  
}

.kategorie-item .icon svg {
  fill: white;
}
@media screen and (max-device-width: 800px) {/*für mobil*/
  .my_maschine_wrapper{
    margin-top: 0;
  }

  .navigator.kategories{
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 42pt);
    transition: width 0.4s; 

    z-index: 11;
  }
  .navigator.kategories p{
    padding: 5pt;
  }
  .navigator.kategories p.kategorie-item{
    padding-left: 50pt;
  }

  .navigator.kategories.not_show{
    width: 0;
  }
  .kategories p.maschine_name::after{
    display: block;
    width: var(--font1);
    height: var(--font1);
    content: '';
  }
  .kategories .maschine_name {
    height: auto;
  }
  .kategories .maschine_name p{
    flex: 1 0 0;
    text-align: left;
  }

  .kategories .maschine_name .maschine_opener{
    flex: 0 0 25pt;

    transition: transform 0.4s;
  }
  .kategories .maschine_name .maschine_opener.open{
    transform: rotate(-180deg);
  }
  .kategories .maschine_name.active{
    border-left-color: var(--color3);
  }
}
</style>