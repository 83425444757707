<template>
  <div>
    <div class="control" :class="{'edit': control.edit && editMode}">
      <div class="name" v-on:click="itemClicked">
        <h2>
          {{control.name}}
        </h2>
      </div>
      <div class="placeholder" v-on:click="itemClicked"></div>

      <div class="control-element hide-mobil" :class="{ 'hide': editMode }">
        <div :class="{'inactive':editMode}" v-if="control.type == 'control_button'" style="margin: auto">
          <md-button class="md-raised md-primary" :disabled="value ? true : false" v-on:click="value = 1">{{control.name}}
          </md-button>
        </div>
        <div class="switch" :class="{'inactive':editMode}" v-if="control.type == 'control_switch'" style="margin: auto">
          <md-switch class="md-primary" v-model="value">{{control.name}}</md-switch>
        </div>
        <div class="number" :class="{'inactive':editMode}" v-if="control.type == 'control_number'" style="margin: auto">

        </div>
      </div>


    </div>
    <md-dialog :md-active.sync="openDetails" class="vertical-wrapper">
      <div class="horizontal-wrapper" style="flex: 0 0 auto">

        <md-dialog-title> {{control.name}}</md-dialog-title>
        <div class="placeholder"></div>
        <md-dialog-actions>
          <md-button class="md-primary" @click="openDetails = false">Close</md-button>
        </md-dialog-actions>
      </div>
      <div style="flex: 1 0 0">
        <div class="control-element-details horizontal-wrapper">
          <div></div>
          <div :class="{'inactive':editMode}" v-if="control.type == 'control_button'"
            style="margin: auto; flex: 0 0 auto">
            <md-button class="md-raised md-primary" :disabled="value? true: false" v-on:click="value = 1">
              {{control.name}}
            </md-button>
          </div>
          <div class="switch" :class="{'inactive':editMode}" v-if="control.type == 'control_switch'"
            style="margin: auto">
            <md-switch class="md-primary x2" v-model="value"></md-switch>
          </div>
          <div class="number" :class="{'inactive':editMode}" v-if="control.type == 'control_number'"
            style="margin: auto">

          </div>
          <div></div>

        </div>
      </div>
    </md-dialog>
  </div>
</template>
<script>


export default {
  name:'controls',
  components:{
  },
  props:
    {'control':Object, 'maschine':Object, 'edit-mode':Boolean, 'edit-object':Object},

  watch: {
    value(){
      if(this.loadValue)
        return
      this.$swal({title: 'set value?', icon: 'question', showConfirmButton:true, confirmButtonText: 'Confirm', showDenyButton: true}).then(e => {
        if(e.isConfirmed){
          this.valueAfterConfirm = this.value
          this.$socket.emit('evoke-control', {token: this.token, maschine: this.maschine, control: {id: this.control.ID, type: this.control.type, value: this.value}})
          if(this.control.type == 'control_button'){
            const vm = this
            setTimeout(() => {
              vm.loadValue = true
              vm.value = false
              vm.$nextTick(() => {
                vm.loadValue = false
              })
              vm.$socket.emit('evoke-control', { token: vm.token, maschine: vm.maschine, control: { id: vm.control.ID, type: vm.control.type, value: 0}})

            }, 2000)
          }
        }else{
          this.loadValue = true
          this.value = this.valueAfterConfirm
          this.$nextTick(() => {
            this.loadValue = false
          })
        }

      })
    }
  },
  methods:{
    itemClicked(){
      if(this.editMode){
        this.$emit('item-clicked', this.control)
      }else{
        this.openDetails = true
      }

    },
    setInternalValue(value){
      // prevent value changed trigger
      this.loadValue = true;
      // set global value to parameter value
      this.value = value;
      this.$nextTick(() => {
        // reactiveated trigger after value is setted
        this.loadValue = false
      })
    }
  },
  computed: {
    token(){
      return this.$store.getters.token;
    }
  },
  sockets: {
    'show-control-details':function({html_id, type, value}){
      if(this.html_id != html_id) 
        return
      if(type == 'control_switch')
        this.setInternalValue(value ? true : false)
    } 
  },
  mounted(){
    this.html_id = this.$uuid.v4()
    
    this.setInternalValue(this.control.value)
    this.$socket.emit('load-control-details', { token: this.token, maschine: this.maschine, control: { id: this.control.ID, html_id:this.html_id}})
  },
  data(){
    return{
      html_id: 0,
      value: false,
      valueAfterConfirm: false,
      loadValue: false,
      openDetails: false
    }
  }
}
</script> 
<style>











.control{
  width: calc(50% - 2pt - 10px);
  display: inline-flex;
  flex-direction: row;
  
  margin: 5px;
  border: 1pt solid var(--color5);
  background-color: var(--color1);
}
.control.edit{
  border-style: dashed;
  border-color: var(--color2);
}
.control .name{
  margin: 5pt ;
}
.control .control-element{
  margin: 0 5pt ;
  display: flex;
}
.control .control-element .button{
  margin: auto;
  padding: 5pt;
}

/**enlarge md-switch */
.md-switch.md-primary.md-theme-default.x2 .md-switch-container{
  width: 68px;
  height: 28px;
}
.md-switch.md-primary.md-theme-default.x2 .md-switch-thumb{
  height: 40px;
  width: 40px;
}
.md-switch.md-primary.md-theme-default.x2.md-checked .md-switch-thumb {
  transform: translate3d(30px, 0, 0);
}

@media screen and (max-device-width: 800px) {
  .control-element{
    display: none;

  }
  
}
</style>