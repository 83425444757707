<template>
  <div class="user-settings-user horizontal-wrapper"  ref="user_settings_user">
    <div class="user_list vertical-wrapper" style="flex: 0.25 0 0">
      <md-field md-clearable style="margin: 0 2.5pt; flex: 0 0 auto;" >
        <label for="">Suche</label>
        <md-input v-model="searchText"></md-input>
      </md-field>
      <div class="vertical-wrapper">

        <list 
          :items="user_list.filter(e => e.text.toLowerCase().includes(searchText.toLowerCase()))" 
          :overflow="true" class="sidebar" 
          v-on:item-clicked="user_selected" 
          style="overflow-y: scroll;flex: 1 0 0px;"
          />  
      </div>
      <div style="flex: 0 0 auto">
          <md-button class="md-raised md-primary" v-on:click="addUser">Add</md-button>
      </div>
    </div>
    <div class="settings_wrapper">
      <div class="horizontal-wrapper" style="flex: 0 0 auto">
        <div class="vertical-wrapper" style="margin: 0 5pt">
          <md-field>
            <label for="">Username/E-mail</label>
            <md-input v-model="user.name" v-on:change="data_change()"></md-input>
          </md-field>
          
          <md-field>
            <label for=""> E-mail</label>
            <md-input v-model="user.email" v-on:change="data_change()"></md-input>
          </md-field>
         
          <md-button class="md-primary" v-on:click="sendInvitation">
              Einladungs E-Mail
          </md-button>
        </div>
        <div class="vertical-wrapper" style="margin: 0 5pt">
          <md-field>
            <label for="">Passwort</label>
            <md-input type="password" v-model="user.pw0" ></md-input>
          </md-field>
           <md-field>
            <label for="">Passwort wiederholen</label>
            <md-input type="password" v-model="user.pw1" ></md-input>
          </md-field>
        
          <div class="horizontal-wrapper" style="gap: 5pt;">
            <md-button class="md-primary" style="flex: 1 0 0" v-on:click="set_pw()">Passwort überschreiben</md-button>

            <md-button class="md-accent" style="flex: 1 0 0" v-on:click="deleteUser()">Benutzer entfernen</md-button>

          </div>
      </div>
    </div>
    
    <div class="horizontal-wrapper">
      <div class="vertical-wrapper" style="margin: 0 5pt">
          <div class="horizontal-wrapper" style="flex: 0 0 auto">
              <div class="vertical-wrapper" style="flex: 1 0 0">
                <p style="margin: auto">Groups:</p>
              </div>
            
              <md-field md-clearable style="margin: 0 2.5pt; flex: 1 0 0;" >
                <label for="">Suche</label>
                <md-input v-model="groupSearchText"></md-input>
              </md-field>
          </div>
          
          <div class="" style="overflow-y: scroll; margin-bottom: 20pt;">
            <md-switch class="md-primary" style="display: block;" v-for="item in groups.filter(e => e.text.toLowerCase().includes(groupSearchText.toLowerCase()))" :key="item.id" :text="item.text" v-model="item.group_active"  v-on:change="(e) => {item.group_active = e; data_change()} ">{{item.text}}</md-switch>
          </div>
      </div>

      <div class="vertical-wrapper" style="margin: 0 5pt">
        <div class="horizontal-wrapper" style="flex: 0 0 auto">
            <div class="vertical-wrapper" style="flex: 1 0 0">
              <p style="margin: auto">Maschines:</p>
            </div>
              
            <md-field md-clearable style="margin: 0 2.5pt; flex: 1 0 0;" >
              <label for="">Suche</label>
              <md-input v-model="maschineSearchText"></md-input>
            </md-field>
        </div>

            <div class="vertical-wrapper overflow-wrapper">
              <md-switch style="flex: 0 0 auto" class="md-primary" v-for="item in maschine_list.filter(e => e.text.toLowerCase().includes(maschineSearchText.toLowerCase()))" :key="item.id " v-model="item.maschine_active" v-on:change="(e) => {item.maschine_active = e; data_change()}">{{item.text}}</md-switch>
            </div>
        </div>
    </div>
  </div>

  </div>
</template>
<script>

import list from '@/components/VueComponents/list.vue'
import { mapState } from 'vuex'


export default {
  name: 'user-settings-user',
  components:{
    list
  },
  props:{
    
  },
  methods:{
    user_selected(user){
      this.$socket.emit('load_settings_user_details', {token: this.token, user:user})
    },
    set_pw(){
      if(this.user.pw0 === this.user.pw1)
        this.$socket.emit('save_user_settings_password', {token: this.token, user_id:this.user.id, pw:this.user.pw0})
    },
    data_change(){
      this.$socket.emit('save_settings_user_details', {
        token: this.token,
        user: this.user,
        maschines: this.maschine_list.map(e => {e.active = e.maschine_active; return e}),
        groups: this.groups.map(e => {e.active = e.group_active; return e})
      })
    },
    addUser(){
      this.$swal({
        title: 'Neuer Benutzer?', 
        icon: 'question', 
       
        showConfirmButton: true,
        confirmButtonText: 'Erstellen',
        showDenyButton: true,
        denyButtonText: 'Abbrechen',

        html:`
          <p></p>
          <text>Möchten Sie einen neuen Benutzer erstellen?</text>
            <br><label>
              Mandat:
            </label>
            <select id="swal-input-mandate" class="swal2-input">
              ${this.mandates.length? this.mandates.map((e, i) => `<option ${!i ? 'selected' : ''} value="${e.id}">${e.name}</option>`).join('\n'): ''}
            </select>
          <input id="swal-input-name" class="swal2-input" placeholder="E-Mail">

          <div style="margin-bottom: 5pt;"></div>
        ` , 
        focusConfirm: false,
        preConfirm: () => {
          const swalInputMandate = document.getElementById('swal-input-mandate')
          return {
            name: document.getElementById('swal-input-name').value,
            IDh_mandate: swalInputMandate ? swalInputMandate.value: 0
          }
        }

      }).then((value) => {
        if (value.isConfirmed)
            this.$socket.emit('add-settings-user', {token: this.token, user: { IDh_mandate: value.value.IDh_mandate, name: value.value.name}})


      })
    },
    sendInvitation(){
      this.$swal({
        icon:'question',
        title: 'Einladung zusenden?', 
        text: 'Dieser Email eine einladung senden?',
        
        input: 'email',
        inputValue: this.user.name,
        inputAttributes:{
          placeholder: 'E Mail',

        },

        showConfirmButton: true,
        confirmButtonText: 'Zusenden',

        showDenyButton: true,
        denyButtonText: 'Abbrechen',

      }).then(e => {
        if(e.isConfirmed){
          this.$socket.emit('settings-user-send-invitation', {token: this.token, user: {id: this.user.id}, email: e.value })
        }
      })
    },
    deleteUser(){
      this.$swal({
        icon:'question',
        title: 'Benutzer löschen?', 
        text: `Benutzer ${this.user.name} und alle Zugänge werden gelöscht?`,
        
        showConfirmButton: true,
        confirmButtonText: 'Löschen',

        showDenyButton: true,
        denyButtonText: 'Abbrechen',

      }).then(e => {
        if(e.isConfirmed){
          this.$socket.emit('delete-user', {token: this.token, user: this.user})
        }
      })
    }
  
  },
  beforeRouteEnter (to, from, next) { 
    next(vm => { 
        vm.$socket.emit('load-settings-users', {token: vm.token})
        vm.$socket.emit('get-mandates', {token: vm.token})

        next();
    }) 
  },
  computed:{
    ...mapState(['token', 'mandates'])
  },
  sockets:{
    'show-settings-user':function({users}){
      users.forEach((item, i) => item.active = !i);
      this.user_list = users
      this.user_selected(users.find(e => e.active))
    },
    show_settings_user_details({user, maschines, groups }){
      // check if user exists or if it is created
      const userExists = this.user_list.some(e => e.id == user.id)
      if(!userExists){
        // set list completly inactive and push new element
        this.user_list.forEach(e => e.active = false);
        this.user_list.push({id: user.id, text: user.name, active:true})

        // sort array after insert to keep list sorted
        this.user_list.sort((a, b) => a.text.localeCompare(b.text))
      }

      groups.forEach(e => e.group_active = e.group_active? true: false)
      this.user = user
      this.maschine_list = maschines
      this.groups = groups
    },
    'set-password-successful':function(){
      this.$swal.fire({
        tilte: 'Success',
        text: 'set new Password',
        icon: 'success'
      })
    },


    'settings-user-message': function (options) {
      this.$swal(options)
    }
  },
  data(){
    return{
      searchText: '',
      maschineSearchText: '',
      groupSearchText: '',


      user_list: [],
      user: {
        id: 0, name : '', email: '', pw0: '', pw1: ''
      },
      maschine_list: [],
      groups: [],

    }
  }
}
</script>
<style>
.user-settings-user{

  font-size: var(--font1);
}
.user-settings-user .user-list{
  flex: 1 0 auto;
}
.user-settings-user .user-list ul.sidebar{
  display: flex;
  overflow-y: scroll;
}
.user-settings-user .settings_wrapper{
  flex:  1 0 0;
  display: flex;
  flex-direction: column;
}
.user-settings-user .list_wrapper{
  flex:  1 0 0;
  display: flex;
  flex-direction: row;
}
.user-settings-user .list_wrapper > div{
  flex: 1 0 0;

  border: solid 2pt var(--color5);
  margin: 5pt;
  overflow-y: scroll;
  max-height: 51.4vh;
  min-height: 43%;

}


.user-settings-user label input{
  font-size: var(--font1);
}
.user-settings-user .pw_wrapper{
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
}
.user-settings-user .pw_wrapper label{
  flex: 0 0 520pt;
}
.user-settings-user .user_wrapper{
  display: flex;
  flex-direction: row;
}
.user-settings-user .user_wrapper .wrapper{
  border: 2pt solid var(--color5);
  flex: 1 0 0;
  margin: 5pt
}
.user-settings-user .user_wrapper .wrapper p{
  text-align: left;
  margin: 2pt;
}
.user-settings-user .user_wrapper .wrapper label{
  text-align: left;
  
}
.user-settings-user .group-maschine-wrapper{
  flex: 1 0 0;
}
.overflow-wrapper{
  flex: 1 0 0;
  overflow-y: scroll;
}
</style>