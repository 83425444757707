<template>
    <div style="flex: 0 0 auto; padding: 0 2px; margin-right: 5pt;" class="horizontal-wrapper">
        <md-button class="md-primary md-raised" :disabled="realtime.realtime" v-on:click="enterRealtimeMode">Live</md-button>

        <div class="horizontal-wrapper" style="">
            <h4 style="margin: auto;" v-if="realtime.realtime && realtime.realtimeMaschine != maschine.name">{{ realtime.realtimeMaschine  }}</h4>
            <div></div>
            <h4 style="margin: auto;" v-if="realtime.realtime">bis {{ realtime.realtimeEndPoint }}</h4>
        </div>
    </div>
</template>
<script>

import { mapState } from 'vuex'

export default{
    name: 'realtime-banner',
    props: { maschine: Object },
    computed:{
        ...mapState(['realtime']),

    },
    methods:{
        async enterRealtimeMode(){
            const { isConfirmed } = await this.$swal.fire({
                icon: 'question',
                title: 'Echtzeit Modus?', 
                text:'Möchtest du den Echtzeitmodus aktivieren?',
                showConfirmButton: true,
                confirmButtonText: 'Ja',
                showDenyButton: true, 
                denyButtonText: 'Nein'
            

            })
            if(isConfirmed){
                console.log('Realtime Mode');
                this.$store.dispatch('realtime/enter-realtime-mode', this.maschine)
            }
        }
    }

}
</script>