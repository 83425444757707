<template>
    <div class="model-selector">
           <div class="horizontal_wrapper">
            <md-field style="flex: 3 0 0; margin:2.5pt;">
              <label>Name</label>
              <md-input v-model="vn_name"></md-input>
            </md-field>
            <md-field style="flex: 1 0 0; margin:2.5pt;">
              <label>Unit</label>
              <md-input v-model="vn_unit"></md-input>
            </md-field>
          </div>

        <div v-for="model in models" :key="model.id" class="model">
            <div class="header">
                <md-switch class="md-primary"  :value="!model.selected" v-on:change="(e) => select_model(e, model)">{{model.name}}</md-switch>
            </div>
            <div class="items" :class="{'hide':!model.selected}">
                <div v-for="item in model.items" :key="item.id">
                    <div v-if="item.type == 'valuename'" class="valuename-selector">
                        <h3>{{item.text}}</h3>
                        <div class="vn-wrapper">
                            <md-switch v-for="(vn, index) in valuenames" :key="'valuenames' + index" :value="selected_valuename(vn.id, item.IDu_valuename)" v-on:change="(e) => change_valuename(e, item, vn)" class="md-primary" >{{`${vn.name} [${vn.unit}]: ${vn.value}`}}</md-switch>

                        </div>
                    </div>
 
                    <div v-if="item.type == 'const'" class="horizontal_wrapper">
                        <h3>{{item.text}}</h3>
                        <div class="placeholder"></div>
                        <md-field style="margin:2.5pt; width: auto; flex: 0 0 auto;">
                            <label>value</label>
                            <md-input v-model="item.value"></md-input>
                        </md-field>
                    </div>
                    <br>
                    <div class="selector"></div>


                </div>
            </div>

        </div>
    </div>
</template>
<script>
import {v4 as uuid} from 'uuid'
import vue from 'vue'

export default {
    name: 'model-selector',
    props: {
        'open':Boolean,
        'edit-object':Object,
        'maschine':Object
    },
    methods:{
        select_model(e, model){
          console.log('click ');
          model.selected = e
          this.models.filter(e => e.id!=model.id).forEach(e => e.selected = false)

        },
        selected_valuename(id, idvn){
            return parseInt(id) != parseInt(idvn)
        },
        change_valuename(e, item, vn){
            if(e)
                item.IDu_valuename = vn.id
            else
                item.IDu_valuename = -1
            this.$forceUpdate()
            
        },
        save(){
            if(this.vn_name == ''){
                this.$swal({
                    icon:'error',
                    title:'Input Error', 
                    text:'Valuename cannot be empty', 
                })


                return 1
            }

            this.$socket.emit('save-chart-models', {token: vue.$token, maschine:this.maschine, chart:this.editObject, models:this.models, valuename:{name: this.vn_name, unit: this.vn_unit}})
            return 0
        }
    },
    sockets:{
        'show-graph-models': function({models, valuenames, valuename}){
            models.forEach(m => {
                m.items.forEach(i => i.id = (i.id < 0)? uuid(): i.id )
                console.log(m)
            });
            this.models = models
            this.valuenames = valuenames

            if (valuename){
                this.vn_name = valuename.name
                this.vn_unit = valuename.unit
            }
            console.log(this.models)
        }
    },
    watch:{
        open(){
            if(this.open){
                this.$socket.emit('load-chart-models', {token: vue.$token, maschine:this.maschine, chart:this.editObject})
            }
        }
    },
    data(){
        return{
            valuenames: [ ],
            models: [
               
            ],
            vn_name: '',
            vn_unit: ''
        }
    }
}
</script>
<style>
.model-selector{
    
}
.model-selector .items{
    margin-left: 50pt;
    margin-right: 50pt;

}
.vn-wrapper{
    height: 180pt;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}
.selector{
    height: 1pt;
    background-color: var(--color0);
}
</style>