<template>
     <div class="maschine_name horizontal-wrapper" :class="{ 'active': active, 'global-view':!id }" v-on:click="click_on_name" style="flex: 0 0 auto">
        <p v-if="id == 0" class="maschine-name" style="flex: 1 0 0; text-align: left; font-size: 20pt; margin: auto 2pt">Globale Übersicht</p>
        <router-link v-else class="maschine-name" style="flex: 1 0 0; text-align: left; " v-on:click="$store.commit('display/set-show-kategories', false)" :to="{ name: 'maschine-overview-view', params: { id: id, name: name } }">{{ name }}</router-link>

        <svg viewBox="0 0 30 30" style="flex: 0 0 30pt" class="maschine_opener" :class="{ 'open': display.showMaschines }">
            <line x1="2" y1="8" x2="16" y2="22" style="stroke:white;stroke-width:3"></line>
            <line x1="14" y1="22" x2="28" y2="8" style="stroke:white;stroke-width:3"></line>
        </svg>
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    name : 'maschine-opener',
    props: { id:[String, Number], name: String, active:Boolean },
    computed:{
        ...mapState(["display"]),

    },
    methods:{
        click_on_name(){

            if (this.$isMobile())
                this.$store.commit('display/set-show-maschines', !this.display.showMaschines)

            this.$emit('maschine-opener')

        }
    },
    data(){
        return{

        }
    }
}
</script>

<style>
 p.maschine_name::after{
    display: block;
    width: var(--font1);
    height: var(--font1);
    content: '';
  }

 .maschine_name p{
    flex: 1 0 0;
    text-align: left;
  }

 .maschine_name .maschine_opener{
    flex: 0 0 25pt;

    transition: transform 0.4s;
  }
 .maschine_name .maschine_opener.open{
    transform: rotate(-180deg);
  }
 .maschine_name.active{
    border-left-color: var(--color3);
  }

 .maschine_name .maschine_opener{
    display: none;
 }
 .maschine_name.global-view{
    display: none;
 }
@media screen and (max-device-width: 800px) {/*für mobil*/
    .maschine_name.global-view{
        display: flex;
    }
    .maschine_name .maschine_opener{
        display: block;
    }
}

</style>