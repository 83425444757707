<template>
    <div ref="dashboard-panel" style="height: 100%; overflow-y: scroll" :style="display.editorMode? 'padding: 50px 0': ''">
        
        <div v-if="display.editorMode" class="" style="height: 50px; margin-top: -50px; width: 100%; position: fixed; z-index: 11;" v-on:dragenter="startScrolling(1)" v-on:dragleave="stopScrollingUp"></div>
       
        <realtime-banner v-if="display.showRealtimeOption && !display.editorMode" :maschine="{id:id, name:name}"  ></realtime-banner>

        <charts-vue v-for="(chart, i) in charts" :key="chart.html_id" 
            :id="chart.html_id" :number="i" 
            :chart="chart" ref="chart"
            :maschine="{id:id, name:name}" 
            :class="{'edit':chart.edit}" 
            :edit="display.editorMode"
            v-on:item-clicked="graph_clicked(chart)" v-on:change-date="changeChartDates" 
            v-on:reorder-chart="graphReorder"
            />
        
        <charts-vue v-if="false"/>

        <schema v-for="schema in schemas" :key="schema.key" 
            :maschine="{id:parseInt(id), name:name}" 
            :schema="schema"
            :edit="display.editorMode" 
            v-on:item-clicked="schema_clicked" />
        <export-template v-for="(etemp, i) in exportTemplates" :key="'export-template_' + i +  $uuid.v4()" :etemp="etemp"
            :maschine="{id:parseInt(id), name:name}" :edit="display.editorMode" v-on:item-clicked="edit_export_template" />
        <controls v-for="(control, i) in controls" :key=" i + $uuid.v4()" :control="control" :edit-mode="display.editorMode"
            :maschine="{id, name}" v-on:item-clicked="graph_clicked" />
        <div v-if="!(kid == -2 && display.editorMode)">
            <values-table v-for="vt in valuesTables" :key="vt.key" :values-table="vt.table" :name="vt.name"
                :table-height="dashboardPanelHeight" :options="vt.options" v-on:get-data-point="getDataPoint"
                :maschine="{id, name}" />
        </div>
      
        
        
        <add-items-template :edit-mode="display.editorMode" :circle="{rad: 200, offset_x: 50, offset_y:50}" :additems="add_items" />
        <maschine-config v-if="(kid == -2 && display.editorMode)" :maschine="{id: id, name: name}"/>
        
        <graphic-editor v-else ref="graphic_editor" :open="display.editorMode" :maschine="{id:parseInt(id)}"
            :edit-object="selected_object" :edit_type="edit_type" :multiple-objects="multiple_objects_selected"
            v-on:property-changed="propertyChanged" ></graphic-editor>

        <div v-if="display.editorMode" class="" style="height: 50px; width: 100%; bottom:0px; position: fixed; z-index: 11; "  v-on:dragenter="startScrolling(-1)" v-on:dragleave="stopScrollingUp"></div>
        
    </div>
</template>
<script>
import chartsVue from '@/components/charts.vue'
import controls from '@/components/controls.vue'
import schema from '@/components/schema.vue'
import ExportTemplate from '@/components/export-template.vue'
import AddItemsTemplate from '../../components/add-items-template.vue'
import graphic_editorVue from '../../components/graphic_editor.vue'
import valuesTableVue from '../../components/dashboard-components/values-table.vue'
import maschineConfigVue from '../../components/maschine-config/maschine-config.vue'

import realtimeBannerVue from '../../components/realtime-banner.vue'

const lodashClonedeep = require("lodash.clonedeep");

import { mapState } from 'vuex'

export default {
    name: 'kategorie-view',
    props: { id: [String, Number], name:String, 'kid': [String, Number], kategorie: String},
    components: {
        'charts-vue': chartsVue,
        'schema': schema,
        'export-template': ExportTemplate,
        'controls': controls,

        'add-items-template':AddItemsTemplate,
        'graphic-editor':graphic_editorVue,

        'values-table':valuesTableVue,
        'maschine-config': maschineConfigVue,

        'realtime-banner':realtimeBannerVue
    },
    computed: {
        token(){
            return this.$store.getters.token
        },

        messageFrames(){
            return this.$store.getters.getMessageFrames
        },

       
        dashboardPanelHeight(){
            return this.$refs['dashboard-panel'] ? this.$refs['dashboard-panel'].clientHeight: 600
        },
        
        ...mapState(['display'])
    },
    methods: {
        changeChartDates({ from, till }) {
            this.charts.forEach(e => {
                e.from = from
                e.till = till
            })

            this.$refs.chart.forEach(e => {
                e.change_date()
            })
        },
        graph_clicked(chart) {
            if (this.display.editorMode) {
                this.multiple_objects_selected = false;

                this.edit_type = 'graph'
                this.charts.forEach(e => e.edit = false);
                this.schemas.forEach(e => e.edit = false);
                this.export_templates.forEach(e => e.edit = false)
                this.controls.forEach(e => e.edit = false)

                this.selected_object = chart
                chart.edit = true;
            }
        },
        schema_clicked(obj) {
            if (this.display.editorMode) {
                this.edit_type = obj.type == 'schema' ? 'schema' : 'schema-item';
                if (obj.type == 'image')
                    this.edit_type = 'image'

                this.charts.forEach(e => e.edit = false);
                this.schemas.forEach(e => e.edit = false)
                this.export_templates.forEach(e => e.edit = false)
                this.controls.forEach(e => e.edit = false)

                if (!(this.shiftPressed && this.edit_type == 'schema-item')) {
                    this.schemas.forEach(e => e.objects ? e.objects.forEach(e => e.edit = false) : 0);
                }

                obj.edit = true;
                this.selected_object = obj

                const activeSchema = this.schemas.find(e => e.objects.some(o => o.edit && this.edit_type == 'schema-item'));
                if (activeSchema)
                    this.multiple_objects_selected = activeSchema.objects.filter(e => e.edit).length - 1 ? true : false
                else
                    this.multiple_objects_selected = false;

            }
        },
        graphReorder({key, order}){
            const chart = this.charts.find(e => e.html_id == key)
            const includeChart = function(e){
                if(order > chart.reihenfolge && order)
                    return e.reihenfolge > order;
                else
                    return e.reihenfolge >= order
            }
            
            this.charts.filter((e) => (!order || includeChart(e)) && e.html_id != chart.html_id).forEach(e => e.reihenfolge = e.reihenfolge + 1)

            if (order > chart.reihenfolge && order)//if order is not 0 add one
                chart.reihenfolge = order + 1
            else
                chart.reihenfolge = order

            const c = lodashClonedeep(this.charts).sort((a, b) => a.reihenfolge - b.reihenfolge)
            c.forEach((e, i) => e.reihenfolge = i)
            this.charts = c
            this.$socket.emit('reorder-charts', { token: this.token, maschine: { id: this.id, name: this.name }, kategorie:{id: this.kid}, charts: this.charts})
        },

        //property of one Item Changed 
        propertyChanged({ property, value }) {
            if (this.edit_mode && this.edit_type == 'schema-item') {
                this.schemas.forEach(s => s.objects.forEach(o => {
                    if (o.edit) {
                        o[property] = value
                        this.$socket.emit('edit-schema-item', { token: this.token, maschine: { id: this.$props.id, name: this.$props.name }, schema: s, item: o })
                    }
                }))
            }
        },


        getDataPoint({key, vIDs}){
            this.$socket.emit('get-data-point', {token: this.token, maschine: {id: this.id, name: this.name}, vIDs: vIDs, key: key})
        },

        startScrolling(direction) {
            const container = this.$refs['dashboard-panel']

            this.scrollInterval = setInterval(() => {
                container.scrollTop -= 5 * direction; // Adjust scroll speed by changing the number
            }, 10); // Adjust interval speed (ms)
        },

        stopScrollingUp() {
            clearInterval(this.scrollInterval);
        },

       
    },
    sockets: {
        'clear_charts':function() {
            this.controls = [];
            this.tables = [];
            this.charts = [];
            this.valuesTables = [];
            if (this.kid < 0)
                this.export_templates = [{ id: -1, name: 'Full Export', export_type: '.csv', edit: false }];
            else
                this.export_templates = []
            this.schemas = [];
        },
        create_dashboard_lines(graph_ids) {
            graph_ids.forEach(e => {
                e.edit = false;
                e.value = '';
                e.max = '';
                e.min = '';
                e.avg = '';
                e.from = '';
                e.till = '';
                this.charts.push(e)
            });


            this.charts.sort((a, b) => a.reihenfolge - b.reihenfolge)
            this.charts.forEach(c => {
                let match = this.messageFrames.find(e => e.IDh_graph == c.id)
                if (match)
                    c.ew_frame = match
            });
            this.charts.length ? this.charts[this.charts.length - 1].lastChart = false : null
            
        },
        'add_control_header': function (control) {
            this.controls.push(control)
        },
        add_schema(schema) {
            schema.edit = false;
            schema.key = this.$uuid.v4()
            this.schemas.push(schema)
        },
        'add-export-template': function (export_template) {
            this.export_templates.push(export_template)
        },

        'set-values-tables': function({id, name, options, table}){
            table.forEach(e => e.editValue = false)
            if (id < 0)
                this.valuesTables = [{ name: 'Datenpunkte', table: table, options: options, key: this.$uuid.v4()} ]
            else
                this.valuesTables = [{ id, name, table, options: options, key: this.$uuid.v4() } ]
        },
        'set-data-point': function({value, key}){
            this.valuesTables.forEach(table => {
                const m = table.table.find(e => e.key == key)
                if(m && !m.editValue)
                    m.value = value
            })
        },
        
        'update_my_editor': function({ item_id, item, type }) {
            this.charts.forEach(c => c.edit = false);
            this.schemas.forEach(c => c.edit = false);

            if (item == 'chart') {
                const new_chart = { id: item_id, ID: item_id, name: 'new chart', type: type, edit: false }
                this.charts.length? this.charts[this.charts.length - 1].lastChart = false: null
                this.charts.push(new_chart)

                this.charts.length ? this.charts[this.charts.length - 1].lastChart = false : null

                this.graph_clicked(new_chart)
            } else if (item == 'schema') {
                const new_schema = { id: item_id, ID: item_id, name: 'new chart', type: 'schema', edit: false }
                this.schemas.push(new_schema);
                this.schema_clicked(new_schema);
            }
        }, 
        'show-ew-frames': function (message_frames) {
            this.message_frames = message_frames
            this.$store.commit('set-message-frames', message_frames)
        },
        'remove-chart-from-dashboard': function ({ chart }) {
            this.charts = this.charts.filter(e => e.id != chart.id)
        },
        'remove-schema-from-dashboard': function ({ schema }) {
            this.schemas = this.schemas.filter(e => e.id != schema.id)
        }
    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.$socket.emit('load_one_kategorie', { token: vm.token, maschine: { id: parseInt(vm.id) }, mobil: vm.$isMobile(), kategorie: {id: vm.kid} })

            // set all menu opener values to false after entering a new maschine
            vm.$store.commit('display/set-show-maschines', false);
            vm.$store.commit('display/set-show-kategories', false);
            vm.$store.commit('display/set-burger-active', false)


            vm.$store.commit('display/set-realtime-option', false)


        });
    },
    beforeRouteUpdate(to, from, next){
        const vm = to.params;
        this.$socket.emit('load_one_kategorie', { token: this.token, maschine: { id: parseInt(vm.id) }, mobil: this.$isMobile(), kategorie: { id: vm.kid } })

        // set all menu opener values to false after entering a new maschine
        this.$store.commit('display/set-show-maschines', false)
        this.$store.commit('display/set-show-kategories', false)
        this.$store.commit('display/set-burger-active', false)



        next()
    },
    data(){
        const vm = this;

        return {
            charts: [],
            schemas: [],
            exportTemplates: [],
            controls: [],
            

            selected_object: {},
            shiftPressed: false,
            edit_mode: false,
            edit_type: 'graph',
            multiple_objects_selected: false,

            add_items: [
                {
                    name: 'chart', image: require('../../images/add-items-icons/chart-line-solid.svg'), deg: 10, 
                additem: function() {  vm.$socket.emit('add-chart-to-maschine', {token:vm.token, maschine:{id:vm.$props.id, name:vm.$props.name, },kategorie: vm.kid > 0? {id: vm.kid } : null } );}}, 
                {
                    name: 'export', image: require('../../images/add-items-icons/file-export-solid.svg'), deg: 40, 
                    additem: function () { vm.$socket.emit('add-export-template', { token: vm.token, maschine: { id: vm.$props.id, name: vm.$props.name }, kategorie: vm.kid > 0 ? { id: vm.kid } : null }); }}, 
                {
                    name: 'RI Schema', image: require('../../images/add-items-icons/water-pump.png'), deg: 70, 
                    additem: function () { vm.$socket.emit('add-schema', { token: vm.token, maschine: { id: vm.$props.id, name: vm.$props.name }, kategorie: vm.kid > 0 ? { id: vm.kid } : null });   }}, 
            ],

            valuesTables: [],
            scrollInterval: 0,

            showRealtimeOption: true,

        }
    }
}
</script>