<template>
  <div class="user-settings-groups horizontal-wrapper" >
    <div class="vertical-wrapper" style="flex: 0.25 0 0">
      <list :items="groups" class="groups_list sidebar" v-on:item-clicked="group_selected"/>
      <div>
        <md-button class="md-primary md-raised" v-on:click="addGroup">Add</md-button>
      </div>
    </div>
    <div class="details_wrapper">
      <div class="name_wrapper vertical-wrapper">
        <p>Group:</p>
        <md-field>
          <label for="">Name</label>
          <md-input v-model="group.name" v-on:blur="postSettingsGroups"></md-input>
        </md-field>
        <div>
          <md-button class="md-accent md-raised" v-on:click="removeGroup">Remove</md-button>
        </div>
      </div>
      <div class="maschine_wrapper">
        <p>Maschines:</p>

        <checkbox v-for="item in maschines" :key="item.id" :text="item.text" :value="+item.active" class="font-20pt" v-on:input="(e) => {item.active = e; postSettingsGroups()}"/>
      </div>
    </div>
  </div>
</template>
<script>
import list from '@/components/VueComponents/list.vue'
import checkbox from '@/components/VueComponents/checkbox.vue'
 
import { mapState } from 'vuex'


export default {
  name: 'user-settings-groups',
  props: { },
  components: {list, checkbox},
  computed: {
    ...mapState(['token', 'mandates', 'admin']),
  },
  methods:{
    loadGroups(groups, loadingNewGroup){

      const m = this.groups.find(e => e.active);

      const selectedId = m? m.id : -1
      
      // depending if selected id is not negative go throug the array and select the previously selected
      // if not make the first one the selected one
      const activeGroup = groups.reduce((arr, e, i, array) => {
        // decide active sign
        if(selectedId > 0)
          e.active = e.id == selectedId
        else if(loadingNewGroup){
          e.active = !this.groups.some(g => e.id== g.id)
        }
        else
          e.active = !i

        // return the new arr if this is the new active group
        if(e.active) 
          return e
        // if last one and it is not active return first item
        else if((array.length -1 <= i) && (!arr)) 
          return this.group[0]

        return arr
      }, 0)

      this.groups = groups;
      
      if(activeGroup)
        this.getSettingsGroupsDetails(activeGroup.id)



    },

    group_selected(group){
      this.groups.forEach(e => e.active = false)
      group.active = true
      if(group.id < 0){
        this.group.name = ''
        this.group.id = -1
        this.maschines.forEach(e => e.active = false)
      }else{

        this.getSettingsGroupsDetails(group.id)
      }

    },

    getMandate(){
      return new Promise((resolve) => {
        if(this.mandates.length > 1){
          this.$swal({
            title: 'Neue Gruppe?',
            icon: 'question',

            showConfirmButton: true,
            confirmButtonText: 'Erstellen',
            showDenyButton: true,
            denyButtonText: 'Abbrechen',

            html: `
          <p></p>
          <text>Möchten Sie eine neue Gruppe erstellen?</text>
            <br><label>
              Mandat:
            </label>
            <select id="swal-input-mandate" class="swal2-input">
              ${this.mandates.length ? this.mandates.map((e, i) => `<option ${!i ? 'selected' : ''} value="${e.id}">${e.name}</option>`).join('\n') : ''}
            </select>

          <div style="margin-bottom: 5pt;"></div>
        ` ,
            focusConfirm: false,
            preConfirm: () => {
              const swalInputMandate = document.getElementById('swal-input-mandate')
              return {
                IDh_mandate: swalInputMandate ? swalInputMandate.value : 0
              }
            }

          }).then((value) => {
            if (value.isConfirmed){

              resolve(value.value.IDh_mandate)
            }

          })
        }else{
          resolve(this.mandates.length? this.mandates[0].id : 0)
        }

      })
    },
  
    async addGroup(){
     

      const mandate = await this.getMandate()
      if(!mandate)
        return

      this.mandate = mandate
      this.groups.forEach(e => e.active = false)

      this.group.name = ''
      this.group.id = -1
      this.maschines.forEach(e => e.active = false)
    },

    async removeGroup(){
      const {isConfirmed} = await this.$swal.fire({
        icon: 'question', title: 'Gruppe entfernen?', text: `Nutzer haben vielleicht keinen Zugang zu ihren Maschinen`,
        showConfirmButton: true, confirmButtonText: 'Ja',
        showDenyButton: true, denyButtonText: 'Nein'
      })
      if(isConfirmed)
        this.deleteSettingsGroup()
    },

    // http calls
    // GET /settings/groups
    getSettingsGroups: async function (loadingNewGroup = false) {

      const url = `${this.$hostname}/settings/groups`;  // Use this.$hostname for the base URL
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': this.token, // using this.token
          },
        });

        const groups = await response.json();

        this.loadGroups(groups, loadingNewGroup)




      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    },
    // GET /settings/groups/:id
    getSettingsGroupsDetails : async function (groupId) {
      // Use encodeURIComponent to ensure proper URL encoding of groupId
      const encodedGroupId = encodeURIComponent(groupId);
      const url = `${this.$hostname}/settings/groups/${encodedGroupId}`;  // Use this.$hostname for the base URL

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': this.token, // using this.token
          },
        });
        const { group, maschines } = await response.json();
        this.group = group;
        this.maschines = maschines

      } catch (error) {
        console.error('Error fetching group by ID:', error);
      }
  
    },

    // POST /settings/groups
    postSettingsGroups: async function () {

      if(this.group.name == '')
        return

      const data = { group: this.group, maschines: this.maschines, reload: this.group.id < 0, mandate: this.mandate }

      const url = `${this.$hostname}/settings/groups`;  // Use this.$hostname for the base URL
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token, // using this.token
          },
          body: JSON.stringify(data), // Send data in the request body
        });

        if(response.status == 200){
          this.getSettingsGroups( this.group.id < 0)
        }
      } catch (error) {
        console.error('Error posting group data:', error);
      }
    },
    // DELETE /settings/groups
    deleteSettingsGroup: async function () {
      const url = `${this.$hostname}/settings/groups`;  // Use this.$hostname for the base URL
      const data = { id: this.group.id }
      try {
        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token, // using this.token
          },
          body: JSON.stringify(data), // Send the id in the request body
        });

        if (response.ok) {
          this.getSettingsGroups()
        } else {
          console.log(`Failed to delete: ${response.status}`);
          const errorData = await response.text();
          console.error(errorData);
        }
      } catch (error) {
        console.error('Error deleting group:', error);
      }
    }
    
  },
  beforeRouteEnter (to, from, next) { 
    next(vm => { 
        vm.getSettingsGroups()
   
        next();
    }) 
  },

  
  data(){
    return{
      groups: [],
      group: {name: '', },
      maschines: [],
      currentGroupIDs: [],

      mandate: null,
    }
  } 
}
</script>
<style>
.user-settings-groups{
  flex: 1 0 0; 
  font-size: var(--font1);

  display: flex;
  flex-direction: row;
}
.user-settings-groups .groups_list{
  display: 0 0 auto;
  display: flex;
  flex-direction: column;;
}
.user-settings-groups .groups_list:last-child{
  text-align: center;
}
.user-settings-groups .details_wrapper{
  flex: 1 0 0;  
  display: flex;
  flex-direction: row;
}
.user-settings-groups .details_wrapper > div{
  flex: 1 0 0;
  border: solid 2pt var(--color5);
  margin: 5pt;
  padding: 2pt;
}
.user-settings-groups p{
  text-align: left;
}
.user-settings-groups label{
  text-align: left;
  display: flex;
  flex-direction: column;
}
.user-settings-groups .maschine_wrapper{
  overflow-y: scroll;
  max-height: calc(100vh - (5vh + 105pt));
}
.user-settings-groups input{
  font-size: var(--font1);
  width: 400pt;
}
</style>