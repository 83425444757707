import { render, staticRenderFns } from "./loginpage.vue?vue&type=template&id=2ebce0ae"
import script from "./loginpage.vue?vue&type=script&lang=js"
export * from "./loginpage.vue?vue&type=script&lang=js"
import style0 from "./loginpage.vue?vue&type=style&index=0&id=2ebce0ae&prod&media=screen&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports