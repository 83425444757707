<template>
    <div class="vertical-wrapper">
        <div class="header horizontal-wrapper" style="flex: 0 0 auto">
            <h4>Syncronisiert</h4>
            <div class="icon" 
                :style="`flex: 0 0 20pt; cursor:pointer; margin: 5pt; height: 20pt; background-image: url(${require('../../images/upload-solid.svg')})`"
                v-on:click="readConfig">

            </div>

            <div class="icon"
                :style="`flex: 0 0 20pt; cursor:pointer; margin: 5pt; height: 20pt; background-image: url(${require('../../images/download-solid.svg')})`"
                v-on:click="writeConfig">
            
            </div>
        </div>
        <maschine-config-table 
            :maschine="maschine" 
            :title="'S7 GET'" :type="'s7get'"
            :header="[
            { title: 'id', flex: 0, type:'hidden' }, 
            
            { title: 'valuename', flex: 3 }, 
            { title: 'unit', flex: 1 }, 
            { title: 'ip', flex: 1 }, 
            { title: 'db', flex: 1}, 
            { title: 'offset', flex: 1}, 
            { title: 'datatype', flex: 1, type: 'dropdown', source: ['int', 'real', 'dint', 'bit']}]"
           

            ></maschine-config-table>
        <maschine-config-table :maschine="maschine" :title="'AnoPI'" :type="'anopi'"
            :header="[
                { title: 'id', flex: 0, type: 'hidden' }, 
            
                { title: 'valuename', flex: 3 }, 
                { title: 'unit', flex: 1 }, 
                { title: 'offset', flex: 1 }, 
                { title: 'messurement', flex: 1, type: 'dropdown', source: ['4-20mA', '0-20mA', '0-10V', '2-10V'] }, 
                { title: 'min', flex: 1 }, 
                { title: 'max', flex: 1 }]">
            </maschine-config-table>
    </div>
</template>
<script>
import maschineConfigTableVue from './maschine-config-table.vue'
export default{
    name: 'maschine-config',
    props: { maschine: Object},
    components: {
        'maschine-config-table':maschineConfigTableVue
    },
    sockets: {
        
     
    },
    computed:{
        token(){
            return this.$store.getters.token
        }
    },
    mounted(){
    },
    methods:{
        readConfig(){
            this.$swal.fire({
                icon: 'warning',
                title: 'Achtung Lesen?', 
                text: 'von Edge Knoten lesen',
                showConfirmButton: true,
                confirmButtonText: 'Ja',

                showDenyButton: true,
                denyButtonText: 'Nein'
            }).then(e => {
                if(e.confirm){
                    console.log('read from');
                }
            })
        },
        writeConfig(){
            this.$swal.fire({
                icon: 'warning',
                title: 'Achtung Schreiben?',
                text: 'auf Edge Knoten schreiben',
                showConfirmButton: true,
                confirmButtonText: 'Ja',

                showDenyButton: true,
                denyButtonText: 'Nein'
            }).then(e => {
                if (e.confirm) {
                    console.log('write from');
                }
            })
    }
    },
    data(){
        return{
            s7SetTable: []
        }
    }
}
</script>