<template>
    <div>

        <md-dialog :md-active.sync="show" class="maschine-fingerprint" style="padding: 14pt; " v-on:close="$router.go(-1)" v-on:md-clicked-outside="$router.go(-1)">
            <div class="horizontal-wrapper" style="padding: 24px 24px 0;">
                <md-dialog-title style="padding: 0; margin: auto">Maschinen Fingerprint</md-dialog-title>
                <div></div>
                <md-button v-on:click="copyToClipboard">Copy to Clipboard</md-button>
            </div>
            <div class="vertical-wrapper" style="margin: 14pt; ">

                <md-field>
                    <md-textarea v-model="fingerprint" :disabled="true"  md-autogrow>
                        
                    </md-textarea>
                </md-field>
            </div>
            
        </md-dialog>
        <md-snackbar :md-duration="2000" :md-active.sync="showSnackbar" md-persistent>
            <span>Copied to Clipboard!</span>
            <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
        </md-snackbar>
        </div>

</template>
<script>
export default {
    name: 'maschine-fingerprint-dialog',
    props: { id:[String, Number] },
    methods: {
        save(){
          
        },
        async copyToClipboard() {
            try {
                await navigator.clipboard.writeText(this.fingerprint);
                this.showSnackbar = true
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        },
    },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.show = true

            const body = JSON.stringify({maschine: {id: vm.$props.id}})
            fetch(`${vm.$hostname}/settings/maschines/fingerprint`, {
                    method: 'POST', 
                    headers: { authorization: vm.token, "Content-Type": "application/json", },
                    body
            }).then(async (e) => {
                if(e.status == 200)
                    vm.fingerprint = (await e.json()).fingerprint
            }).catch()
            next();
        }) 
    },
    sockets:{
        

    },
    computed:{
        token(){
            return this.$store.getters.token
        },
     
    },
    data(){
        return{
            show: false,
            fingerprint: '',
            showSnackbar: false,
        }
    }
}
</script>
<style>
.maschine-fingerprint.md-dialog .md-dialog-container{
    min-width: 50%; min-width: 40%;
}
</style>