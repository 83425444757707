<template>
  <div class="label-with-edit">
    <div :class="{'hide':edit}" v-on:click="go_edit()">{{name}}</div>
    <input type="text" :class="{'hide':!edit}" :value="name" v-on:change="onchange_text" v-on:keyup.enter="leave_edit">
  </div>
</template>
<script>
export default {
  name: 'label-with-edit',
  props: {item:Object, edit:Boolean, name: String},
  methods:{
    go_edit(){
      this.$emit('deselect-all', 'lol');
      this.$emit('update:edit', true);
    },
    leave_edit(){
       this.$emit('leave-edit', 'lol');
       this.$emit('update:edit', false);

    },
    onchange_text(e){

      this.$emit('update:name', e.target.value)

    }

  },data(){

    return{
     
    }
  }
}
</script>
<style>
.label-with-edit{
  display: flex;
  flex-direction: column;
}
.label-with-edit div{
  cursor: pointer;
  text-align: center;
  font-size: var(--font1);

  margin: auto;

}
.label-with-edit input{
  margin: auto;

  font-size: var(--font1);

}
</style>