<template>
    <div class="global-dashboard-settings">
        <md-dialog :md-active="open">
            <md-dialog-title>Global Dashboards</md-dialog-title>
            <div class="horizontal-wrapper" style="margin: 5pt; min-height: 400pt; min-width: 600pt;">
                <div class="vertical-wrapper">
                    <p v-for="g in globalDashboards" :key="g.key" v-on:click="selectDashboard(g.id)" class="gd-item" :class="{'active': g.id == activeGlobalDashboard }">{{g.name}}</p>
                    <div class="vertical-wrapper">
                        <md-button class="md-raised md-primary" v-on:click="addGlobalDashboard">add</md-button>
                    </div>
                </div>

                <div style="flex: 4 0 0" class="vertical-wrapper">
                    <md-tabs style="flex: 1 0 0 " class="vertical-wrapper" :md-dynamic-height="true">
                        <md-tab md-label="Allgemein">
                            <md-field>
                                <label for="">Name</label>
                                <md-input v-model="name"></md-input>
                            </md-field>
                            <md-field>
                                <label for="">Dashboard Typ</label>
                                <md-select v-model="dashboardType">
                                    <md-option :value="'table'">Tabelle</md-option>
                                    <md-option :value="'chart'">Charts</md-option>

                                </md-select>
                            </md-field>
                            <md-button class="md-raised md-accent" v-on:click="deleteGlobalDashboard">Löschen</md-button>
                        </md-tab>
                        <md-tab md-label="Teilen" style="vertical-wrapper">
                            <div class="horizontal-wrapper" style="flex: 0 0 auto">
                                <md-field>
                                    <label for="">Suchen</label>
                                    <md-input v-model="searchText"></md-input>
                                </md-field>
                            </div>

                            <div style="flex: 1 0 0; overflow-y: scroll; max-height: 300pt" class="vertical-wrapper">
                                <div v-for="u in users.filter(e => e.name.toLowerCase().includes( searchText.toLowerCase() )) "  :key="u.key" class="horizontal-wrapper" style="flex: 0 0 auto; margin: 2px;">
                                    <input  type="checkbox" style="display: block; -webkit-appearance: auto;" v-model="u.active" :name="u.name" v-on:input="changeUserActive(u.id)">
                                    <label v-on:click="changeUserActive(u.id)">{{ u.name }}</label>
                                </div>
                            </div>
                        </md-tab>
                    
                    </md-tabs>
                </div>
            </div>
            <md-dialog-actions>
                <md-button class="md-primary" @click="$router.go(-1)">Close</md-button>
                <md-button class="md-primary" v-on:click="saveGlobalDashbordSettings">Save</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>
<script>
export default {
    name: 'global-dashboard-settings-view',
    props: {id: [String, Number]},
    computed:{
        token() {
            return this.$store.getters.token
        },
        globalDashboards(){
            return this.$store.getters.getGlobalDashboards
        }
    },
    watch:{
        activeGlobalDashboard(){
            if(this.activeGlobalDashboard > 0)
                this.$socket.emit('get-global-dashboard-settings-details', { token: this.token, IDh_global_db: this.activeGlobalDashboard })
        }
    },
    methods:{
        selectDashboard(id){
            this.lastDashboardId = this.activeGlobalDashboard;


            this.activeGlobalDashboard = id;
        },
        addGlobalDashboard(){
            this.name = ''
            this.type = 'table'
            this.activeGlobalDashboard = -1

            this.$swal.fire({ icon: 'info', text: 'Neues Dashboard erstellt', title: 'New Dashboard' })

        },
        async deleteGlobalDashboard(){
            const { isConfirmed } = await this.$swal.fire({ 
                icon: 'warning', 
                title: 'Sicher?',
                text: 'Dashboard wirklich unwiderruflich entfernen?', 
                showConfirmButton: true,
                confirmButtonText: 'Ja',

                showDenyButton: true,
                denyButtonText: 'Nein'
            
            })
            if(isConfirmed){
                this.$socket.emit('delete-global-dashboard-settings', { token: this.token, IDh_global_db: this.activeGlobalDashboard });

            }

        },
        changeUserActive(){
            this.changesMade = true;
        },
        saveGlobalDashbordSettings(){
            const globalDashboard = { id: this.activeGlobalDashboard, name: this.name, type: this.dashboardType }
            const users = this.users;

            this.$socket.emit('edit-global-dashboard-settings', { token : this.token, globalDashboard, users  });

            this.$router.push({ name: 'main-global-dashboard', params: {id : this.activeGlobalDashboard }})
        }
    },
    beforeRouteEnter(from, to, next){
        next(vm => {
            vm.open = true

            if(vm.globalDashboards.length == 0){
                vm.addGlobalDashboard()
                return
            }

            vm.activeGlobalDashboard = parseInt(to.params.id | vm.id);

            vm.$socket.emit('get-global-dashboard-settings-details', { token: vm.token, IDh_global_db: vm.activeGlobalDashboard })
        })  
    },
    sockets:{
      'set-global-dashboard-settings-details': function({ globalDashboard, users }){
        this.name = globalDashboard.name;
        this.dashboardType = globalDashboard.type;

        this.users = users;
        this.changesMade = false
      },
      'set-global-dashboard-settings-details-error': function(error){
        if(error == 'no-ownership'){
            this.$swal.fire({ icon : 'error', text: 'Dieses Dashboard gehört jemanden anderes', title: 'No Ownership'})
            this.activeGlobalDashboard = this.lastDashboardId
        }
      }
    },
    data(){
        return{
            open: false,
            activeGlobalDashboard: -1,
            lastDashboardId: -1,

            name : '',
            users: [],
            searchText: '',
            dashboardType: 'table',

            changesMade: false
        }
    }
}
</script>
<style>
.md-tabs.vertical-wrapper .md-tabs-navigation{
    flex: 0 0 auto;
}
.md-tabs.vertical-wrapper .md-content{
    display: flex;
    flex-direction: column;
}
.global-dashboard-settings .md-tab{
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    height: 100%;
}
.gd-item{
    background-color: var(--color1);
    padding: 5pt 20pt;
    cursor: pointer;
}
.gd-item.active{
    background-color: var(--color2);
    color: var(--color1);
}
</style>