<template>
  <div class="charts-details" :class="{'hide':!show}" v-on:click.self="$emit('close-details')">
    <div class="panel vertical_wrapper">
      <div class="header vertical_wrapper">
        <div class="horizontal_wrapper">
          <div class="name">{{chart.name}}</div>
          <div class="placeholder"></div>
          <burger :value="true" :class="{'font42pt':!$isMobile()}" v-on:close="$emit('close-details')" />
        </div>
        <div class="value">
          <p style="font-size: 28pt; font-weight: bold; margin: 10pt; line-height: 28pt;">{{chart.value + ' ' +
            chart.unit}}</p>
        </div>
        <div v-if="chart.type != 'model'" class="details" :class="{'horizontal_wrapper':!$isMobile(), 'vertical_wrapper':$isMobile()}">
          <div v-if="chart.max">{{'max: ' + chart.max + ' ' + chart.unit}}</div>
          <div v-if="chart.min != undefined">{{ ((chart.type != 'bar')? 'min: ': 'start: ') + chart.min + ' ' + chart.unit}}</div>

          <div v-if="chart.avg && chart.type != 'bar'">{{'avg: ' + chart.avg + ' ' + chart.unit}}</div>
          <div v-if="chart.avg && chart.type == 'bar'">{{'diff: ' + chart.avg + ' ' + chart.unit}}</div>

        </div>
      </div>

      <md-tabs class="chart-details-selector" v-if="realtimeChart" v-on:md-changed="changeChartDisplay" style="flex: 0 0 auto" md-alignment="centered">
        <md-tab id="realtime" md-label="Echtzeit"></md-tab>
        <md-tab id="trend" md-label="Trend"></md-tab>
      </md-tabs>



      <md-progress-bar v-if="loading" md-mode="query"></md-progress-bar>

      <div class="chart-frame horizontal_wrapper">
        <div class="scala vertical_wrapper" v-if="chart.type == 'line' && !$isMobile()">
          <!-- Scaling Block-->
          <md-field style="margin-top: 90px;">
            <label>max</label>
            <md-input v-model="scale.max" @blur="scaleChanged"></md-input>
          </md-field>
          <div class="placeholder"></div>
          <md-button v-if="scale.active" class="md-icon-button md-accent" v-on:click="clearScale">
            <md-icon :md-src="require('../images/xmark-solid.svg')"></md-icon>
          </md-button>
          <div class="placeholder"></div>
          <md-field style="margin-bottom: 90px;">
            <label>min</label>
            <md-input v-model="scale.min" @blur="scaleChanged"></md-input>
          </md-field>
        </div>


        <!--CHART BOX-->
        <div v-if="chart.type == 'model'" style="flex: 1 0 0">
          <iframe :src="iframeSrc" frameborder="0" @load="loading = false" style="height: 100%; width: 100%" ref="iframe">
        </iframe>
        </div>
        <div v-else class="chartbox" style="flex: 1 0 0" ref="chartbox">

        </div>

        <div v-if="chart.type == 'line' && !$isMobile()" style="flex: 0 0 200pt">
          <!--chart-prensentation-->
          <h4>Presentation:</h4>
          <md-field style="flex: 0 0 auto; width:auto;">
            <label>presentation</label>
            <md-select v-model="presentation" v-on:md-selected="change_presentation">
              <md-option :value="'absolute'">absolute</md-option>
              <md-option :value="'relative'">relative</md-option>

            </md-select>
          </md-field>
          <h4>Filter</h4>
          <div>
            <!--Slider for Linechars-->
            <input v-if="chart.type == 'line'" type="range" v-model.number="filter" @mouseup="mouseup">
            <div v-if="chart.type == 'line'" :style="`margin: auto 0px;`"> {{ filter }}%</div>
          </div>
          <h4>Data source</h4>
          <md-button class="md-primary">valuenames</md-button>
        </div>

      </div>
      <div v-if="!$isMobile()" class="footer"
        :class="{ 'horizontal_wrapper': !$isMobile(), 'vertical_wrapper':$isMobile()}">
        <vue-datetime type="datetime" title="from" v-model="from_date" format="yyyy.MM.dd HH:mm" />

        <div v-if="!$isMobile()" class="placeholder"></div>

        <!--Selector for Barchars-->
        <md-field v-if="chart.type == 'bar'" style="flex: 0 0 auto; width:auto;">
          <label>type</label>
          <md-select v-model="selector" v-on:md-selected="change_selector">
            <md-option v-for="(item, i) in selectors" :key="i + 'selector'" :value="item.value">{{item.text}}
            </md-option>
          </md-select>
        </md-field>

        <!--segmentation-->
        <md-field v-if="graph_segments.length > 0" style="flex: 0 0 auto; width:auto;">
          <label>Segment</label>
          <md-select v-model="activeSegment" v-on:md-selected="change_selector">
            <md-option v-for="(item, i) in graph_segments" :key="i + $uuid.v4()" :value="item.ID">{{item.Name}}
            </md-option>
          </md-select>
        </md-field>

        <div v-if="!$isMobile()" class="placeholder"></div>

        <vue-datetime type="datetime" title="till" v-model="till_date" format="yyyy.MM.dd HH:mm" />
      </div>

      <!--
        Mobile Graph details
      -->
      <div v-if="$isMobile()" class="footer vertical_wrapper">
        <div class="horizontal_wrapper">
          <vue-datetime type="datetime" title="from" v-model="from_date" format="yyyy.MM.dd HH:mm" />
          <div class="placeholder"></div>
          <vue-datetime type="datetime" title="till" v-model="till_date" format="yyyy.MM.dd HH:mm" />
        </div>
        <!--Selector for Barchars-->
        <md-field v-if="chart.type == 'bar'" style="margin: 5pt; flex: 0 0 auto; width:auto;">
          <label>type</label>
          <md-select v-model="selector" v-on:md-selected="date_changed">
            <md-option v-for="(item, i) in selectors" :key="i + 'selector'" :value="item.value">{{item.text}}
            </md-option>
          </md-select>
        </md-field>
        <!--Slider for Linechars-->
        <input v-if="chart.type == 'line'" type="range" v-model.number="filter" @mouseup="mouseup">
        <div v-if="chart.type == 'line'" :style="`background-color: ${block_filter_color}; margin: auto 0px;`"> {{
          filter }}%</div>
      </div>
    </div>
  </div>
</template>
<script src="https://www.gstatic.com/charts/loader.js"></script>
<script>
import burger from '@/components/VueComponents/burger.vue' 
import { mapState } from 'vuex'

google.charts.load('current', {'packages':['corechart', 'timeline'], 'language': 'de'});

export default {
  name: 'charts-details',
  components:{
    burger
  },
  props: { show:Boolean, chart:Object, maschine:Object, realtimeBuffer:Object, graphOptions:Object },
  watch:{
    show(){
      if(this.show)
        this.loadGraph()
        
    },
  
    selector(){
      
    },
    realtimeChart(){
      if(this.realtimeChart){
        this.chartDisplay = 'realtime'
      }else{
        this.drawLineChart(this.chartData, this.chartOptions)
      }
    }
    
  },
  computed:{
    token(){
      return this.$store.getters.token
    },
    from_date:{
      get() {
        return this.chart.from;
      },
      set(newDate) {
        this.$props.chart.from = newDate
        this.date_changed()
      }
    },
    till_date:{
      get() {
        return this.chart.till;
      },
      set(newDate) {
        this.$props.chart.till = newDate;
        this.date_changed()
      }
    },
    ...mapState(["realtime"]),

    realtimeChart(){
        return  this.graphOptions && this.graphOptions.realtime && this.realtime.realtime && this.realtime.realtimeMaschine == this.maschine.name
    }
  },
  methods:{
    change_selector(){
      

    },
    change_presentation(){
      this.date_changed();
    },
    date_changed(){
      if(this.chart.type == 'model'){

        this.updateChartModel(this.modelIdentifier, this.modelName, this.$moment(this.chart.from).unix(), this.$moment(this.chart.till).unix())
        return
      }
      this.loadGraph()
    },

    mouseup(){
      if(this.block_filter)
        return
      this.block_filter = true
      this.loadGraph()
    },
    chart_loaded(){
      this.loading = false;
    },
    scaleChanged(){
      this.scale.active = true
      this.scale.min.split(',')
      this.scale.max.split(',')

      this.loadGraph()
    },
    clearScale(){
      this.scale.active = false;
      this.loadGraph()
    },

    loadGraph(){
      this.loading = true
      if(!this.show)
        return

      /**
       * Make a Chart Request at Backend
       */
      this.$socket.emit('load_graph', {
        token:this.token, 
        type: this.chart.type, ID:this.chart.ID, 
        graph: this.chart,
        html_id: this.chart.html_id,
        show_details: true,
        activeSegment:this.activeSegment,
        tolerance: this.filter / 100,
        presentation: this.presentation,
        time:{
          von:this.chart.from, 
          bis:this.chart.till, 
          span: (this.chart.type=='bar')? this.selector: null}, 
        mobil:this.$isMobile(), 
        scale: this.scale,
        maschine:{id: parseInt(this.maschine.id)}
      });
    },

    drawLineChart(data, options, source){
      if(this.chartDisplay == 'trend' && source != 'trend')
        return
      
      const chartbox = this.$refs.chartbox
      options.crosshair = { 
        trigger: 'both', 
        orientation: 'vertical', 
        color: '#ccc' 
      }
      options.legend = { position:'bottom' }
  
      options.chartArea = {  left: 100, right: 30}
      const chart = new google.visualization.LineChart(chartbox);
      google.visualization.events.addListener(chart, 'ready', this.chart_loaded);

      chart.draw(data, options);
    },
    changeChartDisplay(e){
      this.chartDisplay = e;

      if(this.chartDisplay == 'trend')
        this.drawLineChart(this.chartData, this.chartOptions, 'trend')
    },


    updateChartModel(key_identifier, name, start, end){
      this.loading = true;

      
      const timeSelection = (start && end)? `/${start}/${end}`: ''

      const url = `${this.$hostname}/model/${name}/chart/${key_identifier}${timeSelection}`
      fetch(url, {
        headers: {
          "Content-Type": "text/html",
          "Authorization": this.token
        },
      }).then(async e => {
        const html = await e.text()
        this.iframeSrc =  "data:text/html;charset=utf-8," + escape(html);
      })

      this.iframeSrc = ''
    },
    
  },
  sockets:{
    add_line_chart({html_id, dataTable, options, show_details}){
      if((this.chart.html_id != html_id) || (!show_details))
        return;
      this.type = 'line'
      const data = google.visualization.arrayToDataTable(
         dataTable.map((row, i) => i? row.map((item, index) => (index)? item:new Date(item)  ): row )
         );
     
      if(dataTable.length <= 2){
        this.block_filter = false
        this.chart_loaded()
        return
      }
      this.chartData = data;
      this.chartOptions = options;

      this.drawLineChart(data, options, 'trend');

      this.block_filter = false
     
    },
    add_bar_chart({html_id, dataTable, options, show_details}){
      if((this.chart.html_id != html_id) || (!show_details))
        return;
        this.type = 'bar'
      const chartbox = this.$refs.chartbox

      options.legend = { position:'bottom' }

      options.height = chartbox.getBoundingClientRect().height ;
      options.width = chartbox.getBoundingClientRect().width; 
      options.chartArea = {  left: 100, right: 30, width: options.width - 100 }
      
      const data = google.visualization.arrayToDataTable(dataTable.map((row, i) => {
        if(i)
          return row.map((c, i) => i? c: new Date(c))
        else
          return row
        }) 
      );
      const chart = new google.visualization.ColumnChart(chartbox);
      google.visualization.events.addListener(chart, 'ready', this.chart_loaded);


      chart.draw(data, options); 

    
    },
    add_status_chart({html_id, dataTable, options, show_details}){
      if(this.chart.html_id != html_id || !show_details)
        return;
      this.type = 'status'
      const chartbox = this.$refs.chartbox

      const chart = new google.visualization.Timeline(this.$refs.chartbox);
      const data = new google.visualization.DataTable();
      data.addColumn({ type: 'string', id: 'name' });

      data.addColumn({ type: 'string', id: 'name' });
      data.addColumn({ type: 'string', id: 'style', role: 'style' });
      data.addColumn({ type: 'date', id: 'start' });
      data.addColumn({ type: 'date', id: 'stop' });
      const dt = dataTable.map(item => [`${item.name}`, item.name, item.color, new Date(item.start), new Date(item.stop)])
      data.addRows( dt );
      
      options.hAxis.maxValue = new Date(options.hAxis.maxValue)
      options.hAxis.minValue = new Date(options.hAxis.minValue)
      options.height = chartbox.getBoundingClientRect().height ;
      options.width = chartbox.getBoundingClientRect().width; 
      options.chartArea = {  left: 100, right: 30, width: options.width - 100 }

      
      google.visualization.events.addListener(chart, 'ready', this.chart_loaded);


      chart.draw(data, options)


    },
    show_graph_details({html_id, name, value, max_value, min_value, avg_value, unit, graph_segments, activeSegment }){
      if(this.chart.html_id != html_id)
        return

      this.chart.name = name;
      this.chart.value = value;
      
      this.chart.max = max_value;
      this.chart.min = min_value;
      this.chart.avg = avg_value;
      this.chart.unit = unit

      if(!this.scale.active){
        this.scale.max = max_value? max_value.join(','): '';
        this.scale.min = min_value? min_value.join(','): '';
      }

      if(graph_segments){
        this.graph_segments = graph_segments
        this.activeSegment = activeSegment;
      }else{
        this.graph_segments = []
        this.activeSegment = 0;
      }

    },
    'add_model': function ({ html_id, options, show_details }) {
        if(this.chart.html_id != html_id || show_details)
          return;
        this.type = 'model'

        this.modelIdentifier = options.key_identifier;
        this.modelName = options.name;
        //this.showFooter = options.dateFooter? true: false

        //this.updateHeaderModel(this.model_identifier, options.name, 0, 0)
        this.updateChartModel(options.key_identifier, options.name, 0, 0)

       
      },
  },
  data(){

    
    return{
      loading: true,
      selector: 'dayly',
      selectors: [{value: 'hourly', text:'Stunde'}, {value: 'dayly', text:'Tag'}, {value: 'weekly', text:'Woche'}, {value: 'monthly', text:'Monat'}, {value: 'yearly', text:'Jahr'}],

      scale: {active: false, min: 0, max: 0},
      scaleMin: '0',
      scaleMax: '0',

      filter: 100,
      block_filter: false,
      block_filter_color: (this.block_filter)? '#ccc': 'white',

      graph_segments : [],
      activeSegment: 0,
      presentation: 'absolute',

      chartData: [],
      chartOptions: {},

      chartDisplay: 'trend',

      iframeSrc: '',
      modelIdentifier: '',
      modelName: '',


      
    }
  }
}
</script>
<style>






.charts-details{
  display: flex;
  flex-direction: column;

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--color7);
  padding: 5% 7%;
  z-index: 20;

  cursor: default;
}
.charts-details .panel{
  background-color: white;
}
.charts-details .name{
  font-size: 22pt;
  margin: auto;
  margin-left: 10pt;
  line-height: 22pt;
}
.charts-details .chart-frame{
  flex: 1 0 0;

}
.charts-details .chart-frame .scala{
  flex: 0 0 40pt;
  width: 40pt;
  margin: 7.5pt;

  display: flex;

}
.charts-details .footer{
  /*display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 
    'fromdate tilldate'
    ' slider   slider'
    ;
    */
}
.charts-details .footer .vdatetime{
  margin: 5pt;
}
.charts-details .footer .vdatetime input{
  font-size: 15pt;
  cursor: pointer;
  text-align: center;
}
.charts-details .details div{
  font-size: var(--font2);
  flex: 1 0 0;

}
.placeholder{
  flex: 1 0 0;
}
@media screen and (max-device-width: 800px) {/*für mobil*/
  .charts-details{
    padding: 0;
  }
  .charts-details .panel{
    height: 100vh;
  }
  .charts-details .header{
    flex: 0 0 auto;
  }
  .charts-details .footer{
    flex: 0 0 auto;

  }
  .charts-details .details div{
    font-size: var(--font3);
    flex: 0 0 auto;

  }
  .vdatetime-input{
    width: 176px;
  }
}
</style>
