<template>
    <div class="vertical-wrapper" ref="table-box">
        <div class="horizontal-wrapper">
            <h3>{{title}}</h3>
        </div>
        <div ref="jexcel-table">

        </div>
        <div class="horizontal-wrapper">
            <div></div>
            <md-button class="md-primary" v-on:click="spreadsheet.getData().length ? spreadsheet.insertRow([-1, '']) : spreadsheet.setData([[-1, '']])">add</md-button>
        </div>
    </div>
</template>
<script>
import jexcelStyle from "jexcel/dist/jexcel.css"; // eslint-disable-line no-unused-vars
import jexcel from "jexcel"; // eslint-disable-line no-unused-vars

export default{
    name: 'maschine-config-table',
    props: { maschine: Object, title: String, type:String, header: Array, body:Array},
    computed: {
        token() {
            return this.$store.getters.token
        },
        editorMode() {
            return this.$store.getters.getEditorMode;
        },  
      
    },
    data(){
        return{
            spreadsheet: 0
        }
    },
    mounted(){
        this.$nextTick(()=> {
            const w = (this.$refs['table-box'].clientWidth - 80) / (this.header.reduce((arr, e, i) => !i? e.flex:  arr + e.flex, 1))
            const options = {
                data: [['']],
                columns: [...this.header].map(e => { return { title: e.title, width: e.flex * w, type: e.type? e.type: 'text', source: e.type == 'dropdown'? e.source: undefined } }), 
                onafterchanges: this.onafterchanges,
                onbeforepaste: this.onbeforepaste,
            }
            this.spreadsheet = jexcel(this.$refs['jexcel-table'], options);
            Object.assign(this, this.spreadsheet);
            this.$socket.emit('get-maschine-config', { token: this.token, maschine: this.maschine, type: this.type })


        })
    },
    sockets:{
        'set-maschine-config':function({type, table}){
            if(type == this.type)
                this.spreadsheet.setData(table.map(r => [...this.header.map(e => r[e.title])]))
            

        }
    },
    methods:{
        onafterchanges(){
            const table = this.spreadsheet.getData()

            const tmp = table.map(row => this.header.reduce( (arr, e, i) => {
                arr[e.title] = row[i]
                return arr;
            }, {})) 
            this.$socket.emit('edit-maschine-config', {token: this.token, maschine: this.maschine, type: this.type, header: this.header, table:tmp})
        },
        onbeforepaste(table, tmp){
            const columns = tmp.split('\t').length
            if(columns == (this.header.length - 1))
                tmp = `-1\t${tmp}`
            return tmp
        },
      
    }
}
</script>