// this store is for values whick are responsible for different display modes
import Vue from 'vue'
import moment from 'moment'
const vm = Vue.prototype;

export default {
    namespaced: true,
    state: {
        
       realtime: false,
       realtimeMaschine: '',
       realtimeEndPoint: '',

    },
    getters: {
       
        
      
    },
    mutations: {
       
        

    },
    actions: {
        'enter-realtime-mode': function({ state, rootState }, maschine){
            // payload contains maschine

            fetch(`${vm.$hostname}/maschines/start-realtime`, {
                    method: 'POST', 
                    headers: { authorization: rootState.token, "Content-Type": "application/json", },
                    body: JSON.stringify({
                        maschine: maschine
                    })
                }).then(async (e) => {
                if(e.status == 200){
                    //const data = await e.json()
                    state.realtime = true;

                    
                    state.realtimeEndPoint = moment.unix(moment().unix() + 5 * 60).format('HH:mm') ;
                    state.realtimeMaschine = maschine.name;

                    setTimeout(() => state.realtime = false, 5 * 60 * 1000)
                }
            })
        }
    }
}