<template>
    <div class="vertical-wrapper">
        <md-progress-bar v-if="showLoader"  md-mode="indeterminate" style="flex: 0 0 5pt"> </md-progress-bar>
        <div v-if="showLoader"></div>

        <div v-if="charts.length" class="horizontal-wrapper" style="flex: 0 0 auto; margin: 0 5pt">
            <h2>{{ globalDashboardName }}</h2>
            <div>

            </div>
            <md-field class="hide">
                <label for="">Sortierung</label>
                <md-select v-model="chartsSortBy">
                    <md-option value="standart">Standart</md-option>
                    <md-option value="alphabetical">Alphabetisch </md-option>
                    <md-option value="increasing">Wert aufsteigend</md-option>
                    <md-option value="decreasing">Wert absteigend</md-option>
                </md-select>
            </md-field>
        </div>
        <div v-if="charts.length" class="panel" style="overflow-y: scroll; flex: 1 0 0;">

            <charts v-for="(chart, i) in charts" :key="chart.html_id" 
                :id="chart.html_id" :number="i" 
                :chart="chart" ref="chart"
                :maschine="{ id: chart.maschineId, name: chart.name.split(':')[0] }" 
                :class="{ 'edit': chart.edit }" 
                :edit="editMode"

                
                :self-request="true"
                :extra-controls="false"
                
            ></charts>

            
            <!--
                v-on:reorder-chart="graphReorder"
                v-on:item-clicked="graph_clicked(chart)" v-on:change-date="changeChartDates" 
                
            -->    

        </div>
        <div v-if="table.length" class="vertical-wrapper" ref="table-wrapper">
            <md-table v-model="table" :md-sort.sync="tableSortBy" :md-sort-order.sync="tableSortOrder" md-card md-fixed-header :md-height="'auto'" :md-sort-fn="customSort">
                <md-table-toolbar>
                    <h1 class="md-title">{{ globalDashboardName }}</h1>
                </md-table-toolbar>

                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }} [{{ item.unit  }}]</md-table-cell>

                    <md-table-cell md-label="Wert" md-sort-by="value">{{ item.value.join(', ') }}</md-table-cell>
                    <md-table-cell md-label="MIN" md-sort-by="min" md-numeric>{{ item.min.join(', ') }}</md-table-cell>
                    <md-table-cell md-label="MAX" md-sort-by="max" md-numeric>{{ item.max.join(', ') }}</md-table-cell>
                    <md-table-cell md-label="AVG" md-sort-by="avg" md-numeric>{{ item.avg.join(', ') }}</md-table-cell>
                    
                    <md-table-cell md-label="Optionen">
                        <md-button v-on:click="$router.push({name : 'my_maschine', params: {id : item.maschineId, name: item.maschineName }})">Maschine</md-button>
                        <md-button v-on:click="openChartDialog(item)">Chart</md-button>

                    </md-table-cell>

                </md-table-row>
            </md-table>
        </div>
        <router-view></router-view>
    </div>
</template>
<script>
import charts from '@/components/charts.vue'

export default {
    name: 'global-dashboard-view',
    props: { id: [String, Number]},
    components: {
        'charts':charts
    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        editMode(){
            return this.$store.getters.getEditorMode
        },
        globalDashboardName(){
            const m = this.$store.getters.getGlobalDashboards.find(e => e.id == this.id)
            if(m)
                return m.name
            else
                return ''

        }
    },
    sockets:{
        'set-global-dashboard-charts': function({ charts }){
            this.showLoader = false

            this.table = []


            charts.forEach(e => {
                e.key = this.$uuid.v4()
                e.edit = false;
            });
            this.charts = charts
        },
        'set-global-dashboard-table': function ({ table }) {
            this.showLoader = false

            this.charts = []
            table.forEach(e => {
                e.key = this.$uuid.v4()
                e.edit = false;

                e.unit = e.unit.join(', ')

            });
            this.table = table
        },
        
    },
    methods: {
        customSort(value) {
            const sortBy = this.tableSortBy
            return value.sort((a, b) => {
                if (typeof a[sortBy] == 'string' && this.tableSortOrder === 'desc') {
                    return a[sortBy].localeCompare(b[sortBy])
                } else if(typeof a[sortBy] == 'string'){
                    return b[sortBy].localeCompare(a[sortBy])
                } else if(Array.isArray(a[sortBy]) && this.tableSortOrder === 'asc'){
                    return Math.max(...a[sortBy]) - Math.max(...b[sortBy])
                } else if (Array.isArray(a[sortBy]) && this.tableSortOrder === 'desc') {
                    return  Math.min(...b[sortBy]) - Math.min(...a[sortBy]) 
                }


            })
        },
        openChartDialog(item){
            this.$router.push({ name : 'main-global-dashboard-chart-details', params: { id: this.id, 'chartId': item.id, maschineId: item.maschineId, type: item.type, html_id: item.html_id }})
        } 
    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.$socket.emit('get-global-dashboard-charts', { token: vm.token , IDh_global_db: vm.id})
            vm.showLoader = true
            vm.charts = []
            vm.table = []

            // set all menu opener values to false after entering a new maschine
            vm.$store.commit('display/set-show-maschines', false)
            vm.$store.commit('display/set-show-kategories', false)
            vm.$store.commit('display/set-burger-active', false)



        })
    },
    beforeRouteUpdate(to, from, next){
        this.$socket.emit('get-global-dashboard-charts', { token: this.token, IDh_global_db: to.params.id })
        this.showLoader = true
        this.charts = []
        this.table = []

        // set all menu opener values to false after entering a new maschine
        this.$store.commit('display/set-show-maschines', false)
        this.$store.commit('display/set-show-kategories', false)
        this.$store.commit('display/set-burger-active', false)


        next()
    },
    data(){
        return{
            showLoader: false,

            charts: [],
            table: [],
            chartsSortBy: 'standart',


            tableSortBy: 'name',
            tableSortOrder: 'asc'
        }
    }
}
</script>
<style>
.md-speed-dial .md-primary{
    background-color: var(--color3);
}
.md-speed-dial .md-primary svg{
    color: white;
}
</style>