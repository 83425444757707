<template>
    <div>
        <md-table :value="searchedAndSorted" :md-sort.sync="sortAfter" :md-sort-order.sync="softAfterASC" md-card :md-height="600"
            md-fixed-header>
            <md-table-toolbar>
                <div class="md-toolbar-section-start">
                    <h1 class="md-title">{{name}}</h1>
                </div>
                <div class="horizontal-wrapper">
                    <md-field md-clearable class="md-toolbar-section-end">
                        <md-input placeholder="Search by name" v-model="search" />
                    </md-field>
                    <md-field style="flex: 0 0 auto; width: auto; margin-right: 10pt;" :number="number">
                        <label for="">Source</label>
                        <md-select v-model="sourceFilter">
                            <md-option v-for="s in sources" :key="s.key" :value="s.value">{{s.value}}</md-option>
                        </md-select>
                    </md-field>
            
                </div>
            </md-table-toolbar>
            <md-table-row class="" slot="md-table-row" slot-scope="{item}" v-on:click="e => valueClick(e, item)">
                <md-table-cell md-label="type" md-sort-by="source"  style="flex: 0 0 auto;">
                    <md-icon v-if="item.source == 'data'" :md-src="require('../../images/values-table-icons/link-solid.svg')">data</md-icon>
                    <md-icon v-if="item.source == 'calc'" :md-src="require('../../images/values-table-icons/square-root-variable-solid.svg')">calc</md-icon>
                    <md-icon v-if="item.source == 'control'" :md-src="require('../../images/values-table-icons/hand-solid.svg')">control</md-icon>
                    <md-icon v-if="item.source == 'hand'" :md-src="require('../../images/values-table-icons/flask-vial-solid.svg')">hand</md-icon>

                </md-table-cell>
                <md-table-cell md-label="Name" md-sort-by="name" style="flex: 1 0 0;">{{item.name}}</md-table-cell>
                <md-table-cell md-label="Einheit" md-sort-by="unit" style="flex: 1 0 0;">{{item.unit}}</md-table-cell>

                <md-table-cell md-label="Wert" md-sort-by="value" style="flex: 1 0 0; padding: 10pt">
                    <div v-if="!item.editValue" class="md-table-cell-value">{{item.value}}</div>
                    <div v-else class="horizontal-wrapper">
                        <md-field style="width: 70px; margin: 0; padding: 0;">

                            <md-input style="text-align: center" type="text" @blur="valueEnter(item)" v-on:keyup.enter="valueEnter(item)" ref='openValueEditor' v-model="item.value"/>
                        </md-field>
                    </div>
                </md-table-cell>
                <md-table-cell md-label="Updated" md-sort-by="lastentry" style="flex: 1 0 0">{{item.lastentry}}</md-table-cell>
                <md-table-cell md-label="Actions" md-sort-by="" style="flex: 1 0 0">
                    <md-button class="md-icon-button">
                        <md-icon :md-src="require('../../images/values-table-icons/pen-solid.svg')"></md-icon>
                    </md-button>
                    <md-button class="md-icon-button">
                        <md-icon :md-src="require('../../images/values-table-icons/bell-solid.svg')"></md-icon>
                    </md-button>
                    <md-button class="md-icon-button">
                        <md-icon :md-src="require('../../images/values-table-icons/table-cells-solid.svg')"></md-icon>
                    </md-button> 
                </md-table-cell>
            
            </md-table-row>
        </md-table>
    </div>
</template>
<script>
export default{
    name: 'values-table',
    props: { 'values-table': Array, 'table-height': { type: Number, default: 600 }, name: { type: String, default: 'Datenpunkte'}, maschine: Object, options:Object },
 
    computed:{
        searchedAndSorted(){
            const table = this.valuesTable.filter(e => (e.source == this.sourceFilter || this.sourceFilter == 'Alle') && e.name.includes(this.search))
            if(this.softAfterASC == 'asc')
                return table.sort((a, b) => a[this.sortAfter].localeCompare(b[this.sortAfter]))
            else
                return table.sort((a, b) => b[this.sortAfter].localeCompare(a[this.sortAfter]))

        },
        token(){
            return this.$store.getters.token
        }
    },
    methods:{
        valueClick(e, cell){
            if (!e.target.classList.contains('md-table-cell-container') && !e.target.classList.contains('md-table-cell-value') )
                return
            if(!this.options.editable)
                return

            cell.editValue = true
            this.$nextTick(() => {
                if (this.$refs.openValueEditor)
                    this.$refs.openValueEditor.$el.focus()
            })
        },
        valueEnter(cell){
            this.$socket.emit('set-data-point', {token: this.token, maschine: this.maschine, vID: cell.vID, value: cell.value})
            cell.editValue = false;

        },
        createSources(){
            const sources = this.valuesTable.reduce((arr, item) => {
                if (!arr.some(e => e.value == item.source)){
                    arr.push({ value: item.source, key: this.$uuid.v4() })
                }

                return arr;
            }, [{ value: 'Alle', key: this.$uuid.v4() }])
            this.sources = sources
            
            this.$forceUpdate()

        }
    },
    mounted(){
        this.createSources()

        this.requestInverval = setInterval(() => {
            this.valuesTable.forEach(e => this.$emit('get-data-point', { key: e.key, vIDs: [e.vID] }))
           
        }, 1000)
    },
    destroyed(){
        if(this.requestInverval)
            clearInterval(this.requestInverval)
    },
    data(){
       
        return{
            sortAfter: 'name',
            softAfterASC: 'asc',

            search: '',

            sources: [{ value: 'Alle', key: this.$uuid.v4() },],
            sourceFilter: 'Alle',
            number: 0,

            requestInverval: 0,
        }
    }
}
</script>

<style>
@media screen and (max-device-width: 800px) {
    /*für mobil*/
    .md-table-cell-container{
        padding: 3px;
    }
}
</style>