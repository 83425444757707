// this store is for values whick are responsible for different display modes

export default {
    namespaced: true,
    state: {
        editorMode: false,
        showMaschines: false,
        showKategories: false,

        burgerActive: false,


        showRealtimeOption: false,
        

    },
    getters: {
        getEditorMode(state) {
            return state.editorMode;
        },
        getShowMaschines(state) {
            return state.showMaschines
        },
        getShowKategories(state) {
            return state.showKategories
        },
    },
    mutations: {
        'set-editor-mode': function (state, editorMode) {
            state.editorMode = editorMode;
        },
        'set-show-maschines': function (state, showMaschines) {
            state.showMaschines = showMaschines
        },
        'set-show-kategories': function (state, showKategories) {
            state.showKategories = showKategories
        },
        'set-burger-active': function(state, active){
            state.burgerActive = active
        },
        'set-realtime-option': function(state, active){
            state.showRealtimeOption = active;
        }

    }
}