var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vertical-wrapper"},[_c('div',{staticClass:"header horizontal-wrapper",staticStyle:{"flex":"0 0 auto"}},[_c('h4',[_vm._v("Syncronisiert")]),_c('div',{staticClass:"icon",style:(`flex: 0 0 20pt; cursor:pointer; margin: 5pt; height: 20pt; background-image: url(${require('../../images/upload-solid.svg')})`),on:{"click":_vm.readConfig}}),_c('div',{staticClass:"icon",style:(`flex: 0 0 20pt; cursor:pointer; margin: 5pt; height: 20pt; background-image: url(${require('../../images/download-solid.svg')})`),on:{"click":_vm.writeConfig}})]),_c('maschine-config-table',{attrs:{"maschine":_vm.maschine,"title":'S7 GET',"type":'s7get',"header":[
        { title: 'id', flex: 0, type:'hidden' }, 
        
        { title: 'valuename', flex: 3 }, 
        { title: 'unit', flex: 1 }, 
        { title: 'ip', flex: 1 }, 
        { title: 'db', flex: 1}, 
        { title: 'offset', flex: 1}, 
        { title: 'datatype', flex: 1, type: 'dropdown', source: ['int', 'real', 'dint', 'bit']}]}}),_c('maschine-config-table',{attrs:{"maschine":_vm.maschine,"title":'AnoPI',"type":'anopi',"header":[
            { title: 'id', flex: 0, type: 'hidden' }, 
        
            { title: 'valuename', flex: 3 }, 
            { title: 'unit', flex: 1 }, 
            { title: 'offset', flex: 1 }, 
            { title: 'messurement', flex: 1, type: 'dropdown', source: ['4-20mA', '0-20mA', '0-10V', '2-10V'] }, 
            { title: 'min', flex: 1 }, 
            { title: 'max', flex: 1 }]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }