<template>
  <div class="maschine-overview" >
    <div :class="{'horizontal_wrapper':!$isMobile(), 'vertical_wrapper':$isMobile()}">
      <div class="overall_view">
        <div class="maschine-name">
          <div class="vertical_wrapper names">
            <p class="maschinename">{{name}}</p>
            <p class="last-message">last message: {{status_maschine.last_message}}</p>
          </div>
          <div v-if="!$isMobile()" class="vpn-button" v-on:click="open_vpn">
            <p>VPN</p>
            <svg viewBox="0 0 12 8">
              <path d="M 2 3 l 4 0 l 0 -1 l 4 2 l -4 2 l 0 -1 l -4 0 l 0 -2" />
            </svg>
          </div>
        </div>


        <div class="vertical-wrapper">
          <div v-for="ma in maschineAttributes" :key="ma.key" class="horizontal-wrapper" style="flex: 0 0 auto; margin: 5pt">
            <p style="flex: 1 0 0; text-align: left;">{{ma.name}}:</p>
            <p style="flex: 1 0 0">{{ma.value}}</p>
            <p style="flex: 2 0 0"></p>
          </div>
        </div>
        <div v-if="status" class="horizontal-wrapper" style="flex: 0 0 auto;">
          <div>
            Virtual IP:<br>
            {{ status.ip }}
          </div>
          <div>
            Online: <br>
            {{ status.online }}
          </div>
          <div>
            Updated: <br>
            {{ $moment(status.updated).format('YYYY-MM-DD HH:mm:ss') }}
          </div>
        </div>
      </div>
      <div class="maschine_log">
        <div class="table_wrapper">
          <md-table v-model="filteredMaschineLogItems" md-sort="name" md-sort-order="asc" md-card md-fixed-header>
            <md-table-toolbar class="horizontal-wrapper">
              <p class="md-title">Maschine Log</p>
              <div style="flex: 1 0 0"></div>
              <md-field class="width: auto; flex: 1 0 0;">
                <label for="">Search</label>
                <md-input v-model="searchLogText"></md-input>
              </md-field>
            </md-table-toolbar>


            <md-table-row slot="md-table-row" slot-scope="{ item }" v-on:click="openLogEditor(item)">
              <md-table-cell md-label="Name" md-sort-by="name">{{item.name}}</md-table-cell>
              <md-table-cell md-label="Type" md-sort-by="type">{{item.type}}</md-table-cell>
              <md-table-cell md-label="Date" md-sort-by="date">{{$moment(item.date).format('YYYY-MM-DD HH:mm')}}
              </md-table-cell>
              <md-table-cell md-label="Info" md-sort-by="info">{{item.info}}</md-table-cell>
            </md-table-row>

          </md-table>

        </div>
        <div class="button_wrapper">
          <button class="add_button" v-on:click="open_row_editor(null)"> + </button>
        </div>

      </div>
    </div>
    <div class="warning_frame_messages">
      <message-frame v-for="(mf, i) in messageFrames" :key="'mf' + i" :message-frame="mf" />
    </div>
    <p class="p-header" style="margin: 5pt;">Kategories:</p>
    <div class="kategories-fast" :class="{'vertical_wrapper':$isMobile(), 'horizontal_wrapper':!$isMobile()}"
      style="margin: 5pt;">
      <md-button v-for="item in kategories" :key="item.id" v-on:click="$router.push({ name: 'kategorie-view', params: {id: id, name: name, kid: item.id, kategorie: item.text}})"
        class="md-primary">{{item.text}}</md-button>
    </div>


    <md-dialog :md-active.sync="maschine_log_editor_open" class="md-dialog-grid">
      <md-dialog-actions>
        <md-button class="md-primary" @click="close_row_editor">Close</md-button>
        <md-button class="md-primary" @click="save_log_editor">Save</md-button>
      </md-dialog-actions>
      <md-dialog-title>Maschinen Log</md-dialog-title>
      <div class="md-dialog-body vertical_wrapper" style="margin: 15pt; min-height: 230pt; "
        :style="`${($isMobile())? '': `min-width: 450pt`}`">
        <div class="horizontal-wrapper res" style="flex: 0 0 auto">
          <md-field style="width: auto; flex: 2 0 0;">
            <label for="">Info</label>
            <md-input v-model="maschineLogItem.info"></md-input>
          </md-field>
          <div class="horizontal-wrapper" style="flex: 1 0 auto">
            <div style="flex: 1 0 15pt"></div>
            <datetime class="size14" type="datetime" style="flex: 0 0 auto; margin: auto; font-size: 20pt;"
              v-model="maschineLogItem.date" />
            <div :style="$isMobile() ? `flex: 1 0 15pt` : `flex: 0 0 0pt`"></div>

          </div>

        </div>

        <div>
          <md-field>
            <label for="">Text</label>
            <md-textarea v-model="maschineLogItem.text"></md-textarea>
          </md-field>
        </div>

      </div>

    </md-dialog>

  </div>
</template>
<script>
import message_frame from '@/components/message-frame.vue'

export default {
    name: 'maschine-overview',
    components: { 'message-frame': message_frame },
    props: {  id:[Number, String], name:String,  },
    methods: {
        open_row_editor(row) {
            this.maschine_log_items.forEach(e => e.open = false)
            if (row)
                row.open = true
            else
                this.maschine_log_items.push({ id: -1, date: this.$moment().format('YYYY-MM-DDTHH:mmZ'), info: '', text: '', open: true })


            this.maschine_log_editor_open = true;
        },
        close_row_editor() {
            this.maschine_log_editor_open = false;
            this.$socket.emit('load_event_log_table', { token: this.token, maschine: { id: parseInt(this.id) } });
            //load new one to remove not saved new entries

        },
        save_log_editor() {
            this.maschine_log_items.forEach(e => e.open = false)

            this.$socket.emit('save_event_log_table', {
                token: this.token,
                maschine: { id: parseInt(this.id) },
                table: this.maschine_log_items
            })
            this.maschine_log_editor_open = false;

        },
        open_vpn() {
            this.$socket.emit('open-vpn-connection', { token: this.token, maschine: {id: this.id} })
        },
        openLogEditor(item) {
            this.maschine_log_items.forEach(e => e.open = false)
            item.open = true
            this.maschine_log_editor_open = true
        },

        updateMaschineOverview(maschineId){
          console.log(maschineId);

          this.$socket.emit('get_maschine_status', { token: this.token, maschine: { id: maschineId } });
          this.$socket.emit('load_event_log_table', { token: this.token, maschine: { id: maschineId } });
          this.$socket.emit('get-maschine-attributes', { token: this.token, maschine: { id: maschineId } });
          this.$socket.emit('get-maschine-vpn-status', { token: this.token, maschine: { id: maschineId } });
          this.$emit('clear-maschine', '')
        }
    },
    sockets: {
        show_event_log_table({ table }) {
            table.forEach((e, i) => { e.date = this.$moment(e.date).format('YYYY-MM-DDTHH:mmZ'); e.key = this.$uuid.v4(); e.open = this.maschine_log_items[i] ? this.maschine_log_items[i].open : false; })
            this.maschine_log_items = table
        },
        'set-maschine-attributes':function(attributes){
          attributes.forEach(e => e.key = this.$uuid.v4())
          this.maschineAttributes = attributes
        },
        'set-maschine-vpn-status': function(status){
          console.log(status);
          this.status = status
        }
    },
   
    beforeRouteEnter(from, to, next){
        next(vm => {
            vm.updateMaschineOverview(parseInt(vm.id))
           
        })
    },
    beforeRouteUpdate(to, from, next){
      this.updateMaschineOverview(parseInt(to.params.id))
      next()
      return true
    },
    data() {
        return {
            maschine_log_editor_open: false,
            maschine_log_item: { id: -1, info: '', datetime: new Date().toISOString(), text: '' },
            maschine_log_items: [],
            searchLogText: '',
            status_maschine: {
                id: -1,
                name: 'Imperium',
                connect: 1,
                last_message: '300 s'
            },
            logs: [
                { info: 'Unit Started', time: new Date(), text: 'lorem ipsum' },
                { info: 'Unit Stopped', time: new Date(), text: 'lorem ipsum' },

            ],
            maschineAttributes: [],
            status: 0,
        }
    },
    created() {
        this.$dictonary.add('overview', 'Übersicht')
    },
    computed: {
        token() {
            return this.$store.getters.token
        },
        maschineLogItem: {
            get() {
                return this.maschine_log_items.find(e => e.open) ? this.maschine_log_items.find(e => e.open) : {}
            },
            set(item) {
                const m = this.maschine_log_items.find(e => e.open);
                if (m) {
                    m.date = item.date;
                    m.info = item.info;
                    m.text = item.text;
                }
            }
        },
        filteredMaschineLogItems: {
            get() {
                return this.maschine_log_items.filter(e => e.info.toLowerCase().includes(this.searchLogText.toLowerCase()))
            },
            set(list) {
                this.maschine_log_items = list
            }
        },
        kategories(){
            return this.$store.getters.getKategories;
        },
        messageFrames(){
            return this.$store.getters.getMessageFrames;
        }
    },
}
</script>
<style>

.vdatetime.size14 input{
  font-size: 14pt;
}
.maschine-overview{
  display: flex;
  flex-direction: column;
}
.maschine-overview > .horizontal_wrapper{
  flex: 0 0 400pt;
}
.vertical_wrapper{
  display: flex;
  flex-direction: column;
}

.overall_view{
  padding: 5pt;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  border: 2pt solid var(--color5);
  margin: 5pt;
  background-color: var(--color1);

}
.overall_view .maschinename{
  margin-bottom: 0;
  margin-top: 5pt;
  font-size: var(--font2);
  text-align: left;
}
.overall_view .last-message{
  font-size: var(--font0);
  text-align: left;

}
.overall_view .maschine-name{
  flex: 0 0 auto ;
  display: flex;
  flex-direction: row;
}
.overall_view .maschine-name .names{
  flex: 1 0 0;
}
.overall_view .vpn-button{
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  cursor: pointer;
}
.overall_view .vpn-button p{
  flex: 1 0 0 ;
  margin: auto;

}
.overall_view .vpn-button svg{
  height: 28pt;
  margin: auto;
  flex: 1 0 0 ;
}
.overall_view .vpn-button .horizontal_wrapper > div{
  width: auto;
}
.maschine_log{
  flex: 1 0 0;
  border: 2pt solid var(--color5);
  margin: 5pt;
  display: flex;
  flex-direction: column;
}
.maschine_log p{
  font-size: 20pt;
  margin-top: 5pt;
  margin-bottom: 0;
  text-align: left;
}
.maschine_log .table{
  flex: 1 0 0;
}
.maschine_log .button_wrapper{
  flex: 0 0 auto;

  display: flex; 
  flex-direction: row-reverse;
}
.maschine_log .table_wrapper{
  flex: 1 0 0;
}
.maschine_log .button_wrapper .add_button{
  flex: 0 0 calc(20px + 25pt);
  margin-right: 5pt;
  margin-bottom: 5pt;

  border-radius: 50%;
}
.maschine_log_editor{
  display: fixed;

}
.maschine_log_editor{
  position: fixed;

  background-color: var(--color7);
  z-index: 20;
  top: 0 ;
  left: 0;
  display: flex;

  width: 100vw ;
  height: 100vh;

}
.maschine_log_editor.hide{
  display: none;
}
.maschine_log_editor > div{
  background-color: var(--color4);
  flex: 1 0 0;
  margin: 12.5%;

  display: flex;
  flex-direction: column;
}
.maschine_log_editor .info_wrapper{
  display: flex;
  flex-direction: row;
}
.maschine_log_editor label{
  text-align: left;
  padding: 5pt;
}
.maschine_log_editor label{
  font-size: var(--font1);
}
.maschine_log_editor label input{
  font-size: var(--font1);
}
.maschine_log_editor label textarea{
  resize: none;
  flex: 1 0 0;
  font-size: var(--font1);
}
.maschine_log_editor .info_wrapper label.info{
  flex: 5 0 0;

}
.maschine_log_editor .info_wrapper label.date{
  flex: 1 0 0;
}
.maschine_log_editor label{
  display: flex;
  flex-direction: column;

}
.maschine_log_editor label.moretext{
  flex: 1 0 0;

}
.maschine_log_editor .placeholder{
  flex: 1 0 0;
}
.maschine_log_editor .button_wrapper{
  display: flex;
  flex-direction: row;
  
}
.maschine_log_editor .button_wrapper button{
  margin-right: 20pt;
  margin-bottom: 5pt;
  flex: 0 0 150pt;
}
.kategories-fast > button{
  flex: 0 0 auto;
  margin: 10pt;
}

p.p-header{
  font-size: var(--font2);
  font-weight: bold;
  text-align: left;
}
@media screen and (max-device-width: 800px) {/*für mobil*/
  .vertical_wrapper{
    flex-direction: column;
    flex: 1 0 0;
  }
  .kategories-fast > button.md-button{
    flex: 0 0 auto;
    width: 70%;
    margin: 5pt auto;
  }

  .maschine_log_editor div{
    margin: 0;
  }
  .maschine_log_editor .button_wrapper{
    order: 0;
  }
  .maschine_log_editor .button_wrapper button{
    margin-right: 0;
    flex: 0 0 35pt;
    height: 30pt;
    border: none;
    background-color: var(--color4);
  }
  .maschine_log_editor .button_wrapper .cancel{
    order: 0;

    background-size: contain;
    background-position: center;
  }
  .maschine_log_editor .button_wrapper .placeholder{
    order: 1;
    flex: 2 0 0;  
  }
  .maschine_log_editor .button_wrapper .ok{
    order: 2;
  }

  .maschine_log_editor .info_wrapper{
    flex-direction: column;
    order: 1;
  }
  .maschine_log_editor label.moretext{
    order: 2
  }
  .maschine-overview .vertical_wrapper{
    flex: 0 0 250pt;
  }
  .maschine-overview .overall_view{
    flex: 0 0 auto;
  }

}
</style>