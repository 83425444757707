import { render, staticRenderFns } from "./settingsView.vue?vue&type=template&id=36204304"
import script from "./settingsView.vue?vue&type=script&lang=js"
export * from "./settingsView.vue?vue&type=script&lang=js"
import style0 from "./settingsView.vue?vue&type=style&index=0&id=36204304&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports