<template>
    <md-dialog :md-active="show" class="" v-on:md-closed="$emit('close')" v-on:md-clicked-outside="$emit('close')">
        <md-dialog-title>Graph Segmente</md-dialog-title>

        <div class="vertical-wrapper" style="padding: 60pt 20pt; min-height: 50vh;">
            <div class="horizontal-wrapper" style="flex: 0 0 auto" v-for="(gs, i) in graphSegments" :key="i + $uuid.v4()">
                <md-field style="flex: 1 0 0; margin:2.5pt;">
                    <label>Name</label>
                    <md-input :value="graphSegments[i].name" v-on:change="e => gs.name = e.target.value"></md-input>
                </md-field>

                <md-switch class="md-primary" v-model="gs.showStart" v-on:change="gs.start = new Date().toISOString()">Start</md-switch>
                <datetime style="margin: auto 7.5pt;" type="datetime" title="start" :class="{'hide':!gs.showStart}" v-model="gs.start"/>

                <md-switch class="md-primary" v-model="gs.showEnd" v-on:change="gs.end = new Date().toISOString()">End</md-switch>
                <datetime style="margin: auto 7.5pt;" type="datetime" title="end" v-if="gs.showEnd" v-model="gs.end"/>



                <div class="action_button delete" style="border:none; margin-top: 20pt" v-on:click="deleteGraphSegment(gs)">
                    <div class="icon" ></div>
                </div>
            </div>
        </div>
        <div class="horizontal-wrapper">
            <div></div>
            <md-button class="md-primary md-raised" v-on:click="addSegment">add</md-button>
        </div>
        <md-dialog-actions>
            <md-button class="md-primary" @click="$emit('close')">Close</md-button>
            <md-button class="md-primary" @click="saveGraphSegements()">Save</md-button>
        </md-dialog-actions>
    </md-dialog>
</template>
<script>
export default {
    name: 'graphs-segments-dialog',
    props: {show:Boolean, maschine:Object, chart:Object},
    watch:{
        show(){
            if(this.show){
                this.$socket.emit('get-graph-segments', {token: this.token, maschine:this.maschine, chart:this.chart})
            }
        }
    },
    computed:{
        token(){
            return this.$store.getters.token
        }
    },
    methods:{
        saveGraphSegements(){
            console.log(this.graphSegments[1].start, this.$moment(this.graphSegments[1].start).format('YYYY-MM-DD HH:mm'));
            this.graphSegments.forEach(e => { e.start = e.showStart? this.$moment(e.start).format('YYYY-MM-DD HH:mm'): null; e.end = e.showEnd? this.$moment(e.end).format('YYYY-MM-DD HH:mm'): null;})
            this.$socket.emit('edit-graph-segments', {token: this.token, maschine:this.maschine, chart:this.chart, graphSegments:this.graphSegments})
            this.$emit('close')
        },
        addSegment(){
            this.graphSegments.push({id: -1, name:'', start: null, end: null, forall: false, })
        },
         
        deleteGraphSegment(gs){
            this.$swal.fire({
                title: 'Sure?', 
                text:'Do you want to remove ' + gs.name + '?',
                icon: 'question',
                showDenyButton: true,
                denyButtonText: 'no',
                showConfirmButton: true,
                confirmButtonText: 'yes',
            }).then( e => {
                if(e.isConfirmed){
                    this.$socket.emit('delete-graph-segments', {token: this.token, maschine:this.maschine, chart:this.chart, graphSegment: gs})
                }
            })
        }
    },
    sockets:{
        'set-graph-segments':function({graphSegments}){
            graphSegments.forEach(e => {e.showStart = e.start? true: false; e.showEnd = e.end? true: false});
            this.graphSegments = graphSegments;
        }
    },
    data(){
        return {
            graphSegments: []
        }
    }
}
</script>