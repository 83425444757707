/* eslint-disable no-console */


import { register } from 'register-service-worker'
import Vue from 'vue'

export default function (STORE){
  
  if (process.env.NODE_ENV === 'production') {

    register(`${process.env.BASE_URL}service-worker.js`, {
      ready (registration) {
        console.log(
          'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
        )
        initServiceWorker(registration)
      },
      registered () {
        console.log('Service worker has been registered.')
      },
      cached () {
        console.log('Content has been cached for offline use.')
      },
      updatefound () {
        console.log('New content is downloading.')
      },
      updated () {
        console.log('New content is available; please refresh.')
      },
      offline () {
        console.log('No internet connection found. App is running in offline mode.')
      },
      error (error) {
        console.error('Error during service worker registration:', error)
      }
    })
  }
  
  
  function isPushManagerActive(pushManager) {
    if (!pushManager) {
      if (!window.navigator.standalone) {
        //document.getElementById('add-to-home-screen').style.display = 'block';
        STORE.commit('set-push-manager-status', 'not active')
        
      } else {
        STORE.commit('set-push-manager-status', 'error')
        
      }
      return false;
    } else {
      return true;
    }
  }
  
  
  // 
  /**
   * initServiceWorker: start init when service worker is registered
   * 
   *  
   * @param {*} swRegistration 
   * @returns 
   */
  // 
  async function initServiceWorker(swRegistration) {
    let pushManager = swRegistration.pushManager;
    
    // first get if there is push manager is available 
    // e.g. 
    // IOS: is installed and available
    // Android: ?
    if (!isPushManagerActive(pushManager)) {
      return;
    }

    // get the permission of the push manager
    let permissionState = await pushManager.permissionState({userVisibleOnly: true});
    switch (permissionState) {
      case 'prompt':
        subscribeToPush()
        break;
      case 'granted':
        pushManagerIsGranted(pushManager)
        break;
      case 'denied':
        STORE.commit('set-push-manager-registration', {})
        STORE.commit('set-push-manager-status', 'denied')
    }
  }
 
  // push manager is granted
  // first check if there is already a push subscription and if not register new one
  async function pushManagerIsGranted(pushManager){

    // get existing push subscription
    let subscription = await pushManager.getSubscription()

    // if exisiting push subscription is null register new one
    if(!subscription){
      subscribeToPush()
      return 
    }

    // save push subscription in the store
    STORE.commit('set-push-manager-status', 'registered')
    STORE.commit('set-push-manager-registration', subscription)

    STORE.dispatch('push-manager-registration')

  }

  // subscribe to push manager 
  async function subscribeToPush() {
    // Public part of VAPID key, generation of that covered in README
    // All subscription tokens associated with that key, so if you change it - you may lose old subscribers
    const VAPID_PUBLIC_KEY = 'BFvnmQ_Oy1C3tvBf66wTHmGU1wZtOa6LONHwb5k2k_O2kXhiCh-lIXyTf3tUFs17Cx451WMMOfk6yf8sgLJYHVo';
    
    let swRegistration = await navigator.serviceWorker.getRegistration();
    if(!swRegistration){
      throw new Error('Register Failed Service Worker not registered');
    }
    
    let pushManager = swRegistration.pushManager;
    if (!isPushManagerActive(pushManager)) {
      throw new Error('Push Manager not available');
    }
    let subscriptionOptions = {
      userVisibleOnly: true,
      applicationServerKey:  VAPID_PUBLIC_KEY
    };
    try {
      let subscription = await pushManager.subscribe(subscriptionOptions);
      // save push subscription in the store
      STORE.commit('set-push-manager-status', 'registered')
      STORE.commit('set-push-manager-registration', subscription)
      
      STORE.dispatch('push-manager-registration')
      return

    } catch (error) {
      STORE.commit('set-push-manager-registration', null)
      STORE.commit('set-push-manager-status', 'User denied push permission')
      throw new Error('User denied push permission')
    }
    
  }

  Vue.prototype.$subscribeToPushManager = subscribeToPush
}