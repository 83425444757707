<template>
  <div class="user-settings vertical-wrapper">
    <div class="vertical-wrapper">
      <div class="header" style="flex: 0 0 auto">
        <list :items="header_list" class="navbar" v-on:item-clicked="navigateTo"/>
        <burger class="font42pt" :value="true" v-on:close="close"/>
      </div>
      <div class="body vertical-wrapper" style="flex: 1 0 0" >
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import burger from '@/components/VueComponents/burger.vue'
import list from '@/components/VueComponents/list.vue'


export default {
  name:'user-settings',
  props: ['id', 'name'],
  components:{
    burger, list, 
  
  },
  methods:{
    navigateTo(e){
        this.$router.push({name: ('settings'+ e.text)})
    },
    close(){
        //this.$router.go(-1)
        let goBackRoute = this.$router.currentRoute.path
        // remove every element after the /settings* route
        const routeChunks = goBackRoute.split('/settings')
        if(routeChunks.length)
          this.$router.push(routeChunks[0]);
        else{
          this.$router.push({ name: 'main-view'})
        }
      
        
    }
  },
  data(){
    return{
      header_list: [
          {id: 1, text: 'User', active:true},
          {id: 2, text: 'Groups', active:false},
          {id: 3, text: 'Maschines', active:false}        
      ]
    }
  }
}
</script>
<style>
.user-settings{
  position: fixed;

  background-color: var(--color7);
  height: 100%;
  width: 100%;
  z-index: 20;

  top: 0;
  left: 0;
  
  

}
.user-settings > div{
  background-color: #fafafa;
  flex: 1 0 0;
  margin: 2.5%;
}
.user-settings .burger{
  margin: 0;
}
.user-settings .header{
  display: flex;
  flex-direction: row;
}
.user-settings .header .navbar{
  flex: 1 0 0;
}
.user-settings .header .navbar li{
  margin-top: 10pt;
  font-size: 26pt;
}

</style>