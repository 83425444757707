<template>
  <div class="vpn-status-bar" :class="{'show-controls':controls.length, 'hide':!visible}">
    <div class="horizontal_wrapper">
      <div class="icons" :class="{'nothing':status==0, 'success':status==1, 'loading':status==2, 'error':status==3}">
 
      </div>

      <div class="status">
        {{status_text}}
      </div>
    </div>
    <div class="horizontal_wrapper yes_no" :class="{'hide':!controls.length}">
      <div v-for="c in controls" :key="c.name" :class="(c.class)? c.class: c.name" v-on:click="c.click()">{{c.name}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'vpn-status-bar',
  props:{ open:Boolean },
  methods:{
    stop_vpn(){
      fetch(`http://127.0.0.1:${this.vpn_interface_port}/close-vpn-connection`,{
          method: 'POST',
          body: ``,
        }).then(() => {
          this.status = 6;
          this.status_text = "closing vpn connection"
          this.controls = []
        })
        .catch(() =>{
          //no vpn interface or wrong port
          this.status = 3
          this.status_text = 'no vpn interface found'
          const my_element = this;
          this.controls = [ 
                {name:'install', class: 'yes', click(){
                  console.log('installing interface');
                }}, 
                {name: 'no thanks', class:'no', click:function(){
                  my_element.visible = false;
                  my_element.status = 0;
                }} ]
        });
    }
  },
  sockets:{
    'try-opening-vpn':function({vpn_server_port, vpn_autorization}){
      /*if(//cookie exists)
        vpn_interface_port = //get cookie
      */

      this.visible = true
      this.status = 2
      this.status_text = 'establish connection to vpn interface'
      fetch(`http://127.0.0.1:${this.vpn_interface_port}/open-vpn-connection`,{
        method: 'POST',
        body: `{ "port": ${vpn_server_port}, "key": "${vpn_autorization}"}`,
      }).then(() => {
        this.status = 4;
        this.status_text = "starting vpn connection"
        this.controls = []
      })
      .catch(() =>{
        //no vpn interface or wrong port
        this.status = 3
        this.status_text = 'no vpn interface found'
        const my_element = this;
        this.controls = [ 
              {name:'install', class: 'yes', click(){
                console.log('installing interface');
              }}, 
              {name: 'no thanks', class:'no', click:function(){
                my_element.visible = false;
                my_element.status = 0;
              }} ]
      });
      const vm = this
      console.log('check state');

      if (this.state_vpn_timer)
        clearInterval(this.state_vpn_timer)

      this.state_vpn_timer = setInterval(() => {
        console.log('check state');

        fetch(`http://127.0.0.1:${vm.vpn_interface_port}/state-vpn-connection`)
        .then(res => res.text())
        .then(e => {
          if(e == 'CONNECTED'){
            this.status = 1
            this.status_text = 'VPN Connection open'
            this.controls = [ 
                {name:'close', class: 'yes', click(){
                  fetch(`http://127.0.0.1:${vm.vpn_interface_port}/close-vpn-connection`)
                }} ]
          }else if(e == 'opening'){
            this.status = 2
            this.status_text = 'VPN Connection starting ...'
            this.controls = [ 
                {name:'close', class: 'yes', click(){
                  fetch(`http://127.0.0.1:${vm.vpn_interface_port}/close-vpn-connection`)
                }} ]
          }
          else if (e == 'DISCONNECTED'){
            setTimeout(() => {
              if (this.state_vpn_timer)
                clearInterval(this.state_vpn_timer)
              this.$emit('closed')
              this.visible = false
            }, 10 )
          }
          
        })
        .catch(() =>{
        });
      }, 1000 * 3)


      if (this.ttl_vpn_timer)
        clearInterval(this.ttl_vpn_timer)

      this.ttl_vpn_timer = setInterval(() => {
          fetch(`http://127.0.0.1:${vm.vpn_interface_port}/keep-vpn-connection-alive`).catch(e => console.log(e))
      }, 1000 * 285)
    }
  },
  data(){
    return{
      visible: false, 
      controls: [],

      status: 0,
      status_text: 'loading...',

      state_vpn_timer: null,
      ttl_vpn_timer:null,
      vpn_interface_port:56123,
    }
  },
}
</script>
<style>
.vpn-status-bar{
  position: fixed;
  bottom: 100px;
  left: 20%;
  width: 60%;
  height: 50px;

  margin: auto 0;
  transition: height .4s;

  background-color: var(--color4);
  border: solid 2pt var(--color0);

  display: flex;
  flex-direction: column;
}
.vpn-status-bar.show-controls{
  height: 100px;
}
.vpn-status-bar > .horizontal_wrapper{
  flex: 1 0 0; 
}
.vpn-status-bar .icons{
  flex: 0 0 50px;
}
.vpn-status-bar .status{
  margin: auto;
}
.vpn-status-bar .yes_no{
  flex: 1 0 0 ;
  display: flex;
  flex-direction: row;
}
.vpn-status-bar .yes_no div{
  margin: 5px;
  padding: 5px;

  flex: 1 0 0;
  font: 16pt;

  border: solid 2pt var(--color4);
  text-align: center;

  cursor: pointer;
}
.vpn-status-bar .yes_no div.yes{
  background-color: var(--color2);
  color: var(--color1);
  
}
</style>