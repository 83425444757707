<template>
    <md-dialog :md-active="show" class="" v-on:md-closed="$emit('close')" v-on:md-clicked-outside="$emit('close')">
        <div class="horizontal_wrapper">
            <md-dialog-title >{{title}}</md-dialog-title>
            <div class="placeholder"></div>
        </div>
        <div style="overflow-y:scroll; padding: 0 24px; flex: 1 0 auto" class="vertical-wrapper">
            
            <div v-for="sa in searchAttributes" :key="sa.key" style="flex: 0 0 auto">
                <md-field>
                    <label for="">{{ sa.text}}</label>
                    <md-input v-model="sa.value"></md-input>
                </md-field>
            </div>
        </div>

        <div class="vertical-wrapper" style="flex: 0 0 auto; margin: 24px">
            <md-button style="" class="" v-on:click="searchAttributes.forEach(e => e.value = '')">clear</md-button>

            <md-dialog-actions style="flex: 0 0 auto; padding: 0" class="horizontal-wrapper">
                <md-button class="md-primary" style="flex: 1 0 0;" v-on:click="$emit('close')" >Schließen</md-button>
                <md-button class="md-primary md-raised" style="flex: 1 0 0;" v-on:click="changeSearchAttributes" >Speichern</md-button>
            </md-dialog-actions>
        </div>

      
      

    </md-dialog>
</template>
<script>

export default {
    name: 'filter-popup',
    props: { title: String, show: Boolean, searchAttributes:Array},
    watch:{
      
    },
    methods:{
        changeSearchAttributes(){
            this.searchAttributes.forEach(e => {
                e.evaluate = function (name, value, item) {
                    return item[name] != undefined? item[name].toString().includes(value) : value == ''
                }
            })
            this.$emit('re-filter')
        }
    },
    mounted(){
        this.changeSearchAttributes()
       
    },
    data(){
        return{
            searchText: '',
           
        }
    }
}
</script>