// this store is for values whick are responsible for different display modes
import Vue from 'vue'
import moment from 'moment'
const vm = Vue.prototype;

export default {
    namespaced: true,
    state: {
        
       offlineMaschines: []

    },
    getters: {
       
        getOfflineMaschines(state) {
            return state.offlineMaschines
        },
      
    },
    mutations: {
       
        'set-offline-maschines': function(state, offlineMaschines){
            state.offlineMaschines = []
            offlineMaschines.forEach(oM => state.offlineMaschines.push(oM))
        }

    },
    actions: {
        'fetch-offline-maschines': function({ commit, rootState }){
            fetch(`${vm.$hostname}/maschines/offline`, {method: 'POST', headers: { authorization: rootState.token }}).then(async (e) => {
                if(e.status == 200){
                    const data = await e.json()
    
                    // sort all maschines after offline date
                    data.sort((a, b) => b.offlineDate - a.offlineDate)
                    // convert dates into format
                    data.forEach(e => e.offlineDate = moment.unix(e.offlineDate).format('YYYY-MM-DD HH:mm:ss') )
                    commit('set-offline-maschines', data)
                }
            })
        }
    }
}